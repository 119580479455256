import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

import swal from 'sweetalert';

import {
  ScriptService,
  GetserviesService
} from '../app.filesServices';

import { AngularFirestore } from '@angular/fire/firestore';

declare var $:any;
declare var Codebase:any;

@Component({
  selector: 'app-unidades',
  templateUrl: './unidades.component.html'
})
export class UnidadesComponent implements OnInit {

  loader:boolean = false;

	token = JSON.parse(localStorage.getItem("token"));
	params:any = { buttons: false, timer: 2000 };
	rutaocliente:any;
	///
	ruta:any;
	unidad:any;
  tipounidad:any;
  alias:any;
	imei:any;
  gps:any = 'coban';
  version:any = '3g';
  mdvr:any = 0;
	telequipo:any;
  folio:any;
  puerto:any;
	compania:any = 'odhette attat';
	fechaCorte:any;
	placas:any;
	modelo:any;
	year:any;
	color:any;
	concesion:any;
	seriev:any;
	seriem:any;
	nombreconcesionario:any;
	telurgercia1:any;
	telurgercia2:any;
	combustiblepromedio:any;
	horaairview1:any;
	horaairview2:any;
  responsable:any;

  tipo:any;
  rutatitulo:any;
  ///
  edit_id:any;
  edit_ruta:any;
  edit_unidad:any;
  edit_tipounidad:any;
  edit_alias:any;
  edit_imei:any;
  edit_imei_tem:any;
  edit_gps:any;
  edit_version:any;
  edit_mdvr:any;
  edit_telequipo:any;
  edit_folio:any;
  edit_puerto:any;
  edit_compania:any;
  edit_fechaCorte:any;
  edit_placas:any;
  edit_modelo:any;
  edit_year:any;
  edit_color:any;
  edit_concesion:any;
  edit_seriev:any;
  edit_seriem:any;
  edit_nombreconcesionario:any;
  edit_telurgercia1:any;
  edit_telurgercia2:any;
  edit_combustiblepromedio:any;
  edit_status:any;
  edit_horaairview1:any;
  edit_horaairview2:any;
  edit_responsable:any;
  ///
  swal:any = {
      title: "¿Seguro que quiere eliminar el registro?",
      text: "No se podra recuperar el registro",
      icon: "warning",
      buttons: true,
      dangerMode: true,
  };
  swal2:any = {
      buttons: {
        cancel: "Cancelar",
        catch: {
          text: "Ajustar transmisión",
          value: "catch",
        },
        defeat: 'Borrar',
      },
    };
  icon:any = {icon: "success"};

  rutaSearchUnidades:any;
  unidadesArray:any = [];

  constructor(
  	public script:ScriptService,
  	public snackBar:MatSnackBar,
    public http:HttpClient,
  	public getservices:GetserviesService,
    private db: AngularFirestore
  	) {
  	this.script.loadScript();
  }

  ngOnInit() {
    this.onChangeSelect();
    this.datosWred();
    this.loader = true;
    let data = {
      pin: this.token.pin
    };
    this.getservices.getData('rutasoclientes',data)
    .subscribe( res => {
    	this.rutaocliente = res;
      this.ruta = this.rutaocliente[0].pin;
      this.rutaSearchUnidades = this.rutaocliente[0].pin;
      this.datosUnidadesCU();
      this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
      });
  }

  datosWred(){
    this.http.post('https://admin.wred.mx/api/V1/obtenerUnidadesInvConcesion',{concesion:this.concesion})
      .subscribe( datosUni => {
        this.unidad = datosUni[0]['unidad'];
        this.imei = datosUni[0]['imei'];
        this.responsable = datosUni[0]['responsable'];
        this.telequipo = datosUni[0]['sim'];
        this.placas = datosUni[0]['placas'];
        this.modelo = datosUni[0]['marca'];
        this.year = datosUni[0]['modelo'];
        this.color = datosUni[0]['color'];
        this.seriev = datosUni[0]['vin'];
        this.seriem = datosUni[0]['motor'];
        this.nombreconcesionario = datosUni[0]['cliente'];
        this.telurgercia1 = datosUni[0]['telefono'];
        if(this.telurgercia1 == 0){
          this.telurgercia1 = '';
        }else{
          this.telurgercia1 = this.telurgercia1;
        }
        this.tipo = datosUni[0]['tipo'];
        this.rutatitulo = datosUni[0]['ruta'];
        this.gps = 'worldcam';
        this.version = 'world01';
        this.mdvr = 1;
        this.alias = 'g'+datosUni[0]['ruta']+datosUni[0]['unidad'];
        this.alias = this.alias.toLowerCase();
      },err => {
        this.snackBar.open('Algo malo salio en la conección WRED', null, { duration: 3000 });
      });
  }

  onChangeSelect() {
    $('.rutaSearchUnidades').on("change",()=>{
        this.unidadesArray = [];
        this.rutaSearchUnidades = $(".rutaSearchUnidades").val();
        this.datosUnidadesCU();
    });
  }

  datosUnidadesCU() {
    this.loader = true;
    let data = {
      pin: this.token.pin,
      ruta: this.rutaSearchUnidades
    };
    this.getservices.getData('equipos',data)
    .subscribe( res => {
        this.loader = false;
        this.snackBar.open('Busqueda correcta - '+this.querutaAirView(this.rutaSearchUnidades), null, { duration: 3000 });
        this.unidadesArray = res;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
      });
  }

  guardar(){
  	if(this.ruta && this.unidad && this.imei && this.telequipo){
      this.loader = true;
      this.setParodemotor(this.queruta(this.ruta),this.imei);
  		let datasave = {
  			  routesorclients_id: this.ruta,
  			  unidad: this.unidad,
          tipounidad: this.tipounidad,
        	alias: this.alias,
  			  imei: this.imei,
          responsable: this.responsable,
	        gps: this.gps,
	        version: this.version,
	        mdvr: this.mdvr,
    			tel_equipo: this.telequipo,
          folio: this.folio,
          puerto: this.puerto,
    			compania: this.compania,
    			fechaCorte: this.fechaCorte,
    			placas: this.placas,
    			modelo: this.modelo,
	        year: this.year,
	        color: this.color,
	        concesion: this.concesion,
	        seriev: this.seriev,
	        seriem: this.seriem,
    			nombre_consecionario: this.nombreconcesionario,
    			tel_urgencia1: this.telurgercia1,
    			tel_urgencia2: this.telurgercia2,
	        combustiblepromedio: this.combustiblepromedio,
	        horaairview1: $('.airview1').val(),
	        horaairview2: $('.airview2').val()
      };
      this.cambioSibive(this.ruta,this.unidad,this.alias);
      this.getservices.setData('equiposSave',datasave)
      .subscribe( data => {
          if(data['res']==true){
            swal("¡Datos  guardados!", this.params );
            	this.ruta = '';
      			  this.unidad = '';
              this.tipounidad = '';
            	this.alias = '';
      			  this.imei = '';
              this.responsable = '';
	            this.gps = '';
	            this.version = '';
	            this.mdvr = '';
        			this.telequipo = '';
              this.folio = '';
              this.puerto = '';
        			this.compania = '';
        			this.fechaCorte = '';
        			this.placas = '';
        			this.modelo = '';
	            this.year = '';
	            this.color = '';
	            this.concesion = '',
	            this.seriev = '';
	            this.seriem = '';
        			this.nombreconcesionario = '';
        			this.telurgercia1 = '';
        			this.telurgercia2 = '';
	            this.combustiblepromedio = '';
	            this.horaairview1 = '';
	            this.horaairview2 = '';
	            this.datosUnidadesCU();
          }else if(data['res']=='imei'){
            this.snackBar.open('El Imei ya existe...', null, { duration: 3000 });
            this.loader = false;
          }else if(data['res']=='alias'){
            this.snackBar.open('El Alias ya existe...', null, { duration: 3000 });
            this.loader = false;
          }else if(data['res']=='tel'){
            this.snackBar.open('El teléfono ya existe...', null, { duration: 3000 });
            this.loader = false;
          }else{
            this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
            this.loader = false;
          }
        },err => {
          if(err.status == 500){
            this.snackBar.open('El IMEI ya existe', null, { duration: 3000 });
            this.loader = false;
          }else{
            this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
            this.loader = false;
          }
        });
  	}else{
  		this.snackBar.open('Faltan campos por llenar', null, { duration: 3000 });
  	}
  }

  editar(id:number){
    for (var i = 0; i < this.unidadesArray.length; ++i) {
        if(this.unidadesArray[i].id == id){
          this.edit_id = this.unidadesArray[i].id;
          this.edit_ruta = this.unidadesArray[i].routesorclients_id;
          this.edit_unidad = this.unidadesArray[i].unidad;
          this.edit_tipounidad = this.unidadesArray[i].tipounidad;
          this.edit_alias = this.unidadesArray[i].alias;
          this.edit_imei = this.unidadesArray[i].imei;
          this.edit_imei_tem = this.unidadesArray[i].imei;
          this.edit_responsable = this.unidadesArray[i].responsable;
          this.edit_gps = this.unidadesArray[i].gps;
          this.edit_version = this.unidadesArray[i].version;
          this.edit_mdvr = this.unidadesArray[i].mdvr;
          this.edit_telequipo = this.unidadesArray[i].tel_equipo;
          this.edit_folio = this.unidadesArray[i].folio;
          this.edit_puerto = this.unidadesArray[i].puerto;
          this.edit_compania = this.unidadesArray[i].compania;
          this.edit_fechaCorte = this.unidadesArray[i].fechaCorte;
          this.edit_placas = this.unidadesArray[i].placas;
          this.edit_modelo = this.unidadesArray[i].modelo;
          this.edit_year = this.unidadesArray[i].year;
          this.edit_color = this.unidadesArray[i].color;
          this.edit_concesion = this.unidadesArray[i].concesion;
          this.edit_seriev = this.unidadesArray[i].seriev;
          this.edit_seriem = this.unidadesArray[i].seriem;
          this.edit_nombreconcesionario = this.unidadesArray[i].nombre_consecionario;
          this.edit_telurgercia1 = this.unidadesArray[i].tel_urgencia1;
          this.edit_telurgercia2 = this.unidadesArray[i].tel_urgencia2;
          this.edit_combustiblepromedio = this.unidadesArray[i].combustiblepromedio;
          this.edit_status = this.unidadesArray[i].status;
          this.edit_horaairview1 = this.unidadesArray[i].horaairview1.substring(0,5);
          this.edit_horaairview2 = this.unidadesArray[i].horaairview2.substring(0,5);
          $(function () {
            Codebase.helpers(['masked-inputs']);
          });
          this.snackBar.open('Edit OK...', null, { duration: 3000 });
        }
    }
  }

  editarSave(){
    if(this.edit_id && this.edit_ruta && this.edit_unidad && this.edit_imei && this.edit_telequipo){
      this.db.collection("parosdemotor").doc(this.edit_imei_tem).delete();
      this.setParodemotor(this.queruta(this.edit_ruta),this.edit_imei);
      if(this.edit_imei_tem != this.edit_imei){
        this.borrarDatosDB(this.edit_ruta,this.edit_imei_tem,'borrar');
      }
      this.edit_imei_tem = 0;
      this.loader = true;
      let data = {
          edit_id: this.edit_id,
          edit_ruta: this.edit_ruta,
          edit_unidad: this.edit_unidad,
          edit_tipounidad: this.edit_tipounidad,
          edit_alias: this.edit_alias,
          edit_imei: this.edit_imei,
          edit_responsable: this.edit_responsable,
          edit_gps: this.edit_gps,
          edit_version: this.edit_version,
          edit_mdvr: this.edit_mdvr,
          edit_telequipo: this.edit_telequipo,
          edit_folio: this.edit_folio,
          edit_puerto: this.edit_puerto,
          edit_compania: this.edit_compania,
          edit_fechaCorte: this.edit_fechaCorte,
          edit_placas: this.edit_placas,
          edit_modelo: this.edit_modelo,
          edit_year: this.edit_year,
          edit_color: this.edit_color,
          edit_concesion: this.edit_concesion,
          edit_seriev: this.edit_seriev,
          edit_seriem: this.edit_seriem,
          edit_nombreconcesionario: this.edit_nombreconcesionario,
          edit_telurgercia1: this.edit_telurgercia1,
          edit_telurgercia2: this.edit_telurgercia2,
          edit_combustiblepromedio: this.edit_combustiblepromedio,
          edit_status: this.edit_status,
          edit_horaairview1: $('.edit_airview1').val(),
          edit_horaairview2: $('.edit_airview2').val()
      };
      this.cambioSibive(this.edit_ruta,this.edit_unidad,this.edit_alias);
      this.getservices.setData('equiposUpdate',data)
        .subscribe( res => {
            if(res['res']==true){
              swal("!Unidad editada correctamente", this.params);
              this.datosUnidadesCU();
              this.edit_id = '';
              this.edit_ruta = '';
              this.edit_unidad = '';
              this.tipounidad = '',
              this.edit_alias = '';
              this.edit_imei = '';
              this.edit_responsable = '';
              this.edit_gps = '';
              this.edit_version = '';
              this.edit_mdvr = '';
              this.edit_telequipo = '';
              this.edit_folio = '';
              this.edit_puerto = '';
              this.edit_compania = '';
              this.edit_fechaCorte = '';
              this.edit_placas = '';
              this.edit_modelo = '';
              this.edit_year = '';
              this.edit_color = '';
              this.edit_concesion = '';
              this.edit_seriev = '';
              this.edit_seriem = '';
              this.edit_nombreconcesionario = '';
              this.edit_telurgercia1 = '';
              this.edit_telurgercia2 = '';
              this.edit_combustiblepromedio = '';
              this.edit_status = '';
              this.edit_horaairview1 = '';
              this.edit_horaairview2 = '';
            }else if(res['res']=='imei'){
              this.snackBar.open('El Imei ya existe...', null, { duration: 3000 });
              this.loader = false;
            }else if(res['res']=='alias'){
              this.snackBar.open('El Alias ya existe...', null, { duration: 3000 });
              this.loader = false;
            }else if(res['res']=='tel'){
              this.snackBar.open('El teléfono ya existe...', null, { duration: 3000 });
              this.loader = false;
            }else{
              this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
              this.loader = false;
            }
            },err => {
              this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
              this.loader = false;
            });
    }
  }

  eliminar(info:any){
    swal(this.swal)
    .then((willDelete) => {
      if (willDelete) {
        this.loader = true;
        let data = {
          id: info['id']
        };
        this.cambioSibive(info['routesorclients_id'],info['unidad'],0);
        this.borrarDatosDB(info['routesorclients_id'],info['imei'],'borrar');
        this.getservices.setData('equiposDelite',data)
        .subscribe( res => {
            if(res['res']==true){
              //// clear firestore
              //this.db.collection("coords").doc(info['imei']).delete();
              this.db.collection("parosdemotor").doc(info['imei']).delete();
              ///
              swal("!Unidad eliminada¡", this.icon);
              this.datosUnidadesCU();
            }else{
              this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
              this.loader = false;
            }
            },err => {
              this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
              this.loader = false;
            });
      }
    });
  }

  eliminarDB(info:any){
    swal("¿Seguro que quiere eliminar el registro de la DB?",this.swal2)
    .then((value) => {
      switch (value) {
     
        case "defeat":
          this.borrarDatosDB(info['routesorclients_id'],info['imei'],'borrar');
          swal("!Datos eliminados¡", this.icon);
          break;
     
        case "catch":
          this.borrarDatosDB(info['routesorclients_id'],info['imei'],'ajustar');
          swal("!Datos ajustados correctamente¡", this.icon);
          break;
      }
    });

  }

  cambioSibive(miruta,miunidad,mialias){
    //// cambio datos a sibive
        for (var i = 0; i < this.rutaocliente.length; ++i) {
          if(this.rutaocliente[i].pin == miruta){
            miruta = this.rutaocliente[i].label;
          }
        }
        let dataSibive = {
              ruta: miruta,
              alias: mialias,
              unidad: miunidad,
            };
        this.getservices.getDataSibive('https://admin.wred.mx/api/V1/sibive_unidad',dataSibive)
        .subscribe( res => {
            if(res['res']==true){
              this.snackBar.open('Gps editado en sibive', null, { duration: 3000 });
            }else{
              this.snackBar.open('Gps no editado en sibive', null, { duration: 3000 });
              this.loader = false;
            }
            },err => {
              this.loader = false;
            });
        //// cambio datos a sibive fin
  }

  setParodemotor(ruta:any,imei:any){
    this.db.collection('parosdemotor').doc(imei).set({
      imei: imei, 
      ruta: ruta, 
      tipo: 'prendermotor'
    });
  }

  borrarDatosDB(ruta:any,imei:any,tipo:any){
    this.loader = true;
    let data = {
      ruta: ruta,
      imei: imei,
      tipo: tipo
    };
    this.getservices.getData('cleartable_editdelite',data)
    .subscribe( res => {
        this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
      });
  }

  queruta(id){
    for (var i = 0; i < this.rutaocliente.length; ++i) {
      if(this.rutaocliente[i].pin == id){
        return this.rutaocliente[i].label;
      }
    }
  }

  querutaAirView(id){
    for (var i = 0; i < this.rutaocliente.length; ++i) {
      if(this.rutaocliente[i].pin == id){
        return this.rutaocliente[i].airview;
      }
    }
  }

}