import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer  } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AgmMap, AgmMarker, AgmPolygon, AgmCircle, AgmPolyline } from '@agm/core';
import { HttpClient } from '@angular/common/http';

import {
  ScriptService,
  GetserviesService
} from '../app.filesServices';

import { environment } from '../../environments/environment';

declare var google:any;
declare var $:any;

@Component({
  selector: 'app-velocidades',
  templateUrl: './velocidades.component.html'
})
export class VelocidadesComponent implements OnInit {

  @ViewChild(AgmMap) map: any;
  @ViewChild(AgmMap) mapGeo: any;

	loader:boolean = false;
	token = JSON.parse(localStorage.getItem("token"));
  mapa = JSON.parse(localStorage.getItem("mapa"));
  heightMap:any = window.screen.height - 250;

	tipo:any;
  ruta:any;
  timeFullInicio:any;
	timeFullFin:any;
	dateInicio:any;
	timeInicio:any;
	dateFin:any;
	timeFin:any;
	velocidad:any;

  lat:number = this.mapa['lat'];
  lng:number = this.mapa['lng'];
  zoom:number = this.mapa['zoom'];

  market:boolean = false;

  unidadesList:any;
  nom:number;

  arreglo:Array<any> = [];

  direccion:any = '';
  selectedDevice:any = 'Filtro';

  hoy:any;
  periodo:any;
  nomhoyperiodo:any;

  btnalert:boolean = false;

  constructor(
    public http:HttpClient,
    public snackBar:MatSnackBar,
  	private router:Router,
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    public script:ScriptService,
    public getservices:GetserviesService
    ) {
  	this.script.loadScript();
  	this.activatedRoute.params.subscribe( params =>{
  	  this.tipo			  = params['tipo'];
      this.ruta           = params['ruta'];
      this.timeFullInicio = params['timeFullInicio'];
      this.timeFullFin 	  = params['timeFullFin'];
      this.dateInicio 	  = params['dateInicio'];
      this.timeInicio 	  = params['timeInicio'];
      this.dateFin 		  = params['dateFin'];
      this.timeFin 		  = params['timeFin'];
      this.velocidad 	  = params['velocidad'];
    });
  }

  public mapReady(map) {
    this.map = map;
  }

  ngOnInit() {
    this.traerGeocercas();
    //// unidades segun  ruta
    this.loader = true;
      let data = {
        ruta: this.ruta,
        pin: this.token.pin
      };
      this.getservices.getData('unidadesNombreRutaFiltro',data)
      .subscribe( res => {
        this.unidadesList = res;
      },err => {
          this.snackBar.open('Error de conexión en el servidor. Hoy', null, { duration: 3000 });
      });
    this.loader = false;
    ////// consulta de alertas
    if(this.tipo == 'consultadiaHoy'){
      this.loader = true;
      let data = {
        pin: this.token.pin,
        ruta: this.ruta,
        horaInicio: this.timeFullInicio,
        horaFin: this.timeFullFin,
        velocidad: this.velocidad
      };
      this.getservices.getData('velocidadesHoy',data)
      .subscribe( res => {
        this.hoy = res['precente'];
        this.periodo = res['periodo'];
        this.loader = false;
        if(this.hoy.length){
          this.nomhoyperiodo = false;
          this.nom = this.hoy.length;
        }else{
          this.nom = 0;
        }
      },err => {
          this.loader = false;
          this.snackBar.open('Error de conexión en el servidor. Hoy', null, { duration: 3000 });
      });
    }else{
      this.loader = true;
      let data = {
        pin: this.token.pin,
        ruta: this.ruta,
        dateInicio: this.dateInicio,
        timeInicio: this.timeInicio,
        dateFin: this.dateFin,
        timeFin: this.timeFin,
        velocidad: this.velocidad
      };
      this.getservices.getData('velocidadesPeriodos',data)
      .subscribe( res => {
        this.hoy = res['precente'];
        this.periodo = res['periodo'];
        this.loader = false;
        if(this.periodo.length){
          this.nomhoyperiodo = this.periodo.length + 1;
          this.nom = this.periodo.length;
          let hoyNumeroSuma;
          if(this.hoy.length > 0){
            hoyNumeroSuma = this.hoy.length;
          }else{
            hoyNumeroSuma = 0;
          }
          this.nom = this.nom + hoyNumeroSuma;
        }else{
          this.nom = 0;
        }
      },err => {
          this.loader = false;
          this.snackBar.open('Error de conexión en el servidor. Periodos', null, { duration: 3000 });
      });
    }
  }

  hidden(dato){
    $('.dinamic').hide();
    $('.'+dato).show();
  }

  onChange(newValue:any) {
    if(newValue != 'Filtro'){
      this.hidden(newValue);
    }else{
      $('.dinamic').show();
    }
  }

  centrar(info){
    this.zoom = 10;
    this.market = true;
    this.map.setCenter({ lat: Number(info['lat']), lng: Number(info['lng']) });
    this.lat = info['lat'];
    this.lng = info['lng'];
    this.zoom = 19;
    this.btnalert = true;
    this.direccion = '';
  }

  adress(){
    // traigo adress
    this.http.get('https://maps.googleapis.com/maps/api/geocode/json?latlng='+this.lat+','+this.lng+'&key='+environment.ubicacion)
    .subscribe((data) => {
      if(data['status'] == "OK"){
        this.direccion = data['results'][2].formatted_address;
      }else{
        this.direccion = 'No hay dirección';
      }
    });
  }

  addmarker(info){
    this.arreglo.push({ lat: Number(info['lat']), lng: Number(info['lng']), label: info['unidad'] });
  }


  traerGeocercas(){
    let data = {
      pin: this.token.pin
    };
    this.getservices.getData('geocercasMapa',data)
    .subscribe( res => {
      this.checarGeo(res);
    },err => {
      this.snackBar.open('Error de conexión en el servidor. geocercas', null, { duration: 3000 });  
    });
  }

  checarGeo(geo:any){
    for (var i = 0; i < geo.length; ++i) {
      if(geo[i]['tipo'] == 'circular'){
        this.hacerCircleGeo(geo[i]);
      }else if(geo[i]['tipo'] == 'poligonal'){
        this.hacerPolygonGeo(geo[i]);
      }else if(geo[i]['tipo'] == 'lineal'){
        this.hacerPolylineGeo(geo[i]);
      }
    }
  }


  hacerCircleGeo(cir:any){
    let color = cir['color'];
    cir = JSON.parse(cir.coords);
    this.mapGeo._mapsWrapper.createCircle({
      center: { lat: cir['lat'], lng: cir['lng'] },
      radius: Number(cir['radio']),
      strokeColor: color,
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillColor: color,
      fillOpacity: 0.3,
      visible: true
    });
  }

  hacerPolygonGeo(poly:any){
    this.mapGeo._mapsWrapper.createPolygon({
      paths: eval(poly['coords']),
      strokeColor: poly['color'],
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillColor: poly['color'],
      fillOpacity: 0.3,
      visible: true
    });
  }

  hacerPolylineGeo(line:any){
    this.mapGeo._mapsWrapper.createPolyline({
      strokeColor: line['color'],
      strokeOpacity: 0.6,
      strokeWeight: 5,
      path: eval(line['coords']),
      visible: true
    });
  }

  getStyle() {
    // snip snip -> fetch the url from somewhere
    let height = this.heightMap;
    let style = `height: ${height}px;`;
    
    // sanitize the style expression
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

}