import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer  } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import swal from 'sweetalert';

import {
  ScriptService,
  GetserviesService
} from '../app.filesServices';

declare var moment:any;
declare var $:any;

@Component({
  selector: 'app-solicitudvideowhats',
  templateUrl: './solicitudvideowhats.component.html',
  styleUrls: ['./solicitudvideowhats.component.css']
})
export class SolicitudvideowhatsComponent implements OnInit {

  loader:boolean = false;
  token = JSON.parse(localStorage.getItem("token"));

  params:any = { buttons: false, timer: 9000 };

  swal:any = {
      title: "¿Seguro que quiere eliminar el registro?",
      text: "No se podra recuperar el registro",
      icon: "warning",
      buttons: true,
      dangerMode: true,
  };

  nom:any = 0;

  datosVideos:any;

  dataUserAgent:any;

  tipo:any = 'asalto';
  unidad:any = '';
  ruta:any = '';
  diav:any = '';
  mesv:any = '';
  entre:any = '15:40 y 15:45';
  nombre:any = '';
  descripcionubicacion:any = '';
  responsiva:any = '';
  cel:any = '';
  correo:any = '';

  meses:any = [];

  clienteslista:any;
  unidadesList:any;

  solicitudForm:any = false;

  listaSolicitudesVideosAdmin:any = [];

  vistaterminar:any = false;
  idUpdateSolicitud:any;


  descripcion:any = '';
  path:any = '';
  url:any = '';

  fechaVideoInicioWhats:any = moment(this.fechaHoy(), 'YYYY-MM-DD').subtract(7, 'days').format('YYYY-MM-DD');
  fechaVideoFinWhats:any = moment(this.fechaHoy(), 'YYYY-MM-DD').subtract(1, 'days').format('YYYY-MM-DD');

  constructor(
    public snackBar:MatSnackBar,
    private router:Router,
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    public script:ScriptService,
    public getservices:GetserviesService
    ) {
    this.script.loadScript();
    if(this.token['modouser'] == 'sistemas' || this.token['modouser'] == 'monitoreo' || this.token['modouser'] == 'monitoreoanalistas' || this.token['modouser'] == 'monitoreoadmin'){}else{
      this.router.navigate(['reload','mapa']); 
    }
  }

  ngOnInit() {
    this.datosGeneralesVideos();
    // trae datos de rutas para traer alertas
    let data = {
      pin: this.token.pin
    };
    this.getservices.getData('rutasoclientes',data)
    .subscribe( (res:any[]) => {
      this.clienteslista = res;
      this.ruta = this.clienteslista[0].nombre_videos;
      this.unidades(this.clienteslista[0].nombre_videos);
      ///
    },err => {
      this.snackBar.open('Error de conexión en el servidor. Rutas Solicitudes', null, { duration: 3000 });  
    });
    this.loader = true;
    this.getservices.getData('useragent',{})
    .subscribe( res => {
      this.dataUserAgent = res;
      this.dataUserAgent = eval(this.dataUserAgent);
      this.queMes();
      },err => {
        this.snackBar.open('Error de conexión en el servidor. User Agent', null, { duration: 3000 });
    });
    this.loader = false;
    // trae lista de alertas videos
    this.loader = true;
    let dataW = {
      pin: this.token.pin,
      modouser: this.token.modouser
    };
    this.getservices.getData('solicitudVideosListAdminWhats',dataW)
    .subscribe( res => {
      this.listaSolicitudesVideosAdmin = res;
      this.nom = this.listaSolicitudesVideosAdmin.length;
      this.loader = false;
      },err => {
        this.loader = false;
        this.snackBar.open('Error de conexión en el servidor. Datos Lista Solicitudes', null, { duration: 3000 });
    });
  }

  queMes(){
    let elMes = this.dataUserAgent['mes'];
    if(elMes == '12'){
      this.mesv = 'diciembre';
      this.meses = [
      {mes: 'noviembre', mesM: 'Noviembre'},
      {mes: 'diciembre', mesM: 'Diciembre'}
      ];
    }else if(elMes == '11'){
      this.mesv = 'noviembre';
      this.meses = [
      {mes: 'octubre', mesM: 'Octubre'},
      {mes: 'noviembre', mesM: 'Noviembre'}
      ];
    }else if(elMes == '10'){
      this.mesv = 'octubre';
      this.meses = [
      {mes: 'septiembre', mesM: 'Septiembre'},
      {mes: 'octubre', mesM: 'Octubre'}
      ];
    }else if(elMes == '09'){
      this.mesv = 'septiembre';
      this.meses = [
      {mes: 'agosto', mesM: 'Agosto'},
      {mes: 'septiembre', mesM: 'Septiembre'}
      ];
    }else if(elMes == '08'){
      this.mesv = 'agosto';
      this.meses = [
      {mes: 'julio', mesM: 'Julio'},
      {mes: 'agosto', mesM: 'Agosto'}
      ];
    }else if(elMes == '07'){
      this.mesv = 'julio';
      this.meses = [
      {mes: 'junio', mesM: 'Junio'},
      {mes: 'julio', mesM: 'Julio'}
      ];
    }else if(elMes == '06'){
      this.mesv = 'junio';
      this.meses = [
      {mes: 'mayo', mesM: 'Mayo'},
      {mes: 'junio', mesM: 'Junio'}
      ];
    }else if(elMes == '05'){
      this.mesv = 'mayo';
      this.meses = [
      {mes: 'abril', mesM: 'Abril'},
      {mes: 'mayo', mesM: 'Mayo'}
      ];
    }else if(elMes == '04'){
      this.mesv = 'abril';
      this.meses = [
      {mes: 'marzo', mesM: 'Marzo'},
      {mes: 'abril', mesM: 'Abril'}
      ];
    }else if(elMes == '03'){
      this.mesv = 'marzo';
      this.meses = [
      {mes: 'febrero', mesM: 'Febrero'},
      {mes: 'marzo', mesM: 'Marzo'}
      ];
    }else if(elMes == '02'){
      this.mesv = 'febrero';
      this.meses = [
      {mes: 'enero', mesM: 'Enero'},
      {mes: 'febrero', mesM: 'Febrero'}
      ];
    }else if(elMes == '01'){
      this.mesv = 'enero';
      this.meses = [
      {mes: 'diciembre', mesM: 'Diciembre'},
      {mes: 'enero', mesM: 'Enero'}
      ];
    }
  }

  onChange(rutaID:any) {
    this.unidades(rutaID);
  }

  unidades(ruta:any){
    this.loader = true;
    let data = {
      ruta: ruta,
      pin: this.token.pin
    };
    this.getservices.getData('unidadesNombreRutaFiltroVideos',data)
    .subscribe( res => {
      this.unidadesList = res;
      this.unidad = this.unidadesList[0].unidad;
      this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor. Unidades Solicitud', null, { duration: 3000 });
        this.loader = false;
    });
  }

  datosGeneralesVideos(){
    // trae lista de alertas videos
    this.loader = true;
    this.getservices.getData('solicitudVideosDatosAdminWhats',{})
    .subscribe( res => {
      this.datosVideos = res;
      this.datosVideos = eval(this.datosVideos[0]);
      this.loader = false;
      },err => {
        this.loader = false;
        this.snackBar.open('Error de conexión en el servidor. Datos Generales Solicitudes', null, { duration: 3000 });
    });
  }

  porPeriodoVieos(){
    this.loader = true;
    let data = {
      fechaVideoInicioWhats: $(".fechaVideoInicioWhats").val(),
      fechaVideoFinWhats: $(".fechaVideoFinWhats").val()
    };
    this.getservices.getData('solicitudVideosListAdminWhatsPeriodo',data)
    .subscribe( res => {
        this.listaSolicitudesVideosAdmin = res;
        this.nom = this.listaSolicitudesVideosAdmin.length;
        this.loader = false;
      },err => {
        this.loader = false;
        this.snackBar.open('Error de conexión en el servidor. Periodo', null, { duration: 3000 });
    });
  }

  crearSolicitud(){
    this.solicitudForm = true;
  }

  listaSolicitud(){
    this.solicitudForm = false;
  }

  solicitud(){
    if(this.tipo && this.unidad && this.ruta && this.diav && this.mesv && this.entre && this.nombre && this.descripcionubicacion && this.responsiva && this.cel){
      this.loader = true;
      let datasave = {
        ip: this.dataUserAgent['ip'],
        nav: this.dataUserAgent['nav'],
        fechahora: this.dataUserAgent['ano']+'-'+this.dataUserAgent['mes']+'-'+this.dataUserAgent['dia']+' '+this.dataUserAgent['hora']+':00',
        solicitud: $('.texto').text(), 
        tipo: this.tipo, 
        unidad: this.unidad,
        ruta: this.ruta,
        diav: this.diav,
        mesv: this.mesv,
        entre: this.entre,
        responsable: this.nombre,
        responsiva: this.responsiva,
        pin: this.token['pin'],
        cel: this.cel,
        correo: this.correo,
        descripcionubicacion: this.descripcionubicacion
      };
    this.getservices.setData('solicitudVideosWhats',datasave)
    .subscribe( data => {
      if(data['gps'] == true){
        swal("¡Unidad con GPS... No se puede hacer solicitud!", this.params);
        this.loader = false;
      }else if(data['res']==true){

        if(data['adeudo']==true){
          swal("¡Unidad con adeudo!", this.params);
        }

        this.snackBar.open('!Solicitud enviada correctamente¡', null, { duration: 3000 });
        this.ngOnInit();
        this.solicitudForm = false;
        this.loader = false;
      }else{
        this.snackBar.open('Error de conexión en el servidor. Send Solicitud', null, { duration: 3000 });
        this.loader = false;
      }
    },err => {
        this.snackBar.open('Error de conexión en el servidor. RESPUESTA', null, { duration: 3000 });
      this.loader = false;
    });
    }else{
      this.snackBar.open('¡Algún campo está vacío! No se puede hacer la solicitud...', null, { duration: 3000 });
    }
  }

  
  terminarvista(id:any){
    this.vistaterminar = true;
    this.idUpdateSolicitud = id;
  }

  datosVideosUpdate(){
    if(this.descripcion && this.path && this.url){
      this.loader = true;
      let dataupdate = {
        id: this.idUpdateSolicitud,
        descripcion: this.descripcion,
        path: this.path,
        url: this.url
      };
      this.getservices.setData('solicitudVideosWhatsUpdate',dataupdate)
      .subscribe( data => {
        if(data['res']==true){
          this.snackBar.open('!Solicitud editada correctamente¡', null, { duration: 3000 });
          this.ngOnInit();
          this.vistaterminar = false;
          this.loader = false;
        }else{
          this.snackBar.open('Error al editar la Solicitud', null, { duration: 3000 });
          this.loader = false;
        }
      },err => {
          this.snackBar.open('Error de conexión en el servidor. Update', null, { duration: 3000 });
        this.loader = false;
      });
    }else{
      this.snackBar.open('¡Algún campo está vacío! No se puede hacer la solicitud...', null, { duration: 3000 });
    }
  }

  trash(id:any){
    swal(this.swal)
    .then((willDelete) => {
      if (willDelete) {
        this.loader = true;
          let datasave = {
            id: id
          };
        this.getservices.setData('deleteSolicitudWhast',datasave)
        .subscribe( data => {
          if(data['res']==true){
            this.snackBar.open('!Solicitud eliminada correctamente¡', null, { duration: 3000 });
            this.ngOnInit();
            this.loader = false;
          }else{
            this.snackBar.open('Error al eliminar la Solicitud', null, { duration: 3000 });
            this.loader = false;
          }
        },err => {
            this.snackBar.open('Error de conexión en el servidor. RESPUESTA Trash', null, { duration: 3000 });
          this.loader = false;
        });
      }
    });
  }

  fechaHoy(){
    let now = new Date();
    let year = "" + now.getFullYear();
    let month = "" + (now.getMonth() + 1); if (month.length == 1) { month = "0" + month; }
    let day = "" + now.getDate(); if (day.length == 1) { day = "0" + day; }
    return year + "-" + month + "-" + day;
  }

}