import { Component } from '@angular/core';
import { version } from '../../../version/version';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent {
	version:any = version.no;
}
