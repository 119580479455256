import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import {
  ScriptService,
  GetserviesService
} from '../app.filesServices';

declare var $:any;

@Component({
  selector: 'app-rutasdatos',
  templateUrl: './rutasdatos.component.html',
  styleUrls: ['./rutasdatos.component.css']
})
export class RutasdatosComponent implements OnInit {

	loader:boolean = false;

	token = JSON.parse(localStorage.getItem("token"));
	params:any = { buttons: false, timer: 2000 };

	datos:any;
  	coun:any = 0;

  constructor(
  	public script:ScriptService,
  	public snackBar:MatSnackBar,
  	public getservices:GetserviesService
  	) {
  	this.script.loadScript();
  }

 
  ngOnInit() {
  	this.loader = true;
    this.getservices.getData('generalReports',{report: 'datosRutasGeneral'})
    .subscribe( res => {
    	this.datos = res;
      	this.coun = this.datos.length;
      	this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
      });
  }

  editarSave(id,nombre){
  	this.loader = true;
  	let datos = {
  		report: 'editDatosRuta',
  		id:id,
  		cargo: $('.cargo'+nombre).val(),
  		responsable: $('.responsable'+nombre).val(),
  		telresponsable: $('.tel_responsable'+nombre).val(),
      //admin_id:$('.adminid'+nombre).val(),
      administrada:$('.administrada'+nombre).val(),
      sibive:$('.sibive'+nombre).val(),
      preciomdvr:$('.preciomdvr'+nombre).val(),
      preciogps:$('.preciogps'+nombre).val(),
  	};
    this.getservices.getData('generalReports',datos)
    .subscribe( res => {
    	this.loader = false;
    	if(res['res']==true){
    		this.ngOnInit();
    		this.snackBar.open('Editado correctamente...', null, { duration: 3000 });	
    	}
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
      });
  }

}