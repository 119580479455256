import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer  } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AgmMap, AgmMarker, AgmPolygon, AgmCircle, AgmPolyline } from '@agm/core';
import { Router, ActivatedRoute } from '@angular/router';

import {
  ScriptService,
  GetserviesService
} from '../app.filesServices';

declare var $:any;
declare var moment:any;
declare var google:any;
declare var Codebase:any;
declare var setMap:any;

@Component({
  selector: 'app-contadoresmdbractual',
  templateUrl: './contadoresmdbractual.component.html',
  styleUrls: ['./contadoresmdbractual.component.css']
})
export class ContadoresmdbractualComponent implements OnInit {

  @ViewChild(AgmMap) map: any;

  loader:boolean = false;
  token = JSON.parse(localStorage.getItem("token"));
  mapa = JSON.parse(localStorage.getItem("mapa"));
  heightMap:any = window.screen.height - 250;
  params:any = { buttons: false, timer: 2000 };

  lat:number = this.mapa['lat'];
  lng:number = this.mapa['lng'];
  zoom:number = this.mapa['zoom'];

  contadores:any;
  contadoresDatos = {bloqueosbajan: 'S/D',
    bloqueossuben: 'S/D',
    primerdato:  'S/D',
    total:  'S/D',
    totalbajada: 'S/D',
    totalbloqueos: 'S/D',
    totalmanana: 'S/D',
    totalmananab: 'S/D',
    totalnoche:  'S/D',
    totalnocheb: 'S/D',
    totalreal:  'S/D',
    totalsubida:  'S/D',
    totaltarde:  'S/D',
    totaltardeb: 'S/D',
    ultimodato:  'S/D'};
  no:any;
  view = false;

  cobro:any = 0.0;
  porcentaje:any = 8;
  estudiante:any = 6;
  precio:any = 8.50;
  combustible:any = 1250;

  horaUNO:any = moment(this.hh_mm_ss(), 'HH:mm:ss').subtract(1, 'hours').format('HH:mm');
  horaDOS:any = this.hh_mm_ss();

  fechaContador:any = this.fechaHoy();

  ruta:any;
  unidad:any;

  contadoresHoy:any = [];
  nom:any = 0;

  contadoresBloqueo:any = [];
  nomContadoresBloqueo:any = 0;

  contadoresMarkers:any = [];

  bottonTotal:any = true;
  bottonSuben:any = true;
  bottonBajan:any = true;

  bottonSubm:any = true;
  bottonSubt:any = true;
  bottonSubn:any = true;
  bottonBajm:any = true;
  bottonBajt:any = true;
  bottonBajn:any = true;

  bottonBloqueosT:any = true;
  bottonBloqueosS:any = true;
  bottonBloqueosB:any = true;

  markers:Array<any> = [];

  viewperiodofechasBoton:any = false;
  viewperiodobloqueosBoton:any = false;

  viewperiodofechas:any = false;
  viewperiodo:any = false;
  datosPorPeriodoData:any = [];
  viewTotalBloqueos:any = false;

  cobro_totales:any = 0.0;

  total_pasaje:number = 0;
  total_total:number = 0;
  total_totalsuben:number = 0;
  total_totalbajan:number = 0;
  total_totalbloqueos:number = 0;
  total_bloqueossuben:number = 0;
  total_bloqueosbajan:number = 0;
  total_subidasm:number = 0;
  total_subidast:number = 0;
  total_subidasn:number = 0;
  total_bajadasm:number = 0;
  total_bajadast:number = 0;
  total_bajadasn:number = 0;

  fechaContadorInicio:any = moment(this.fechaHoy(), 'YYYY-MM-DD').subtract(7, 'days').format('YYYY-MM-DD');
  fechaContadorFin:any = moment(this.fechaHoy(), 'YYYY-MM-DD').subtract(1, 'days').format('YYYY-MM-DD');

  constructor(
    public script:ScriptService,
    public snackBar:MatSnackBar,
    private sanitizer: DomSanitizer,
    public getservices:GetserviesService,
    private router: Router
    ) {
    this.script.loadScript();
    if(this.token['modouser'] == 'sistemas' || this.token['modouser'] == 'monitoreo' || this.token['nivel'] == 'Concesionario'){}else{
      this.router.navigate(['reload','mapa']); 
    }
    $(function () {
          Codebase.helpers(['datepicker','masked-inputs','select2']);
    });
  }

  public mapReady(map) {
    this.map = map;
  }
 
  ngOnInit() {
    this.loader = true;
    this.getservices.getData('contadoresUser',{pin: this.token['pin']})
    .subscribe( res => {
      this.contadores = res;
        this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
      });
  }

  getRandomColor() {
    var color = Math.floor(0x1000000 * Math.random()).toString(16);
    return '#' + ('000000' + color).slice(-6);
  }

  datosHoy(){
    this.loader = true;
    let data = {
      ruta: this.ruta,
      unidad: this.unidad,
      fecha: $(".fechaContador").val(),
      horaUNO: $(".horaUNO").val(),
      horaDOS: $(".horaDOS").val()
    };
    this.getservices.getData('datosHoyContadores',data)
    .subscribe( res => {
        this.contadoresHoy = res;
        this.nom = this.contadoresHoy.length;
        this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
      });
  }

  regresar(){
    this.cobro = 0.0;
    this.nom = 0;
    this.view = false;
    this.viewperiodofechas = false;
    this.viewperiodo = false;
    this.datosPorPeriodoData = [];

    this.viewTotalBloqueos = false;

    this.total_pasaje = 0;
    this.total_total = 0;
    this.total_totalsuben = 0;
    this.total_totalbajan = 0;
    this.total_totalbloqueos = 0;
    this.total_bloqueossuben = 0;
    this.total_bloqueosbajan = 0;
    this.total_subidasm = 0;
    this.total_subidast = 0;
    this.total_subidasn = 0;
    this.total_bajadasm = 0;
    this.total_bajadast = 0;
    this.total_bajadasn = 0;

    this.clearMarker();
  }

  calcular(){
    let porcentajeestudiante = this.porcentaje;
    let porcentajenormal = (100 - porcentajeestudiante);
    let pasajeros:any = this.contadoresDatos['totalreal'];
    let totalestudiante = ((pasajeros * porcentajeestudiante) / 100) * this.estudiante;
    let normal = ((pasajeros * porcentajenormal) / 100) * this.precio;
    let importe = totalestudiante+normal;
    let totalcombustible = this.combustible;
    this.cobro = importe - totalcombustible;
  }

  vermapa(tipo:any){
    this.loader = true;
    let data = {
      ruta: this.ruta,
      unidad: this.unidad,
      fecha: $(".fechaContador").val(),
      tipo: tipo
    };
    this.getservices.getData('contadoresTipoConsultaMapa',data)
    .subscribe( res => {
        if(res['data'][0]['end']){
          this.contadoresBloqueo = res['data'];
          this.nomContadoresBloqueo = this.contadoresBloqueo.length;
        }else{
          this.contadoresMarkers = res['data'];
        }
        this.crearmarker();
        this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
      });
  }

  clearMarker(){
    for (var i = 0; i < this.markers.length; ++i) {
        this.markers[i].setMap(null);
    }
    this.markers = [];
    this.contadoresHoy = [];
    this.contadoresBloqueo = [];

    this.bottonTotal = true;
    this.bottonSuben = true;
    this.bottonBajan = true;
    this.bottonSubm = true;
    this.bottonSubt = true;
    this.bottonSubn = true;
    this.bottonBajm = true;
    this.bottonBajt = true;
    this.bottonBajn = true;
    this.bottonBloqueosT = true;
    this.bottonBloqueosS = true;
    this.bottonBloqueosB = true;
  }

  crearmarker(){
    this.loader = true;
    let colorSuben = this.getRandomColor();
    let colorBajan = this.getRandomColor();
    let colorElegido;
    let indexP;
    let indexBlo;
    let colorbloqueo;
    ///
    if(this.nomContadoresBloqueo > 0){
      for (var i = 0; i < this.contadoresBloqueo.length; ++i) {
        indexBlo = i+2;
        colorbloqueo = this.getRandomColor();
        ///
        this.map._mapsWrapper.createMarker({
          position: { lat: Number(this.contadoresBloqueo[i].lat), lng: Number(this.contadoresBloqueo[i].lng) },
          animation: google.maps.Animation.BOUNCE,
          zIndex: indexBlo,
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 5, //tamaño
            strokeColor: colorbloqueo, //color del borde
            strokeWeight: 1, //grosor del borde
            fillColor: colorbloqueo, //color de relleno
            fillOpacity: 0.5// opacidad del relleno
          },
          title: '[ '+this.contadoresBloqueo[i].speed+' KM/H ] - '+this.contadoresBloqueo[i].fechahora
        }).then((marker: any) => {
          this.markers[this.markers.length] = marker;
        });
        this.map._mapsWrapper.createMarker({
          position: { lat: Number(this.contadoresBloqueo[i].latend), lng: Number(this.contadoresBloqueo[i].lngend) },
          animation: google.maps.Animation.BOUNCE,
          zIndex: indexBlo,
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 5, //tamaño
            strokeColor: colorbloqueo, //color del borde
            strokeWeight: 1, //grosor del borde
            fillColor: colorbloqueo, //color de relleno
            fillOpacity: 0.5// opacidad del relleno
          },
          title: '[ '+this.contadoresBloqueo[i].speed+' KM/H ] - '+this.contadoresBloqueo[i].end
        }).then((marker: any) => {
          this.markers[this.markers.length] = marker;
        });
      }
      this.loader = false;
      this.snackBar.open('Marker ok || '+this.contadoresBloqueo.length, null, { duration: 3000 });
    }else if(this.nomContadoresBloqueo == 0){
      for (var i = 0; i < this.contadoresMarkers.length; ++i) {
        if(this.contadoresMarkers[i].ident == 1){
          colorElegido = colorSuben;
          indexP = 5;
        }else{
          colorElegido = colorBajan;
          indexP = 6;
        }
        this.map._mapsWrapper.createMarker({
          position: { lat: Number(this.contadoresMarkers[i].lat), lng: Number(this.contadoresMarkers[i].lng) },
          zIndex: indexP,
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 5, //tamaño
            strokeColor: colorElegido, //color del borde
            strokeWeight: 1, //grosor del borde
            fillColor: colorElegido, //color de relleno
            fillOpacity: 0.5// opacidad del relleno
          },
          title: '[ '+this.contadoresMarkers[i].speed+' KM/H ] - '+this.contadoresMarkers[i].fechahora
        }).then((marker: any) => {
          this.markers[this.markers.length] = marker;
        });
      }
      this.loader = false;
      this.snackBar.open('Marker ok || '+this.contadoresMarkers.length, null, { duration: 3000 });
    }
    ///
  }

  verContadores(ruta,unidad){
    this.loader = true;
    this.ruta = ruta;
    this.unidad = unidad;
    this.viewperiodofechas = false;
    this.getservices.getData('contadoresUserDatosPorDia',{ruta: ruta, unidad:unidad, fecha: $(".fechaContador").val() })
    .subscribe( res => {
      this.no = res;
      this.view = true;
      if (this.no.length != 0) {
        this.contadoresDatos = res[0];
        this.calcular();
        this.imputmask();
      }else{
        this.imputmask();
        this.contadoresDatos = {bloqueosbajan: 'S/D',bloqueossuben: 'S/D',primerdato:  'S/D',total:  'S/D',totalbajada: 'S/D',totalbloqueos: 'S/D',totalmanana: 'S/D',totalmananab: 'S/D',totalnoche:  'S/D',totalnocheb: 'S/D',totalreal:  '0',totalsubida:  'S/D',totaltarde:  'S/D',totaltardeb: 'S/D',ultimodato:  'S/D'};
      }
        this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
      });
  }

  datosDiaContadores(){
    this.contadoresHoy = [];
    this.contadoresBloqueo = [];
    this.clearMarker();
    this.verContadores(this.ruta,this.unidad);
  }

  /* periodo */

  verContadoresperiodo(ruta,unidad){
    this.imputmask();
    this.viewperiodofechas = true;
    this.ruta = ruta;
    this.unidad = unidad;
    this.viewperiodofechasBoton = true;
    this.viewperiodobloqueosBoton = false;
  }

  verBloqueosPeriodo(ruta,unidad){
    this.imputmask();
    this.viewperiodofechas = true;
    this.ruta = ruta;
    this.unidad = unidad;
    this.viewperiodobloqueosBoton = true;
    this.viewperiodofechasBoton = false;
  }

  vertotalbloqueosPeriodo(){
    this.loader = true;
    this.view = false;
    this.viewperiodofechas = false;
    this.viewTotalBloqueos = true;
    let data = {
      ruta: this.ruta,
      unidad: this.unidad,
      fechaUNO: $(".fechaContadorInicio").val(),
      fechaDOS: $(".fechaContadorFin").val()
    };
    this.getservices.getData('contadoresBloqueosPeriodo',data)
    .subscribe( res => {
        this.contadoresBloqueo = res['data'];
        this.nomContadoresBloqueo = this.contadoresBloqueo.length;
        this.loader = false;
        if(this.nomContadoresBloqueo > 0){
          setTimeout(()=>{
            this.crearmarker();
          },3000);
        }
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
      });
  }

  porPeriodo(){
    this.view = false;
    this.viewperiodofechas = false;
    this.viewperiodo = true;
    this.loader = true;
    let data = {
      ruta: this.ruta,
      unidad: this.unidad,
      fechainicio: $(".fechaContadorInicio").val(),
      fechafin: $(".fechaContadorFin").val()
    };
    this.getservices.getData('datosPorPeriodo',data)
    .subscribe( res => {
        this.datosPorPeriodoData = res;
        this.totalesCalculo(res);
        this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
      });
  }

  totalesCalculo(res:any){
    for (var i = 0; i < res.length; ++i) {
      this.total_pasaje += res[i]['pasaje'];
      this.total_total += res[i]['total'];
      this.total_totalsuben += res[i]['totalsuben'];
      this.total_totalbajan += res[i]['totalbajan'];
      this.total_totalbloqueos += res[i]['totalbloqueos'];
      this.total_bloqueossuben += res[i]['bloqueossuben'];
      this.total_bloqueosbajan += res[i]['bloqueosbajan'];
      this.total_subidasm += res[i]['subidasm'];
      this.total_subidast += res[i]['subidast'];
      this.total_subidasn += res[i]['subidasn'];
      this.total_bajadasm += res[i]['bajadasm'];
      this.total_bajadast += res[i]['bajadast'];
      this.total_bajadasn += res[i]['bajadasn'];
    }
    this.calcularTotales();
  }
  calcularTotales(){
    let porcentajeestudiante = this.porcentaje;
    let porcentajenormal = (100 - porcentajeestudiante);
    let pasajeros:any = this.total_pasaje;
    let totalestudiante = ((pasajeros * porcentajeestudiante) / 100) * this.estudiante;
    let normal = ((pasajeros * porcentajenormal) / 100) * this.precio;
    let importe = totalestudiante+normal;
    let totalcombustible = this.combustible * this.datosPorPeriodoData.length;
    this.cobro = importe - totalcombustible;
    this.cobro_totales = importe - totalcombustible;
  }
  /* periodo */


  fechaHoy(){
    let now = new Date();
    let year = "" + now.getFullYear();
    let month = "" + (now.getMonth() + 1); if (month.length == 1) { month = "0" + month; }
    let day = "" + now.getDate(); if (day.length == 1) { day = "0" + day; }
    return year + "-" + month + "-" + day;
  }

  imputmask(){
    $(function () {
      Codebase.helpers(['datepicker','masked-inputs','select2']);
    });
  }

  hh_mm_ss() {
    let now = new Date();
    let hour = "" + now.getHours(); if (hour.length == 1) { hour = "0" + hour; }
    let minute = "" + now.getMinutes(); if (minute.length == 1) { minute = "0" + minute; }
    return hour + ":" + minute;
  }

  getStyle() {
    // snip snip -> fetch the url from somewhere
    let height = this.heightMap;
    let style = `height: ${height}px;`;
    
    // sanitize the style expression
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

}