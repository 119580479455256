import { ElementRef, Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { DomSanitizer  } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import swal from 'sweetalert';

import {
  ScriptService,
  GetserviesService
} from '../app.filesServices';

declare var google:any;

@Component({
  selector: 'app-mapa-custom',
  templateUrl: './mapa-custom.component.html'
})
export class MapaCustomComponent implements OnInit {

  loader:boolean = false;

	@ViewChild("search") searchElementRef: ElementRef;

	public map: any;

  token = JSON.parse(localStorage.getItem("token"));
  params:any = { buttons: false, timer: 2000 };

	lat: number = 22.73207149314701;
	lng: number = -101.92049204144308;
	zoom: number = 6;
	lat2: number;
	lng2: number;
	center: boolean = false;

  /// datos server
  vanderaServer:boolean;
  fechaServer:string;
  /// 

	heightMap:any = window.screen.height - 250;

  constructor(
  		private sanitizer: DomSanitizer,
		  private _loader: MapsAPILoader,
    	private _zone: NgZone,
      public script:ScriptService,
      public getservices:GetserviesService,
      public snackBar:MatSnackBar
    ) {
    this.script.loadScript();
  }

  public mapReady(map) {
		this.map = map;
	}

  ngOnInit() {
    this._loader.load().then(() => {
        let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {});
        google.maps.event.addListener(autocomplete, 'place_changed', () => {
            this.loader = true;
            this._zone.run(() => {
              let place = autocomplete.getPlace();

              this.map.setCenter({ lat: Number(place.geometry.location.lat()), lng: Number(place.geometry.location.lng()) });
        	    this.zoom = Number(16);
              //console.log(place);
              this.loader = false;
            });
        });
    });
    //// datos de mapa
    this.getdatosMapa();
    ///
  }

  getdatosMapa(){
    this.loader = true;
    let data = {
      pin: this.token.pin
    };
    this.getservices.getData0('dataMap',data)
    .subscribe( res => {
          if(res){
            this.vanderaServer = true;
            this.lat = Number(res.lat);
            this.lng = Number(res.logn);
            this.zoom = Number(res.zoom);
            this.fechaServer = res.updated_at.split(' ',1);
            this.loader = false;
          }else{
            this.vanderaServer = false;
            this.loader = false;
          }
        },err => {
          this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        });
  }

  guardar(url){
    if(this.lat2 && this.lng2){
      //// mando datos para guardar o editar
      this.loader = true;
      let data = {
        pin: this.token.pin,
        lat: this.lat2,
        lng: this.lng2,
        zoom: this.zoom,
      };
      this.getservices.setData(url,data)
      .subscribe( data => {
        if(data['res']==true){
          swal("¡Parametros guardados!", this.params );
          this.loader = false;
          //// borro y creo de nuevo el localstorage
          localStorage.removeItem("mapa");
          let map = {
              'lat': Number(this.lat2),
              'lng': Number(this.lng2),
              'zoom': Number(this.zoom)
            };
          localStorage.setItem('mapa', JSON.stringify(map));
          ///
          this.vanderaServer = true;
          ///
        }else{
          this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
          this.loader = false;
          this.vanderaServer = false;
        }
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
      });
    }else{
      this.snackBar.open('Latitud y Longitud sin editar', null, { duration: 3000 });
    }
  }

 
  zoomChange(event){
    this.zoom = event;
  }

  centerChange(event){
  	this.lat2 = event.lat;
  	this.lng2 = event.lng;
  	this.center = true;
  }

  placeMarker(event){
    ///console.log($event.coords.lat);
    ///console.log($event.coords.lng);
   	//console.log(event)
  }

  setCurrentPosition() {
    if ("geolocation" in navigator) {
      this.loader = true;
      this.zoom = Number(10);
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = Number(position.coords.latitude);
        this.lng = Number(position.coords.longitude);
        this.map.setCenter({ lat: Number(position.coords.latitude), lng: Number(position.coords.longitude) });
        this.zoom = Number(16);
        this.loader = false;
      });
    }
  }

  getStyle() {
    // snip snip -> fetch the url from somewhere
    let height = this.heightMap;
    let style = `height: ${height}px;`;
    
    // sanitize the style expression
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

}
