import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';

import swal from 'sweetalert';

import {
  ScriptService,
  GetserviesService
} from '../app.filesServices';

import { AngularFirestore } from '@angular/fire/firestore';

declare var $:any;
declare var Codebase:any;

@Component({
  selector: 'app-botonespanico',
  templateUrl: './botonespanico.component.html',
  styleUrls: ['./botonespanico.component.css']
})
export class BotonespanicoComponent implements OnInit {

  loader:boolean = false;
	token = JSON.parse(localStorage.getItem("token"));

  c5Array:any;
  hpArray:any;
  hc5Array:any;

  rutaSearchPanicos:any;
  rutaocliente:any;
  unidadesClientesArray:any = [];

  constructor(
  	public script:ScriptService,
    private router: Router, 
  	public snackBar:MatSnackBar,
  	public getservices:GetserviesService,
    private db: AngularFirestore
  	) {
  	this.script.loadScript();
    if(this.token['modouser'] == 'sistemas' || this.token['modouser'] == 'monitoreo' || this.token['modouser'] == 'monitoreoanalistas' || this.token['modouser'] == 'monitoreoadmin'){}else{
      this.router.navigate(['reload','mapa']); 
    }
  }

  ngOnInit() {
    this.onChangeSelect();
    // trae datos de rutas para select
    this.loader = true;
    let data = {
      pin: this.token.pin
    };
    this.getservices.getData('rutasoclientes',data)
    .subscribe( res => {
      this.rutaocliente = res;
      this.rutaSearchPanicos = this.rutaocliente[0].pin;
      this.unidadesPanico();
      if(this.rutaocliente.length == 1){
        $('.rutas').hide();
      }
      this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
    });
  }

  onChangeSelect() {
    $('.rutaSearchPanicos').on("change",()=>{
        this.unidadesClientesArray = [];
        this.rutaSearchPanicos = $(".rutaSearchPanicos").val();
        this.unidadesPanico();
    });
  }

  unidadesPanico() {
  	this.loader = true;
    let data = {
      pin: this.token.pin,
      ruta: this.rutaSearchPanicos
    };
    this.getservices.getData('equipos',data)
    .subscribe( res => {
        this.unidadesClientesArray = res;
        this.snackBar.open('Busqueda correcta - '+this.querutaAirView(this.rutaSearchPanicos), null, { duration: 3000 });
        this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
      });
    /// traer datos de unidades incativas
    this.getservices.getData('equiposInctivos',{})
    .subscribe( res => {
        this.c5Array = res['c5'];
        this.hpArray = res['hp'];
        this.hc5Array = res['hc5'];
      },err => {
        this.snackBar.open('Error de conexión en el servidor Datos incativas', null, { duration: 3000 });
      });
  }

  edit(tipo,id,campo){
  	this.loader = true;
    let data = {
      tipo: tipo,
      id:id,
      campo:campo
    };
    this.getservices.getData('equiposBotonesPanico',data)
    .subscribe( res => {
    	if(res['res'] == true){
    		this.snackBar.open('Editado correctamente', null, { duration: 3000 });
    	}else{
    		this.snackBar.open('Algo malo paso...', null, { duration: 3000 });
    	}
        this.loader = false;
        this.unidadesPanico();
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
      });
  }

  horasdesactivadas(id,tipoalerta){
    this.loader = true;
    let data = {
      id:id,
      tipo:tipoalerta
    };
    this.getservices.getData('equiposBotonesPanico24Horas',data)
    .subscribe( res => {
        if(res['res'] == true){
          this.snackBar.open('Desactivación de unidad por 24 horas...', null, { duration: 9000 });
        }else{
          this.snackBar.open('Algo malo paso al desactivar la unidad por 24 horas...', null, { duration: 6000 });
        }
        this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor 24 horas', null, { duration: 3000 });
        this.loader = false;
      });
  }

  editHelp(tipo,id,campo,tipoalerta){
    let alertvalor = 0;
    if(tipoalerta == 'c5'){
      alertvalor = 0;
    }else{
      alertvalor = 1;
    }
    if(tipo == alertvalor){
      swal("Seleccionar el tipo de desactivación que piensa hacer. (El de 24 horas se activa en automático)", {
        buttons: {
          horas: {
            text: "24 Horas",
            value: "horas",
          },
          indefinido: {
            text: "Tiempo indefinido",
            value: "indefinido",
          },
          cancelar: {
            text: "Cancelar",
            value: "cancelar",
          }
        },
      })
      .then((value) => {
        switch (value) {
       
          case "horas":
            this.edit(tipo,id,campo);
            this.horasdesactivadas(id,tipoalerta);
            break;
       
          case "indefinido":
            this.edit(tipo,id,campo);
            break;

          case "cancelar":
            this.snackBar.open('Se cancelo la acción...', null, { duration: 3000 });
            break;

        }
      });
    }else{
      this.edit(tipo,id,campo);
    }
  }

  datos(plan,id){
    this.loader = true;
    let data = {
      plan: ''+plan+'',
      id:id
    };
    this.getservices.getData('equiposDatos',data)
    .subscribe( res => {
      if(res['res'] == true){
        this.snackBar.open('Editado correctamente', null, { duration: 3000 });
      }else{
        this.snackBar.open('Algo malo paso...', null, { duration: 3000 });
      }
        this.loader = false;
        this.unidadesPanico();
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
      });
  }


  querutaAirView(id){
    for (var i = 0; i < this.rutaocliente.length; ++i) {
      if(this.rutaocliente[i].pin == id){
        return this.rutaocliente[i].airview;
      }
    }
  }

}
