import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AgmMap } from '@agm/core';

import swal from 'sweetalert';

import {
  ScriptService,
  GetserviesService
} from '../app.filesServices';

declare var $:any;

@Component({
  selector: 'app-camarascount',
  templateUrl: './camarascount.component.html',
  styleUrls: ['./camarascount.component.css']
})
export class CamarascountComponent implements OnInit {

	loader:boolean = false;

	public map: any;

	token = JSON.parse(localStorage.getItem("token"));
	params:any = { buttons: false, timer: 2000 };

	rutaocliente:any;
	unidadeslist:any;
	ruta:any;
	nombreRuta:any;
	mes:any = 0;
	detalleslistUsers:any;
	detalleslistSessions:any = false;

	unidadD:any;

  constructor(
  	public script:ScriptService,
  	public snackBar:MatSnackBar,
  	public getservices:GetserviesService
  	) {
  	this.script.loadScript();
  }

  ngOnInit() {
  	// trae datos de rutas para select
    this.loader = true;
    let data = {
      pin: this.token.pin
    };
    this.getservices.getData('rutasoclientes',data)
    .subscribe( res => {
      this.rutaocliente = res;
      this.ruta = this.rutaocliente[0].pin;
      this.nombreRuta = this.rutaocliente[0].label;
      this.unidadesTransmicion(this.ruta);
      this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
    });
  }

  detalleSesiones(unidadID:any,nombreUnidad:any){
  	this.detalleslistUsers = [];
  	this.detalleslistSessions = [];
  	this.unidadD = nombreUnidad;
  	this.loader = true;
    let data = {
      unidad: unidadID,
      mes: this.mes
    };
    this.getservices.getData('detallesSesiones',data)
    .subscribe( res => {
      this.detalleslistUsers = res['users'];
      this.detalleslistSessions = res['sesiones'];
      this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
    });
  }

  onChange(rutaID:any) {
  	this.mes = 0;
  	for (var i = 0; i < this.rutaocliente.length; ++i) {
  		if(this.rutaocliente[i].pin == rutaID){
  			this.ruta = rutaID;
  			this.nombreRuta = this.rutaocliente[i].label;
  		}
  	}
  	this.unidadesTransmicion(rutaID);
  }

  mesQuery(consulta:any){
  	this.mes = consulta;
  	this.unidadesTransmicion(this.ruta);
  }

  unidadesTransmicion(rutaID:any){
  	this.loader = true;
    let data = {
      ruta: rutaID,
      mes: this.mes
    };
    this.getservices.getData('countsesseonunidad',data)
    .subscribe( res => {
    //console.log(res);
      this.unidadeslist = res;
      if(this.unidadeslist.length == 0){
      	this.snackBar.open('Sin datos encontrados...', null, { duration: 4000 });
      }
      this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
    });
  }

}
