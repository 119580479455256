import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'segundosminutos'
})
export class SegundosminutosPipe implements PipeTransform {

  transform(value: any, args?: any): any {
  	if(value>0){
  		return (new Date(value * 1000)).toUTCString().match(/(\d\d:\d\d:\d\d)/)[0];;	
  	}else{
  		return '00:00:00';
  	}
    
  }

}
