import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AgmMap, AgmPolygon, AgmCircle, AgmPolyline } from '@agm/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';


import { NotifierService } from 'angular-notifier';

import { FormBuilder, FormGroup, FormArray, FormControl, ValidatorFn } from '@angular/forms';
import { of } from 'rxjs';

import swal from 'sweetalert';

import {
  GetserviesService
} from '../../app.filesServices';

import { AngularFirestore } from '@angular/fire/firestore';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

declare var $:any;
declare var Codebase:any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

  @ViewChild(AgmMap) mapGeo: any;

  private readonly notifier: NotifierService;

  public map: any;

  rutas:any;

  audio:any;

  clienteslista:any;

  items:Array<any> = [];
  itemsSinTransmitir:any;
  itemsciudad:any[] = [];

  noAlerts:number = 0;
  noAlertsciudad:number = 0;
  noAlertsSinTransmitir:number = 0;

  loader:boolean = false;

  params:any = { buttons: false, timer: 2000 };

  nuevacontrasena:string;

  token = JSON.parse(localStorage.getItem("token"));
  mapa = JSON.parse(localStorage.getItem("mapa"));
  heightMap:any = window.screen.height;

  lat:any = this.mapa['lat'];
  lng:any = this.mapa['lng'];
  zoom:any = 17;

  nombreuser:string = this.token.nombre;
  correouser:string = this.token.correo;

  pagos:any;
  total:any;

  //// modal alerts
  titulo:any;
  ruta:any;
  unidad:any;
  alias:any;
  imei:any;
  fecha:any;
  hora:any;
  speed:any;
  direccion:any;
  nombre:any;
  tel1:any;
  tel2:any;
  geo:any;
  sibive:any;
  cargo:any;
  responsable:any;
  tel_responsable:any;
  responsableunidad:any;

  datasibivefoto:boolean = false;
  verFoto:boolean = false;

  stopinterval:any;
  stopintervalSinTransmision:any;

  botonSigueCam:boolean = false;

  rutaSelect:any;
  unidadSelect:any;
  unidadesList:any;
  unidadesListActivas:any;
  noAU:any = 0;
  tipoSearch:any = 'imei';
  datoSearch:any;
  
  datoSearchPrintJson:any;
  datoSearchPrintView:boolean = false;
  datoSearchGrupo:any;
  datoSearchGrupoView:boolean = false;

  infoviewUnidad:boolean = false;

  items2: any[] = [];
  rutaSeach: any;
  rutaSeachUrl: any;
  config2: any = {'max': 7, 'placeholder': 'Ruta', 'sourceField': ['airview']};

  videoslista:any;

  gruposList:any;
  gruposSelect:any;


  tiempoUnidadesDetenidas:number = 10;
  tiempoUnidadesRestaMinutosActivas:number = 10;

  constructor(
    notifierService: NotifierService,
    private db: AngularFirestore,
    public snackBar:MatSnackBar,
    private sanitizer: DomSanitizer,
		private router:Router,
    public http:HttpClient,
    public getservices:GetserviesService,
    private formBuilder: FormBuilder
             ) {
    this.notifier = notifierService;
    this.audio = new Audio();
    this.audio.src = "../../../../assets/sound/alert.mp3";
    this.audio.load();
    this.audio.play();
    this.audio.loop = true;
    this.audio.muted = true;
    ///
  }


  videosAlerts(){
    if(this.token.modouser == 'sistemas' || this.token.modouser == 'monitoreo'){
      this.db.collection('videosolicitud').valueChanges()
       .subscribe((data:any[]) => {
         this.videoslista = data;
         if(this.videoslista.length>0){
           this.audio.muted = false;
           $("#modal-videosAlertas").modal("show");
         }
      });
    }
  }

  atender(clave:any){
    this.audio.muted = true;
    $("#modal-videosAlertas").modal("hide");
    this.snackBar.open('Atendiendo alerta...', null, { duration: 3000 });  
    this.db.collection("videosolicitud").doc(clave).delete();
    this.router.navigate(['reload','videosadmin']);
  }

  onSelect(item: any) {
    this.rutaSeach = item['airview'];
    this.rutaSeachUrl = item['label'];
  }

  /* funcion que cambia conforme escribes
  onInputChangedEvent(val: any) {
    this.rutaSeach = val;
  }
  */

  playAudio(){
    this.audio.muted = false;
    $('.alertaPanico').addClass('blink_me');
  }

  stopaudi(){
    this.audio.muted = true;
    $('.alertaPanico').removeClass('blink_me');
  }

  public mapReady(map) {
    this.map = map;
  }

  ngOnInit() {
    this.alertaDeTransmision5min();
    this.stopintervalSinTransmision = setInterval(()=> {
      this.alertaDeTransmision5min();
    },120000); //cada dos minutos
    this.videosAlerts();
    this.traerGeocercas();
    this.getAlerts();
    /// checo si hay status de alertas
    this.checkstatusalert();
    this.stopinterval = setInterval(()=> {
      if(this.noAlerts >= 1){
        this.getAlerts();
      }else{
        this.checkstatusalert();
      }
    },30000);
    ///
    // trae datos de rutas para traer alertas
    let data = {
      pin: this.token.pin
    };
    this.getservices.getData('rutasoclientes',data)
    .subscribe( (res:any[]) => {
      this.items2 = res;
      this.rutas = res;
      this.clienteslista = res;
      this.rutaSelect = this.clienteslista[0].label;
      this.rutaSeach = this.clienteslista[0].airview;
      this.rutaSeachUrl = this.rutaSelect;
        if(this.clienteslista.length == 1){
          $('.rutas').hide();
        }
      ///
    },err => {
      this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });  
    });
    /*
    /// condicion para no traer alertas a los consecionarios
    if(this.token.nivel != 'Concesionario'){
    // trae datos de rutas para traer alertas
    let data = {
      pin: this.token.pin
    };
    this.getservices.getData('rutasoclientes',data)
    .subscribe( (res:any[]) => {
      this.rutas = res;
      let countData:any = res;
      countData = countData.length;
      ///
      if(countData == 1){
        this.db.collection('alerts', ref => 
          ref.where('ruta', '==', res[0]['label']).orderBy("fecha","asc").orderBy("hora","asc")
        ).valueChanges()
         .subscribe((data:any[]) => {
           this.items = data;
           this.checkalerts();
        });
      }else{
        if(this.token.correo == 'hector.san.ram@gmail.com' || this.token.correo == 'hector_san_ram@hotmail.com'){
          this.db.collection('alerts').valueChanges()
           .subscribe((data:any[]) => {
             this.items = data;
             this.checkalerts();
          });
        }
      }
      ///
    },err => {
      this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });  
    });
    }/// fin condicion de alertas
    */
  }

  alertaDeTransmision5min(){
    let data = {
      pin: this.token.pin
    };
    this.getservices.getData('checkTransPlatform',data)
    .subscribe( res => {
        this.itemsSinTransmitir = res;
        this.noAlertsSinTransmitir =  this.itemsSinTransmitir.length;
      },err => {
        this.snackBar.open('Error de conexión en mala transmision ALert', null, { duration: 3000 });
        this.loader = false;
    });
  }

  onChange(rutaID:any) {
    this.unidades(rutaID);
  }

  traerUnidades(){
    this.unidades(this.rutaSelect);
    this.gruposDatos();
  }

  traerUnidadesActivas(){
    this.loader = true;
    let data = {
      tiempo: this.tiempoUnidadesDetenidas,
      minutosActividad: this.tiempoUnidadesRestaMinutosActivas
    };
    this.getservices.getData('detenidasUnidades', data)
    .subscribe( res => {
      this.unidadesListActivas = res;
      this.noAU = this.unidadesListActivas.length;
      this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
    });
  }

  unidades(ruta:any){
    this.loader = true;
    let data = {
      ruta: ruta,
      pin: this.token.pin
    };
    this.getservices.getData('unidadesNombreRutaFiltro',data)
    .subscribe( res => {
        if(res != 0){
          this.unidadesList = res;
          this.unidadSelect = this.unidadesList[0].imei;
          this.loader = false;
        }else{
          this.unidadesList = [];
          this.loader = false;
        }
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
    });
  }

  gruposDatos(){
    this.loader = true;
    this.getservices.getData('grupos',{})
    .subscribe( res => {
      this.gruposList = res;
      this.gruposSelect = this.gruposList[0].grupo;
      this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
    });
  }

  irmapa(ruta:any){
    $("#modal-datosUnidades").modal("hide");
    this.router.navigate(['reloadid','maparutas',ruta]);
  }

  buscarDatosGrupos(){
    this.loader = true;
    this.datoSearchGrupo = [];
    let data = {
      grupo: this.gruposSelect
    };
    this.getservices.getData('searchUnidadesGrupo',data)
    .subscribe( (res:any[]) => {
      this.loader = false;
      if(res['res'] == true){
        this.datoSearchPrintView = false;
        this.datoSearchGrupoView = true;
        this.datoSearchGrupo = res['datos'];
        this.infoviewUnidad = true;
      }else{
        this.datoSearchPrintView = false;
        this.datoSearchGrupoView = false;
        this.infoviewUnidad = false;
        this.snackBar.open('No se encontro ninguna respuesta...', null, { duration: 3000 });  
      }
    },err => {
      this.loader = false;
      this.snackBar.open('Error de conexión en el servidor | Por Ruta', null, { duration: 3000 });  
    });
  }

  buscarDatosUnidadesRU(){
    this.loader = true;
    this.datoSearchPrintJson = [];
    let data = {
      ruta: this.rutaSelect,
      unidad: this.unidadSelect
    };
    this.getservices.getData('searchUnidadesRU',data)
    .subscribe( (res:any[]) => {
      this.loader = false;
      if(res['res'] == true){
        this.datoSearchGrupoView = false;
        this.datoSearchPrintView = true;
        this.datoSearchPrintJson = eval(res['datos'][0]);
        this.infoviewUnidad = true;
      }else{
        this.datoSearchGrupoView = false;
        this.datoSearchPrintView = false;
        this.infoviewUnidad = false;
        this.snackBar.open('No se encontro ninguna respuesta...', null, { duration: 3000 });  
      }
    },err => {
      this.loader = false;
      this.snackBar.open('Error de conexión en el servidor | Por Ruta', null, { duration: 3000 });  
    });
  }
  buscarDatosUnidades(){
    this.loader = true;
    this.datoSearchPrintJson = [];
    let data = {
      tipo: this.tipoSearch,
      dato: this.datoSearch
    };
    this.getservices.getData('searchUnidades',data)
    .subscribe( (res:any[]) => {
      this.loader = false;
      if(res['res'] == true){
        this.datoSearchGrupoView = false;
        this.datoSearchPrintView = true;
        this.datoSearchPrintJson = eval(res['datos'][0]);
        this.infoviewUnidad = true;
      }else{
        this.datoSearchGrupoView = false;
        this.datoSearchPrintView = false;
        this.infoviewUnidad = false;
        this.snackBar.open('No se encontro ninguna respuesta...', null, { duration: 3000 });  
      }
    },err => {
      this.loader = false;
      this.snackBar.open('Error de conexión en el servidor | Por dato', null, { duration: 3000 });  
    });
  }

  ngOnDestroy() {
    this.audio.pause();
    this.audio = '';
    clearTimeout(this.stopinterval);
    clearTimeout(this.stopintervalSinTransmision);
  }

  checkstatusalert(){
    let data = {
      pin: this.token.pin
    };
    this.getservices.getData('statusAlert',data)
    .subscribe( (res:any[]) => {
      if(res['status'] == true){
        this.getAlerts();
      }
    },err => {
      this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });  
    });
  }

  getAlerts(){
    let data = {
      pin: this.token.pin
    };
    this.getservices.getData('getAlertsAll',data)
    .subscribe( (res:any[]) => {
      this.items = [];
      for (var i = 0; i < res.length; ++i) {
        if(res[i]['tipo'] == 'ac alarm'){
          this.notifier.notify( 'error', 'Alerta!!! Unidad '+res[i]['unidad']+' | Ruta '+res[i]['ruta']+' desconectada' );
          this.UpdateAlert(res[i]['id'],0);
          //this.clearArray(res[i]['id']);
        }else if(res[i]['tipo'] == 'low battery'){
          this.notifier.notify( 'warning', 'Unidad '+res[i]['unidad']+' | Ruta '+res[i]['ruta']+' Transmitiendo con bateria' );
          this.UpdateAlert(res[i]['id'],0);
          //this.clearArray(res[i]['id']);
        }else{
          this.items.push(res[i]);
        }
      }
      this.checkalerts();
    },err => {
      this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });  
    });
  }

  checkalerts(){
    this.noAlerts = this.items.length;
    if(this.noAlerts > 0){
      this.playAudio();
    }else{
      this.stopaudi();
    }
  }

  helpme(imei:number){
    let data = {
        imei: imei,
        cmd: 'helpme'
    };
    this.getservices.setData('cmd',data)
      .subscribe( data => {
        if(data['res']==true){
          this.snackBar.open('Help me... Send!!!', null, { duration: 3000 });
        }else{
          this.snackBar.open('Help me... Error!!!', null, { duration: 3000 });
        }
      },err => {
        this.snackBar.open('Error de conexión en el servidor... SMS', null, { duration: 3000 });
      });
  }

  clearArray(id:any){
    let temporal = this.items;
    this.items = [];
    for (var i = 0; i < temporal.length; ++i) {
      if(temporal[i].id != id){
        this.items.push(temporal[i]);
      }
    }
    this.checkalerts();
  }

  UpdateAlert(id:any,action:any){
    let data = {
        id: id,
        userID: this.token['pin'],
        action: action
    };
    this.getservices.setData('AlertUpdateStatus',data)
      .subscribe( data => {
        if(data['res']==false){
          this.snackBar.open('Algo malo paso... O ya fue atendida la alerta.', null, { duration: 3000 });
        }
      },err => {
        this.snackBar.open('Error de conexión en el servidor... SMS', null, { duration: 3000 });
      });
  }

  closeAlert(dato:any){
    //// clear firestore
    //this.db.collection("alerts").doc(dato['key']).delete();
    this.clearArray(dato['id']);
    this.UpdateAlert(dato['id'],1);
    ///
    this.map.setCenter({ lat: Number(dato['lat']), lng: Number(dato['lng']) });
    this.lat = dato['lat'];
    this.lng = dato['lng'];
    this.zoom = 19;
    ///
    if(dato['tipo'] == 'help me'){
      this.helpme(dato['imei']);
      this.titulo = 'Alerta de panico';
      this.botonSigueCam = true;
    }else if(dato['tipo'] == 'salida'){
      this.titulo = 'Salida de Geocerca - '+dato['geo'];
      if(dato['nivelGeo'] == 1){
        this.botonSigueCam = true;
      }else{
        this.botonSigueCam = false;
      }
    }else if(dato['tipo'] == 'entro'){
      this.titulo = 'Entrada de Geocerca - '+dato['geo'];
      if(dato['nivelGeo'] == 1){
        this.botonSigueCam = true;
      }else{
        this.botonSigueCam = false;
      }
    }
    ///
    this.direccion = '...';
    this.ruta = dato['ruta'];
    this.unidad = dato['unidad'];
    this.alias = dato['alias'];
    this.imei = dato['imei'];
    this.cargo = dato['cargo'];
    this.responsable = dato['responsable'];
    this.tel_responsable = dato['tel_responsable'];
    this.responsableunidad = dato['responsableunidad'];
    this.fecha = dato['fecha'];
    this.hora = dato['hora'];
    this.speed = dato['speed'];
    this.nombre = dato['nombre'];
    this.tel1 = dato['tel1'];
    this.tel2 = dato['tel2'];
    this.geo = dato['geo'];
    this.sibive = dato['sibive'];
    ///
    if(dato['sibive'] == 'si'){
      this.verFoto = false;
      this.datasibivefoto = false;
      let misibive = {
        ruta: dato['ruta'],
        unidad: dato['unidad'],
      };
      this.getservices.getDataSibive('https://admin.wred.mx/api/V1/sibive',misibive)
      .subscribe( (sibive:any[]) => {
        this.datasibivefoto = sibive['foto'];
      });
    }
    ///
  }

  adress(){
    ///
    this.http.get('https://maps.googleapis.com/maps/api/geocode/json?latlng='+this.lat+','+this.lng+'&key='+environment.ubicacion)
    .subscribe((data) => {
      if(data['status'] == "OK"){
        this.direccion = data['results'][2].formatted_address;
      }else{
        this.direccion = 'No hay dirección';
      }
    });
  }

  miFoto(){
    this.verFoto = true;
  }

  borrartodas(){
    for (var i = 0; i < this.items.length; ++i) {
      //// clear firestore
      //this.db.collection("alerts").doc(this.items[i]['key']).delete();
      if(this.items[i]['tipo'] == 'help me'){
        this.helpme(this.items[i]['imei']);
      }
      this.UpdateAlert(this.items[i]['id'],2);
    }
    this.items = [];
    this.checkalerts();
    this.snackBar.open('Alertas eliminadas correctamente', null, { duration: 5000 });
    this.stopaudi();
  }

  pagosData(){
    this.loader = true;
    let data = {
        pin: this.token.pin
    };
    this.getservices.getData('pagosFactura',data)
      .subscribe( data => {
        if(data['res']==true){
          this.pagos = data['data'];
          this.total = data['total'];
        }else{
          this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        }
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
      });
    this.loader = false;
  }

  cambiarpass(){
    if (this.nuevacontrasena) { 
      this.loader = true;
      let data = {
        pin: this.token.pin,
        nuevacontrasena: this.nuevacontrasena
      };
      this.getservices.setData('resetPass',data)
      .subscribe( res => {
          if(res['res']==true){
            this.snackBar.open('Contraseña editada correctamente', null, { duration: 3000 });
            this.loader = false;
            this.auth();
          }else{
            this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
            this.loader = false;
          }
        },err => {
          this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
          this.loader = false;
        });
    } else {
      this.snackBar.open('No hay dato que editar', null, { duration: 3000 });
    }
  }

  cambiarDatos(){
    if (this.nombreuser && this.correouser) { 
      if (this.nombreuser == this.token.nombre && this.correouser == this.token.correo) { 
        this.snackBar.open('No hay datos que editar', null, { duration: 3000 });
        return;
      }
      this.loader = true;
      let data = {
        pin: this.token.pin,
        nombreuser: this.nombreuser,
        correouser: this.correouser
      };
      this.getservices.setData('resetData',data)
      .subscribe( res => {
          if(res['res']==true){
            this.snackBar.open('Datos editados correctamente', null, { duration: 3000 });
            this.loader = false;
            this.auth();
          }else{
            this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
            this.loader = false;
          }
        },err => {
          this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
          this.loader = false;
        });
    } else {
      this.snackBar.open('No hay dato o datos que editar', null, { duration: 3000 });
    }
  }


  traerGeocercas(){
    let data = {
      pin: this.token.pin
    };
    this.getservices.getData('geocercasMapa',data)
    .subscribe( res => {
      this.checarGeo(res);
    },err => {
      this.snackBar.open('Error de conexión en el servidor. geocercas', null, { duration: 3000 });  
    });
  }

  checarGeo(geo:any){
    for (var i = 0; i < geo.length; ++i) {
      if(geo[i]['tipo'] == 'circular'){
        this.hacerCircleGeo(geo[i]);
      }else if(geo[i]['tipo'] == 'poligonal'){
        this.hacerPolygonGeo(geo[i]);
      }else if(geo[i]['tipo'] == 'lineal'){
        this.hacerPolylineGeo(geo[i]);
      }
    }
  }

  hacerCircleGeo(cir:any){
    let color = cir['color'];
    cir = JSON.parse(cir.coords);
    this.mapGeo._mapsWrapper.createCircle({
      center: { lat: cir['lat'], lng: cir['lng'] },
      radius: Number(cir['radio']),
      strokeColor: color,
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillColor: color,
      fillOpacity: 0.3,
      visible: true
    });
  }

  hacerPolygonGeo(poly:any){
    this.mapGeo._mapsWrapper.createPolygon({
      paths: eval(poly['coords']),
      strokeColor: poly['color'],
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillColor: poly['color'],
      fillOpacity: 0.3,
      visible: true
    });
  }

  hacerPolylineGeo(line:any){
    this.mapGeo._mapsWrapper.createPolyline({
      strokeColor: line['color'],
      strokeOpacity: 0.6,
      strokeWeight: 5,
      path: eval(line['coords']),
      visible: true
    });
  }

  consultarRutasSearch(){
    this.router.navigate(['reloadid','maparutas',this.rutaSeachUrl]);
  }

  consultarRutas(){
    let datoSelect = $('.queRutas').val().length;
    let rutas = '';
    for (var i = 0; i < datoSelect; ++i) {
      rutas = rutas + $('.queRutas').val()[i];
      if(datoSelect-1 != i){
        rutas = rutas+':';
      }
    }
    this.router.navigate(['reloadid','maparutas',rutas]);
  }

  getStyle() {
    // snip snip -> fetch the url from somewhere
    let height;
    let style;
    if(this.noAlerts <= 7){
      style = `height: auto;`;
    }else{
      height = this.heightMap * 0.42;
      style = `height: ${height}px;`;
    }
    
    // sanitize the style expression
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

  auth(){
    this.stopaudi();
    localStorage.removeItem("token");
    localStorage.removeItem("mapa");
    localStorage.removeItem("token_sibive");
		swal("¡Sesión cerrada!", this.params );
		this.router.navigate(['login']);
    location.reload(true);
	}

}