export { LoginComponent } from './login/login.component';
export { MapaComponent } from './mapa/mapa.component';
export { MapaCustomComponent } from './mapa-custom/mapa-custom.component';
export { UnidadesComponent } from './unidades/unidades.component';
export { RutaComponent } from './ruta/ruta.component';
export { ParadasComponent } from './paradas/paradas.component';
export { GeocercasComponent } from './geocercas/geocercas.component';
export { AsociacionComponent } from './asociacion/asociacion.component';
export { AlertsComponent } from './alerts/alerts.component';
export { VelocidadesComponent } from './velocidades/velocidades.component';
export { MinutoaminutoComponent } from './minutoaminuto/minutoaminuto.component';
export { ParosdemotorComponent } from './parosdemotor/parosdemotor.component';
export { TransmicionComponent } from './transmicion/transmicion.component';
export { GeovirtualComponent } from './geovirtual/geovirtual.component';
export { CamarasComponent } from './camaras/camaras.component';
export { GeoreportComponent } from './georeport/georeport.component';
export { PosicionesComponent } from './posiciones/posiciones.component';
export { UsersComponent } from './users/users.component';
export { DistanciatiempoComponent } from './distanciatiempo/distanciatiempo.component';
export { ReportevelocidadesComponent } from './reportevelocidades/reportevelocidades.component';
export { ZonasrojasComponent } from './zonasrojas/zonasrojas.component';
export { ConexionComponent } from './conexion/conexion.component';
export { PlanchadasbasesComponent } from './planchadasbases/planchadasbases.component';
export { IgnicionComponent } from './ignicion/ignicion.component';
export { AlertaspanicoComponent } from './alertaspanico/alertaspanico.component';
export { GeocercasalertasComponent } from './geocercasalertas/geocercasalertas.component';
export { GeocercasmasterComponent } from './geocercasmaster/geocercasmaster.component';
export { BotonespanicoComponent } from './botonespanico/botonespanico.component';
export { ReportealertaComponent } from './reportealerta/reportealerta.component';
export { CsinfoComponent } from './csinfo/csinfo.component';
export { ReportransmicionComponent } from './reportransmicion/reportransmicion.component';
export { TelefonosmalComponent } from './telefonosmal/telefonosmal.component';
export { SindatoscontacotsComponent } from './sindatoscontacots/sindatoscontacots.component';
export { RutasdatosComponent } from './rutasdatos/rutasdatos.component';
export { CobrostransmisionComponent } from './cobrostransmision/cobrostransmision.component';
export { CamarascountComponent } from './camarascount/camarascount.component';
export { CamarascountuserreportComponent } from './camarascountuserreport/camarascountuserreport.component';
export { TestComponent } from './test/test.component';
export { CobroswredComponent } from './cobroswred/cobroswred.component';
export { LagosComponent } from './lagos/lagos.component';
export { ContadoresComponent } from './contadores/contadores.component';
export { DetectaComponent } from './detecta/detecta.component';
export { VideosolicitudComponent } from './videosolicitud/videosolicitud.component';
export { VideosadminComponent } from './videosadmin/videosadmin.component';
export { Sintransmision2diasyperiodoComponent } from './sintransmision2diasyperiodo/sintransmision2diasyperiodo.component';
export { SolicitudvideowhatsComponent } from './solicitudvideowhats/solicitudvideowhats.component';
export { ContadoresmdbractualComponent } from './contadoresmdbractual/contadoresmdbractual.component';
///
export { ReloadComponent } from './reload/reload.component';
export { ReloadidComponent } from './reloadid/reloadid.component';
export { ReloadalertsComponent } from './reloadalerts/reloadalerts.component';
export { ReloadvelocidadesComponent } from './reloadvelocidades/reloadvelocidades.component';
export { ReloadminutoaminutoComponent } from './reloadminutoaminuto/reloadminutoaminuto.component';
export { ReloadparosComponent } from './reloadparos/reloadparos.component';