import { Component, OnInit } from '@angular/core';
import { DomSanitizer  } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import swal from 'sweetalert';

import {
  ScriptService,
  GetserviesService
} from '../app.filesServices';

declare var $:any;

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html'
})
export class UsersComponent implements OnInit {

  loader:boolean = false;
  token = JSON.parse(localStorage.getItem("token"));
  heightMap:any = window.screen.height - 250;

  usuarios:any;
  rutaocliente:any;
  ruta:any;
  usuario:any;

  swal:any = {
      title: "¿Seguro que quiere eliminar el registro?",
      text: "No se podra recuperar el registro",
      icon: "warning",
      buttons: true,
      dangerMode: true,
  };
  icon:any = {icon: "success"};


	formnuevo:boolean = false;
	formedit:boolean = false;

	tipo:any = 'Analista';
	status:any = 'Activo';
  mdvr:any = 'no';
	nombre:any;
	email:any;
	pass:any;
	con_pass:any;
  send_email:any = 'no';

  edit_id:any;
	edit_tipo:any;
	edit_status:any;
  edit_mdvr:any;
	edit_nombre:any;
	edit_email:any;
	edit_pass:any;
	edit_con_pass:any;
  edit_send_email:any;


  userasociar:any;
  unidades:any;

  nombreUserLogins:string;
  idUserLogins:any;
  listaLogins:any;
  listaLoginsCount:number = 0;
  // logins
  timeFullInicioLogins:any = this.fechaHoy()+' 00:00';
  timeFullFinLogins:any = this.fechaHoy()+' 23:59';

  constructor(
  	private router:Router,
    public script:ScriptService,
    public getservices:GetserviesService,
    public snackBar:MatSnackBar,
    private sanitizer: DomSanitizer
  ) { 
    this.script.loadScript();
  }

  ngOnInit() {
    this.onChangeSelect();
  	// trae datos de rutas para select
    this.loader = true;
    let data = {
      pin: this.token.pin
    };
    this.getservices.getData('rutasoclientes',data)
    .subscribe( res => {
      this.rutaocliente = res;
      this.ruta = this.rutaocliente[0].pin;
      this.traerUsuarios(this.ruta);
      if(this.rutaocliente.length == 1){
	    $('.rutas').hide();
	  }
      this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
    });
  }

  onChangeSelect() {
    $('.js-select2').on("change",()=>{
        this.ruta = $(".rutaSearchUsers").val();
        this.traerUsuarios(this.ruta);
    });
  }

  traerUsuarios(ruta:any){
  	// trae datos de rutas para select
    this.loader = true;
    let data = {
      ruta: ruta
    };
    this.getservices.getData('traerUsuarios',data)
    .subscribe( res => {
      this.usuarios = res;
      this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
    });
  }

  editar(data){
    this.edit_id = data['id'];
  	this.formnuevo = false;
  	this.formedit = true;
  	///
  	this.edit_tipo = data['tipo_usuarios'];
  	this.edit_status = data['status'];
    this.edit_mdvr = data['mdvr'];
  	this.edit_nombre = data['name'];
  	this.edit_email = data['email'];
    this.edit_send_email = data['send_email'];
  }

  editUser(){
  	if(this.edit_tipo && this.edit_status && this.edit_nombre && this.edit_email){
      if(this.edit_pass && this.edit_con_pass){
        if(this.edit_pass != this.edit_con_pass){
          this.snackBar.open('La contraseña no coincide...', null, { duration: 3000 });
          return;
        }
      }
      this.loader = true;
      let data = {
        id: this.edit_id,
        tipo: this.edit_tipo,
        status: this.edit_status,
        mdvr: this.edit_mdvr,
        nombre: this.edit_nombre,
        email: this.edit_email,
        pass: this.edit_pass,
        send_email: this.edit_send_email
      };
      this.getservices.setData('editUsuarios',data)
        .subscribe( res => {
            if(res['res']==true){
              swal("Usuario editado correctamente...", this.icon);
              this.traerUsuarios(this.ruta);
            }else if(res['res']=='exist'){
              this.snackBar.open('Correo electronico ya existente...', null, { duration: 3000 });
              this.loader = false;
            }else{
              this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
              this.loader = false;
            }
            },err => {
              this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
              this.loader = false;
            });
  	}else{
  		this.snackBar.open('Hay algun campo que llenar...', null, { duration: 5000 });
  	}
  }

  eliminar(id:any){
  	swal(this.swal)
    .then((willDelete) => {
      if (willDelete) {      	
        this.loader = true;
        let data = {
          id: id
        };
        this.getservices.setData('deliteUsuarios',data)
        .subscribe( res => {
            if(res['res']==true){
              swal("!Unidad eliminada¡", this.icon);
              this.traerUsuarios(this.ruta);
            }else{
              this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
              this.loader = false;
            }
            },err => {
              this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
              this.loader = false;
            });
      }
    });
  }

  nuevo(){
  	this.formnuevo = true;
  	this.formedit = false;
  }

  saveUser(){
  	if(this.tipo && this.status && this.mdvr && this.nombre && this.email && this.pass && this.con_pass && this.pass == this.con_pass){
      this.loader = true;
      let data = {
        ruta: this.ruta,
        tipo: this.tipo,
        status: this.status,
        mdvr: this.mdvr,
        nombre: this.nombre,
        email: this.email,
        pass: this.pass,
        send_email: this.send_email
      };
      this.getservices.setData('saveUsuarios',data)
        .subscribe( res => {
            if(res['res']==true){
              swal("Usuario guardado correctamente...", this.icon);
              this.traerUsuarios(this.ruta);
            }else if(res['res']=='exist'){
              this.snackBar.open('Correo electronico ya existente...', null, { duration: 3000 });
              this.loader = false;
            }else{
              this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
              this.loader = false;
            }
            },err => {
              this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
              this.loader = false;
            });
  	}else{
  		this.snackBar.open('Hay algun campo que llenar o la contraseña no coincide...', null, { duration: 5000 });
  	}
  }


  asociarunidades(nombre:any,user:any,ruta:any){
    this.userasociar = nombre;
    this.ruta = ruta;
    this.usuario = user;
    this.loader = true;
    let data = {
        ruta: this.ruta,
        user: this.usuario
      };
    this.getservices.setData('unidadesUser',data)
    .subscribe( res => {
      this.unidades = res;
      this.loader = false;
    },err => {
      this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
      this.loader = false;
    });
  }

  fullSaveunidadesUser(){
    $(".asociarUnidadesUsuarios > option").prop("selected","selected");
    $(".asociarUnidadesUsuarios").trigger("change");
    this.asociarSaveunidadesUser(false);
  }

  asociarSaveunidadesUser(unidadesLimpia:any){
    let relacionUnidades;
    if(unidadesLimpia == true){
      relacionUnidades = '';
    }else{
      relacionUnidades = $(".asociarUnidadesUsuarios").val();
    }
    if(!relacionUnidades.length && unidadesLimpia == false){
      this.snackBar.open('Los campos estan vacios... Unidades sin relación.', null, { duration: 3000 });
    }else{
    this.loader = true;
    let datasave = {
      user: this.usuario,
      routesorclients_id: this.ruta,
      unidades: relacionUnidades
    };
    this.getservices.setData('unidadesUserSave',datasave)
    .subscribe( data => {
      if(data['res']==true){
        this.traerUsuarios(this.ruta);
        this.snackBar.open('Unidades asociadas correctamente...', null, { duration: 5000 });
      }else{
        this.snackBar.open('Error de conexión en el servidor 1', null, { duration: 3000 });
      }
    },err => {
        this.snackBar.open('Error de conexión en el servidor 2', null, { duration: 3000 });
    });
    this.loader = false;
    }
  }

  logins(user:any,id:any){
    this.nombreUserLogins = user;
    this.idUserLogins = id;
  }

  consultaLogins(){
    let fecha1 = $(".timeFullInicioLogins").val();
    let fecha2 = $(".timeFullFinLogins").val();
    ///
    this.loader = true;
    let logindata = {
      user: this.idUserLogins,
      fecha1: fecha1,
      fecha2: fecha2
    };
    this.getservices.setData('consultaLoginsList',logindata)
    .subscribe( data => {
      this.listaLogins = data;
      this.listaLoginsCount = this.listaLogins.length;
      this.loader = false;
    },err => {
        this.snackBar.open('Error de conexión en el servidor [Login 2]', null, { duration: 3000 });
        this.loader = false;
    });
  }

  fechaHoy(){
    let now = new Date();
    let year = "" + now.getFullYear();
    let month = "" + (now.getMonth() + 1); if (month.length == 1) { month = "0" + month; }
    let day = "" + now.getDate(); if (day.length == 1) { day = "0" + day; }
    return year + "-" + month + "-" + day;
  }

  getStyle() {
    // snip snip -> fetch the url from somewhere
    let height = this.heightMap;
    let style = `height: ${height}px;`;
    
    // sanitize the style expression
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

}
