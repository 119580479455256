import { ElementRef, Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MapsAPILoader } from '@agm/core';
import { AgmMap, AgmMarker } from '@agm/core';

import { DomSanitizer  } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import swal from 'sweetalert';

import {
  ScriptService,
  GetserviesService
} from '../app.filesServices';

import { CONST } from '../app.const';

declare var google:any;

@Component({
  selector: 'app-paradas',
  templateUrl: './paradas.component.html'
})
export class ParadasComponent implements OnInit {
    @ViewChild(AgmMap) map: any;
    @ViewChild("search") searchElementRef: ElementRef;

  loader:boolean = false;
  
	public mapseting: any;
	public mapReady(mapseting) {
	  this.mapseting = mapseting;
	}

	token = JSON.parse(localStorage.getItem("token"));
  mapa = JSON.parse(localStorage.getItem("mapa"));
  zoom:number;
  heightMap:any = window.screen.height - 250;

	params:any = { buttons: false, timer: 2000 }; 
	swal:any = {
	  title: "¿Seguro que quiere eliminar el registro?",
	  text: "No se podra recuperar el registro",
	  icon: "warning",
	  buttons: true,
	  dangerMode: true,
	};
  icon:any = {icon: "success"};

  ///markers
  marker:any;
  markerSave:Array<any> = [];
  guardarMarker:boolean = false;
  parada:string;
  paradasFront:any;

  //front
  ruta:any;
  rutaocliente:any;

  constructor(
    private router:Router,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    public script:ScriptService,
    public snackBar:MatSnackBar,
    private _loader: MapsAPILoader,
    private _zone: NgZone,
    public getservices:GetserviesService) {
    this.script.loadScript();
  }

  ngOnInit() {
    // trae datos de rutas para select
    this.loader = true;
    let data = {
      pin: this.token.pin
    };
    this.getservices.getData('rutasoclientes',data)
    .subscribe( res => {
      //console.log(res);
      this.rutaocliente = res;
      this.ruta = this.rutaocliente[0].pin;
      this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
    });
    //// traer posicion por direccion
    this._loader.load().then(() => {
        let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {});
        google.maps.event.addListener(autocomplete, 'place_changed', () => {
            this.loader = true;
            this._zone.run(() => {
              let place = autocomplete.getPlace();

              this.mapseting.setCenter({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
              this.zoom = 16;
              //console.log(place);
              this.loader = false;
            });
        });
    });
    ////
    this.getDataRuta();
  }

  getDataRuta(){
    this.loader = true;
    let data = {
      pin: this.token.pin
    };
    this.getservices.getData('mapsDataMarkers',data)
    .subscribe( res => {
        //console.log(res);
        this.paradasFront = res;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
    });
    this.loader = false;
  }

  deleteMarker(id:number){
    swal(this.swal)
    .then((willDelete) => {
      if (willDelete) {
        this.loader = true;
        let data = {
          id: id
        };
        this.getservices.setData('maps_markerDelite',data)
        .subscribe( res => {
          if(res['res']==true){
            swal("!Marker eliminado¡", this.params);
            this.loader = false;
            this.router.navigate(['reload','paradas']);
          }else{
            this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
            this.loader = false;
          }
        },err => {
          this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
          this.loader = false;
        });
      }
    });
  }

  createMarker(mark){
    if(this.guardarMarker == false){
      this.map._mapsWrapper.createMarker({
        position: mark.coords,
        animation: google.maps.Animation.DROP,
        icon: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'

      }).then((marker: any) => {
          this.marker = marker;
      });
      this.markerSave = mark.coords;
      this.guardarMarker = true;
    }else{
      this.snackBar.open('Aun no guarda este marcador pendiente', null, { duration: 3000 });
    }
  }

  clearMarker(){
    this.guardarMarker = false;
    this.marker.setMap(null);
  } 

  saveMarker(){
    if(this.ruta && this.parada){
      this.loader = true;
      let data = {
        rutaocliente: this.ruta,
        parada: this.parada,
        lat: this.markerSave['lat'],
        lng: this.markerSave['lng']
      };
      this.getservices.setData('maps_markerSave',data)
      .subscribe( data => {
        if(data['res']==true){
          this.guardarMarker = false;
          swal("!Parada guardada¡", this.params);
          this.router.navigate(['reload','paradas']);
        }else{
          this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        }
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
      });
    }else{
      this.snackBar.open('Falta algun campo por llenar', null, { duration: 3000 });
    }
  }

  setCurrentPosition() {
    if ("geolocation" in navigator) {
      this.loader = true;
      this.zoom = 10;
      navigator.geolocation.getCurrentPosition((position) => {
        this.mapseting.setCenter({ lat: position.coords.latitude, lng: position.coords.longitude });
        this.zoom = 16;
        this.loader = false;
      });
    }
  }

  getStyle() {
    // snip snip -> fetch the url from somewhere
    let height = this.heightMap;
    let style = `height: ${height}px;`;
    
    // sanitize the style expression
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }
}
