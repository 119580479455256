import { ElementRef, Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer  } from '@angular/platform-browser';
import { MapsAPILoader } from '@agm/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AgmMap, AgmCircle } from '@agm/core';
import { HttpClient } from '@angular/common/http';


import swal from 'sweetalert';

import {
  ScriptService,
  GetserviesService
} from '../app.filesServices';

import { environment } from '../../environments/environment';

declare var $:any;
declare var moment:any;
declare var google:any;

@Component({
  selector: 'app-ignicion',
  templateUrl: './ignicion.component.html',
})
export class IgnicionComponent implements OnInit {

	@ViewChild(AgmMap) map: any;
  	@ViewChild(AgmMap) mapGeo: any;

  	public mapseting: any;
  	public mapReady(mapseting) {
  	  this.mapseting = mapseting;
  	}

  loader:boolean = false;
  token = JSON.parse(localStorage.getItem("token"));
  mapa = JSON.parse(localStorage.getItem("mapa"));
  heightMap:any = window.screen.height - 250;

  lat:number = this.mapa['lat'];
  lng:number = this.mapa['lng'];
  zoom:number = this.mapa['zoom'];

  rutaocliente:any;
  ruta:any;

  unidadesList:any;
  unidad:any;

  uEAtimeFullInicio:any = '00:00';
  uEAtimeFullFin:any = '23:59';

  uEAdateInicio:any = this.fechaAyer();
  uEAtimeInicio:any = '00:00';
  uEAdateFin:any = this.fechaHoy();
  uEAtimeFin:any = '23:59';

  listaDatos:any;

  no:number = 0;

  direccion:any = '';
  market:boolean = false;


  constructor(
  	public http:HttpClient,
    public snackBar:MatSnackBar,
  	private router:Router,
    private sanitizer: DomSanitizer,
    private _loader: MapsAPILoader,
    private _zone: NgZone,
    private activatedRoute: ActivatedRoute,
    public script:ScriptService,
    public getservices:GetserviesService
  ) { 
    this.script.loadScript();
  }

  ngOnInit() {
  	this.traerGeocercas();
  	// trae datos de rutas para select
    this.loader = true;
    let data = {
      pin: this.token.pin
    };
    this.getservices.getData('rutasoclientes',data)
    .subscribe( res => {
      this.rutaocliente = res;
      this.ruta = this.rutaocliente[0].label;
      this.unidades(this.ruta);
      if(this.rutaocliente.length == 1){
  	    $('.rutas').hide();
  	  }
      this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
    });
  }

  onChange(rutaID:any) {
    this.unidades(rutaID);
  }

  unidades(ruta:any){
    this.loader = true;
    let data = {
      ruta: ruta,
      pin: this.token.pin
    };
    this.getservices.getData('unidadesNombreRutaFiltro',data)
    .subscribe( res => {
      this.unidadesList = res;
      this.unidad = this.unidadesList[0].imei;
      this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
    });
  }

  apagadoencendido(tipo:string){
    this.loader = true;
    this.listaDatos = [];
    //// traer datos sin conexion o bateria
    let data2;
    let hoydoble;
    if(tipo == 'presente'){
      data2 = {
        ruta: this.ruta,
        equipo: this.unidad,
        tipo: tipo,
        fechaIni: this.fechaHoy()+' '+$('.uEAtimeFullInicio').val()+':00',
        fechaFin: this.fechaHoy()+' '+$('.uEAtimeFullFin').val()+':00'
      };
    }else{
      /// if para ver si la fecha fin es igual a hoy
      if($('.uEAdateFin').val() == this.fechaHoy()){
        hoydoble = {
          ruta: this.ruta,
          equipo: this.unidad,
          tipo: 'presente',
          fechaIni: this.fechaHoy()+' 00:00:00',
          fechaFin: this.fechaHoy()+' '+$('.uEAtimeFin').val()+':00'
        };
      }
      ///
      data2 = {
        ruta: this.ruta,
        equipo: this.unidad,
        tipo: tipo,
        fechaIni: $('.uEAdateInicio').val()+' '+$('.uEAtimeInicio').val()+':00',
        fechaFin: $('.uEAdateFin').val()+' '+$('.uEAtimeFin').val()+':00'
      };
    }
    this.getservices.getData('apagadoEncendidoData',data2)
    .subscribe( (res:any[]) => {
      if(res[0] == undefined){
        this.snackBar.open('Tal ves esta pidiendo un rango de fecha mal... ', null, { duration: 6000 });
      }

      /// datos de hoy consulta doble
      if(tipo == 'historico' && $('.uEAdateFin').val() == this.fechaHoy()){
        this.getservices.getData('apagadoEncendidoData',hoydoble)
        .subscribe( (hoydatadoble:any[]) => {

          this.loader = false;

          ///
          var countR = res.length;
          if(countR > 0){
          	for (var i = 0; i < res.length; ++i) {
	        	this.listaDatos.push(res[i]);
	        }
          }
          var countD = hoydatadoble.length;
          if(countD > 0){
        	for (var i = 0; i < hoydatadoble.length; ++i) {
        	    this.listaDatos.push(hoydatadoble[i]);
            }
	      }

	      this.no = this.listaDatos.length;


        },err => {
          this.loader = false;
          this.snackBar.open('Error de conexión en el servidor consulta presente doble.', null, { duration: 3000 });
        });
      }else{

        this.loader = false;
        this.listaDatos = res;
        this.no = this.listaDatos.length;

      }

      },err => {
        this.loader = false;
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
    });
  }

  centrar(info){
    this.zoom = 10;
    this.market = true;
    this.mapseting.setCenter({ lat: Number(info['lat']), lng: Number(info['lng']) });
    this.lat = info['lat'];
    this.lng = info['lng'];
    this.zoom = 19;
    // traigo adress
    this.http.get('https://maps.googleapis.com/maps/api/geocode/json?latlng='+info['lat']+','+info['lng']+'&key='+environment.ubicacion)
    .subscribe((data) => {
      if(data['status'] == "OK"){
        this.direccion = data['results'][2].formatted_address;
      }else{
        this.direccion = 'No hay dirección';
      }
    });
  }

  traerGeocercas(){
    let data = {
      pin: this.token.pin
    };
    this.getservices.getData('geocercasMapa',data)
    .subscribe( res => {
      this.checarGeo(res);
    },err => {
      this.snackBar.open('Error de conexión en el servidor. geocercas', null, { duration: 3000 });  
    });
  }

  checarGeo(geo:any){
    for (var i = 0; i < geo.length; ++i) {
      if(geo[i]['tipo'] == 'circular'){
        this.hacerCircleGeo(geo[i]);
      }else if(geo[i]['tipo'] == 'poligonal'){
        this.hacerPolygonGeo(geo[i]);
      }else if(geo[i]['tipo'] == 'lineal'){
        this.hacerPolylineGeo(geo[i]);
      }
    }
  }

  hacerCircleGeo(cir:any){
    let color = cir['color'];
    cir = JSON.parse(cir.coords);
    this.mapGeo._mapsWrapper.createCircle({
      center: { lat: cir['lat'], lng: cir['lng'] },
      radius: Number(cir['radio']),
      strokeColor: color,
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillColor: color,
      fillOpacity: 0.3,
      visible: true
    });
  }

  hacerPolygonGeo(poly:any){
    this.mapGeo._mapsWrapper.createPolygon({
      paths: eval(poly['coords']),
      strokeColor: poly['color'],
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillColor: poly['color'],
      fillOpacity: 0.3,
      visible: true
    });
  }

  hacerPolylineGeo(line:any){
    this.mapGeo._mapsWrapper.createPolyline({
      strokeColor: line['color'],
      strokeOpacity: 0.6,
      strokeWeight: 5,
      path: eval(line['coords']),
      visible: true
    });
  }

  fechaHoy(){
    let now = new Date();
    let year = "" + now.getFullYear();
    let month = "" + (now.getMonth() + 1); if (month.length == 1) { month = "0" + month; }
    let day = "" + now.getDate(); if (day.length == 1) { day = "0" + day; }
    return year + "-" + month + "-" + day;
  }

  fechaAyer(){
    let hoy = this.fechaHoy();
    let ayer = this.editar_fecha(hoy, "-1", "d");
    return ayer;
  }

  editar_fecha(fecha, intervalo, dma) {
    var separador = separador || "-";
    var arrayFecha = fecha.split(separador);
    var dia = arrayFecha[2];
    var mes = arrayFecha[1];
    var anio = arrayFecha[0];
    
    var fechaInicial = new Date(anio, mes - 1, dia);
    var fechaFinal = fechaInicial;
    if(dma=="m" || dma=="M"){
      fechaFinal.setMonth(fechaInicial.getMonth()+parseInt(intervalo));
    }else if(dma=="y" || dma=="Y"){
      fechaFinal.setFullYear(fechaInicial.getFullYear()+parseInt(intervalo));
    }else if(dma=="d" || dma=="D"){
      fechaFinal.setDate(fechaInicial.getDate()+parseInt(intervalo));
    }else{
      return fecha;
    }
    dia = fechaFinal.getDate();
    mes = fechaFinal.getMonth() + 1;
    anio = fechaFinal.getFullYear();
   
    dia = (dia.toString().length == 1) ? "0" + dia.toString() : dia;
    mes = (mes.toString().length == 1) ? "0" + mes.toString() : mes;
   
    return anio + "-" + mes + "-" + dia;
  }



  getStyle() {
    // snip snip -> fetch the url from somewhere
    let height = this.heightMap;
    let style = `height: ${height}px;`;
    
    // sanitize the style expression
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

}