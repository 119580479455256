import { Component, OnInit } from '@angular/core';
import { DomSanitizer  } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import swal from 'sweetalert';

import {
  ScriptService,
  GetserviesService
} from '../app.filesServices';

declare var $:any;
declare var moment:any;

@Component({
  selector: 'app-conexion',
  templateUrl: './conexion.component.html'
})
export class ConexionComponent implements OnInit {

  loader:boolean = false;
  token = JSON.parse(localStorage.getItem("token"));
  heightMap:any = window.screen.height - 250;


  rutaocliente:any;
  ruta:any;

  unidadesList:any;
  unidad:any;

  uCtimeFullInicio:any = '00:00';
  uCtimeFullFin:any = '23:59';

  uCdateInicio:any = this.fechaAyer();
  uCtimeInicio:any = '00:00';
  uCdateFin:any = this.fechaHoy();
  uCtimeFin:any = '23:59';

  listaDatos:any;

  no:any;


  constructor(
  	private router:Router,
    public script:ScriptService,
    public getservices:GetserviesService,
    public snackBar:MatSnackBar,
    private sanitizer: DomSanitizer
  ) { 
    this.script.loadScript();
  }

  ngOnInit() {
  	// trae datos de rutas para select
    this.loader = true;
    let data = {
      pin: this.token.pin
    };
    this.getservices.getData('rutasoclientes',data)
    .subscribe( res => {
      this.rutaocliente = res;
      this.ruta = this.rutaocliente[0].label;
      this.unidades(this.ruta);
      if(this.rutaocliente.length == 1){
  	    $('.rutas').hide();
  	  }
      this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
    });
  }

  onChange(rutaID:any) {
    this.unidades(rutaID);
  }

  unidades(ruta:any){
    this.loader = true;
    let data = {
      ruta: ruta,
      pin: this.token.pin
    };
    this.getservices.getData('unidadesNombreRutaFiltro',data)
    .subscribe( res => {
      this.unidadesList = res;
      this.unidad = this.unidadesList[0].imei;
      this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
    });
  }

  sinconexion(tipo:string){
    this.loader = true;
    this.listaDatos = [];
    //// traer datos sin conexion o bateria
    let data2;
    let hoydoble;
    if(tipo == 'presente'){
      data2 = {
        ruta: this.ruta,
        equipo: this.unidad,
        tipo: tipo,
        fechaIni: this.fechaHoy()+' '+$('.uCtimeFullInicio').val()+':00',
        fechaFin: this.fechaHoy()+' '+$('.uCtimeFullFin').val()+':00'
      };
    }else{
      /// if para ver si la fecha fin es igual a hoy
      if($('.uCdateFin').val() == this.fechaHoy()){
        hoydoble = {
          ruta: this.ruta,
          equipo: this.unidad,
          tipo: 'presente',
          fechaIni: this.fechaHoy()+' 00:00:00',
          fechaFin: this.fechaHoy()+' '+$('.uCtimeFin').val()+':00'
        };
      }
      ///
      data2 = {
        ruta: this.ruta,
        equipo: this.unidad,
        tipo: tipo,
        fechaIni: $('.uCdateInicio').val()+' '+$('.uCtimeInicio').val()+':00',
        fechaFin: $('.uCdateFin').val()+' '+$('.uCtimeFin').val()+':00'
      };
    }
    this.getservices.getData('conexion',data2)
    .subscribe( (res:any[]) => {
      if(res[0] == undefined){
        this.snackBar.open('Tal ves esta pidiendo un rango de fecha mal... ', null, { duration: 6000 });
      }

      /// datos de hoy consulta doble
      if(tipo == 'historico' && $('.uCdateFin').val() == this.fechaHoy()){
        this.getservices.getData('conexion',hoydoble)
        .subscribe( (hoydatadoble:any[]) => {

          this.loader = false;

          ///
          var countR = res.length;
          if(countR > 0){
          	for (var i = 0; i < res.length; ++i) {
	        	this.listaDatos.push(res[i]);
	        }
          }
          var countD = hoydatadoble.length;
          if(countD > 0){
        	for (var i = 0; i < hoydatadoble.length; ++i) {
        	    this.listaDatos.push(hoydatadoble[i]);
            }
	      }

	      this.no = this.listaDatos.length;


        },err => {
          this.loader = false;
          this.snackBar.open('Error de conexión en el servidor consulta presente doble.', null, { duration: 3000 });
        });
      }else{

        this.loader = false;
        this.listaDatos = res;
        this.no = this.listaDatos.length;

      }

      },err => {
        this.loader = false;
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
    });
  }

  fechaHoy(){
    let now = new Date();
    let year = "" + now.getFullYear();
    let month = "" + (now.getMonth() + 1); if (month.length == 1) { month = "0" + month; }
    let day = "" + now.getDate(); if (day.length == 1) { day = "0" + day; }
    return year + "-" + month + "-" + day;
  }

  fechaAyer(){
    let hoy = this.fechaHoy();
    let ayer = this.editar_fecha(hoy, "-1", "d");
    return ayer;
  }

  editar_fecha(fecha, intervalo, dma) {
    var separador = separador || "-";
    var arrayFecha = fecha.split(separador);
    var dia = arrayFecha[2];
    var mes = arrayFecha[1];
    var anio = arrayFecha[0];
    
    var fechaInicial = new Date(anio, mes - 1, dia);
    var fechaFinal = fechaInicial;
    if(dma=="m" || dma=="M"){
      fechaFinal.setMonth(fechaInicial.getMonth()+parseInt(intervalo));
    }else if(dma=="y" || dma=="Y"){
      fechaFinal.setFullYear(fechaInicial.getFullYear()+parseInt(intervalo));
    }else if(dma=="d" || dma=="D"){
      fechaFinal.setDate(fechaInicial.getDate()+parseInt(intervalo));
    }else{
      return fecha;
    }
    dia = fechaFinal.getDate();
    mes = fechaFinal.getMonth() + 1;
    anio = fechaFinal.getFullYear();
   
    dia = (dia.toString().length == 1) ? "0" + dia.toString() : dia;
    mes = (mes.toString().length == 1) ? "0" + mes.toString() : mes;
   
    return anio + "-" + mes + "-" + dia;
  }



  getStyle() {
    // snip snip -> fetch the url from somewhere
    let height = this.heightMap;
    let style = `height: ${height}px;`;
    
    // sanitize the style expression
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

}