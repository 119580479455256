import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import swal from 'sweetalert';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { CONST } from '../app.const';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  params:any = { buttons: false, timer: 2000 };
  paramsNotificaciones:any = {button: "Enterado!"};

  constructor(
		  private router:Router,
    	public snackBar:MatSnackBar,
    	public http:HttpClient
             ) {}

  loginServe(usuario:string, password:string){
  	let headers = new HttpHeaders({ 'Content-Type' : 'application/json' });

    let data = JSON.stringify({
        client_id: CONST.clientId,
        client_secret: CONST.clientSecret,
        grant_type: CONST.grantType,
        username: usuario,
        password: password
    });

    this.http.post(CONST.token, data, { headers })
      .pipe( map( data => {
        return data['access_token'];
      }) )
      .subscribe( token => {
      	this.getDatos(token,usuario);
      },err => {
        this.snackBar.open('Credenciales incorrectas', null, { duration: 3000 });
      });
      ///
    let dataWRED = JSON.stringify({
        client_id: CONST.clientId,
        client_secret: CONST.clientSecret,
        grant_type: 'client_credentials'
    });  
    this.http.post('https://admin.wred.mx/oauth/token', dataWRED, { headers })
      .pipe( map( data => {
        return data['access_token'];
      }) )
      .subscribe( token => {
        localStorage.setItem('token_sibive', token);
      },err => {
        this.snackBar.open('Credenciales incorrectas', null, { duration: 3000 });
      });
      ///
  }

  getDatos(token:string,usuario:string){
  	let headers = new HttpHeaders({ 'Authorization' : 'Bearer ' + token });

  	let dataPost = {
      username: usuario
    };

    this.http.post(CONST.API+'dataUser', dataPost, { headers })
      .pipe( map( data => {
        return data[0];
      }) )
      .subscribe( data => {
      	if(data['status'] == 'Activo'){
          this.getMapa(token,data['pin']);
          this.getUserAgent(token,data['pin']);
      		/// creo el local storage
      		let store = { 
	            'pin': data['pin'], 
              'nivel': data['tipo_usuarios'],
	            'nombre': data['name'],
              'correo': data['email'],
              'camaras': data['mdvr'],
              'noR': data['noR'],
              'modouser': data['modo_usuario'],
	            'token': token
	        };
          localStorage.setItem('token', JSON.stringify(store));
      		/// 
          if(data['not'] == false){
            swal("¡Inicio de sesión correcto!", this.params);
          }else{
            swal(data['title'], data['notificacion'], this.paramsNotificaciones);  
          }
          ///
      	}
      	if(data['status'] == 'Suspendido'){
      		this.snackBar.open('Usuario Suspendido', null, { duration: 5000 });
      	}
      },err => {
        this.snackBar.open('Error de conexión en el servidor (0.1)', null, { duration: 3000 });
      });
  }

  getMapa(token,pin){
    let headers = new HttpHeaders({ 'Authorization' : 'Bearer ' + token });
    //// creo storage para mapa
    let data = {
      pin: pin
    };
    this.http.post(CONST.API+'dataMap', data, { headers })
    .subscribe( res => {
      let map;
      if(res['res'] != false){
        map = {
          'lat': Number(res[0]['lat']),
          'lng': Number(res[0]['logn']),
          'zoom': Number(res[0]['zoom'])
        };
      }else{
        map = { 'lat': Number(22.73207149314701), 'lng': Number(-101.92049204144308), 'zoom': Number(6) };
      }
      localStorage.setItem('mapa', JSON.stringify(map));
      /// llamar datos de localStorage token para hacer if
      let token = JSON.parse(localStorage.getItem("token"));
      if(token['modouser'] == 'monitoreo'){
        this.router.navigate(['reload','reportealertas']);
      }else{
        this.router.navigate(['reload','mapa']);
      }
    },err => {
      this.snackBar.open('Error de conexión en el servidor (0.2)', null, { duration: 3000 });
    });
  }

  getUserAgent(token,pin){
    let headers = new HttpHeaders({ 'Authorization' : 'Bearer ' + token });
    //// creo storage para mapa
    let data = {
      pin: pin
    };
    this.http.post(CONST.API+'loginlog', data, { headers })
    .subscribe( res => {
      if(res['res'] == true){
        this.snackBar.open('Sesión desde: '+res['data'], null, { duration: 5000 });  
      }
    },err => {
      this.snackBar.open('Error de conexión en el servidor (0.3)', null, { duration: 3000 });
    });
  }

  tokencheck(token:string){
    let headers = new HttpHeaders({ 'Authorization' : 'Bearer ' + token });
    return this.http.get(CONST.API+'tokenCheck', { headers });
  }

}
