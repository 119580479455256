import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class RoleGuardService implements CanActivate{

  constructor(public router: Router,public snackBar:MatSnackBar) {}

  canActivate(route: ActivatedRouteSnapshot) {

    const token = JSON.parse(localStorage.getItem("token"));
    let expectedRole = route.data.expectedRole; // resive los datos o dato de routes que compara

    let validRol:boolean = false;

    for (var i = expectedRole.length - 1; i >= 0; i--) {

    	if (token['nivel'] == expectedRole[i]) {
    		return true;
    	}else{
    		validRol = false;
    	}

    }

    if (validRol == false) {
      localStorage.removeItem("token");	
      this.router.navigate(['login']);
      this.snackBar.open('Nivel de usuario no permitido...', null, { duration: 5000 });
      return false;
    }
    return true;
  }


}