import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import {
  ScriptService,
  GetserviesService
} from '../app.filesServices';

@Component({
  selector: 'app-reportransmicion',
  templateUrl: './reportransmicion.component.html',
  styleUrls: ['./reportransmicion.component.css']
})
export class ReportransmicionComponent implements OnInit {

	loader:boolean = false;

	token = JSON.parse(localStorage.getItem("token"));
	params:any = { buttons: false, timer: 2000 };

	datosNunca:any;
  counNunca:any = 0;
	datosHoy:any;
  counHoy:any = 0;
	datosGps:any;
  counGps:any = 0;

  datosTiempo:any;
  counTiempo:any = 0;

  tiempo:any = '120';

  constructor(
  	public script:ScriptService,
  	public snackBar:MatSnackBar,
  	public getservices:GetserviesService
  	) {
  	this.script.loadScript();
  }

 
  ngOnInit() {
  	this.loader = true;
    this.tiempoMinutos();
    this.getservices.getData('generalTranmision',{report: 'nunca'})
    .subscribe( res => {
    	this.datosNunca = res;
      this.counNunca = this.datosNunca.length;
      	this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor. nunca', null, { duration: 3000 });
        this.loader = false;
      });
    this.getservices.getData('generalTranmision',{report: 'hoy'})
    .subscribe( res => {
    	this.datosHoy = res;
      this.counHoy = this.datosHoy.length;
      	this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
      });
    this.getservices.getData('generalTranmision',{report: 'gps'})
    .subscribe( res => {
    	this.datosGps = res;
      this.counGps = this.datosGps.length;
      	this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor. GPS', null, { duration: 3000 });
        this.loader = false;
      });
  }

  tiempoMinutos(){
    this.getservices.getData('generalTranmision',{report: 'tiempo', minutos: this.tiempo })
    .subscribe( res => {
      this.datosTiempo = res;
      this.counTiempo = this.datosTiempo.length;
        this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor. Tiempo', null, { duration: 3000 });
        this.loader = false;
      });
  }
}