import { Component, OnInit } from '@angular/core';
import { DomSanitizer  } from '@angular/platform-browser';

import {
  ScriptService,
  GetserviesService
} from '../app.filesServices';

@Component({
  selector: 'app-camaras',
  templateUrl: './camaras.component.html'
})
export class CamarasComponent implements OnInit {

  loader:boolean = false;
  token = JSON.parse(localStorage.getItem("token"));
  heightMap:any = window.screen.height - 250;

  url:any;

  constructor(
    public script:ScriptService,
    public getservices:GetserviesService,
    private sanitizer: DomSanitizer
  ) { 
    this.script.loadScript();
  }

  ngOnInit() {
  }


  getStyle() {
    // snip snip -> fetch the url from somewhere
    let height = this.heightMap;
    let style = `height: ${height}px;`;
    
    // sanitize the style expression
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

}
