import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer  } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import {
  ScriptService,
  GetserviesService
} from '../app.filesServices';

declare var $:any;

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit {

	loader:boolean = false;
	token = JSON.parse(localStorage.getItem("token"));
	///heightMap:any = window.screen.height - 250;
 
  constructor(
    public snackBar:MatSnackBar,
  	private router:Router,
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    public script:ScriptService,
    public getservices:GetserviesService
    ) {
  	this.script.loadScript();
  }

  ngOnInit() {
    //// request
    this.loader = true;
      let data = {
        //ruta: this.ruta,
        //pin: this.token.pin
      };
      this.getservices.getData('unidadesNombreRutaFiltro',data)
      .subscribe( res => {
        //this.unidadesList = res;
      },err => {
          this.snackBar.open('Error de conexión en el servidor. Hoy', null, { duration: 3000 });
      });
    this.loader = false;
  }

  /*
  getStyle() {
    // snip snip -> fetch the url from somewhere
    let height = this.heightMap;
    let style = `height: ${height}px;`;
    
    // sanitize the style expression
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }
  */

}