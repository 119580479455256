import { Pipe, PipeTransform } from '@angular/core';

declare var moment:any;

@Pipe({
  name: 'diferencia'
})
export class DiferenciaPipe implements PipeTransform {

  transform(value: any, args?: any): any {
  	var fecha1 = moment(args, "YYYY-MM-DD HH:mm:ss");
    var fecha2 = moment(value, "YYYY-MM-DD HH:mm:ss");
    return fecha2.diff(fecha1, 'm')+' Min.';
  }

}
