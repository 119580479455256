import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import {
  ScriptService,
  GetserviesService
} from '../app.filesServices';

@Component({
  selector: 'app-sindatoscontacots',
  templateUrl: './sindatoscontacots.component.html',
  styleUrls: ['./sindatoscontacots.component.css']
})
export class SindatoscontacotsComponent implements OnInit {

	loader:boolean = false;

	token = JSON.parse(localStorage.getItem("token"));
	params:any = { buttons: false, timer: 2000 };

	datosContactos:any;
  	counContactos:any = 0;

  constructor(
  	public script:ScriptService,
  	public snackBar:MatSnackBar,
  	public getservices:GetserviesService
  	) {
  	this.script.loadScript();
  }

 
  ngOnInit() {
  	this.loader = true;
    this.getservices.getData('generalReports',{report: 'datosContanto'})
    .subscribe( res => {
    	this.datosContactos = res;
      	this.counContactos = this.datosContactos.length;
      	this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
      });
  }

}