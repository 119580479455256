import { RouterModule, Routes } from '@angular/router';
/// pages
import {
	LoginComponent,
	MapaComponent,
	MapaCustomComponent,
	UnidadesComponent,
  RutaComponent,
  ParadasComponent,
  GeocercasComponent,
  AsociacionComponent,
  AlertsComponent,
  VelocidadesComponent,
  MinutoaminutoComponent,
  ParosdemotorComponent,
  TransmicionComponent,
  GeovirtualComponent,
  CamarasComponent,
  GeoreportComponent,
  PosicionesComponent,
  UsersComponent,
  DistanciatiempoComponent,
  ReportevelocidadesComponent,
  ZonasrojasComponent,
  ConexionComponent,
  PlanchadasbasesComponent,
  IgnicionComponent,
  ReloadComponent,
  ReloadidComponent,
  ReloadalertsComponent,
  ReloadvelocidadesComponent,
  ReloadminutoaminutoComponent,
  ReloadparosComponent,
  AlertaspanicoComponent,
  GeocercasalertasComponent,
  GeocercasmasterComponent,
  BotonespanicoComponent,
  ReportealertaComponent,
  CsinfoComponent,
  ReportransmicionComponent,
  TelefonosmalComponent,
  SindatoscontacotsComponent,
  RutasdatosComponent,
  CobrostransmisionComponent,
  CamarascountComponent,
  CamarascountuserreportComponent,
  TestComponent,
  CobroswredComponent,
  LagosComponent,
  ContadoresComponent,
  DetectaComponent,
  VideosolicitudComponent,
  VideosadminComponent,
  Sintransmision2diasyperiodoComponent,
  SolicitudvideowhatsComponent,
  ContadoresmdbractualComponent
} from './app.files';

/// CanActivate
import { AuthGuardService as AuthGuard } from './services/auth-guard.service';
import { RoleGuardService as RoleGuard  } from './services/role-guard.service';

const APP_ROUTES: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'login/:key/:user/:key2/:pass/:key3', component: LoginComponent },
  { path: 'mapa', component: MapaComponent, canActivate: [AuthGuard] },
  { path: 'detecta', component: DetectaComponent, canActivate: [AuthGuard] },
  { path: 'camarascontadorasactual', component: ContadoresmdbractualComponent, canActivate: [AuthGuard] },
  { path: 'maparutas/:rutas', component: MapaComponent, canActivate: [AuthGuard] },
  { path: 'unidades', component: UnidadesComponent, canActivate: [AuthGuard,RoleGuard], 
    data: { expectedRole: ['Administrador','Particular'] } },
  { path: 'geocercas', component: GeocercasComponent, canActivate: [AuthGuard,RoleGuard], 
    data: { expectedRole: ['Administrador','Particular'] } },
  { path: 'geocercasedit/:id', component: GeocercasComponent, canActivate: [AuthGuard,RoleGuard], 
    data: { expectedRole: ['Administrador','Particular'] } },
  { path: 'asociacion', component: AsociacionComponent, canActivate: [AuthGuard,RoleGuard], 
    data: { expectedRole: ['Administrador','Particular'] } },
  { path: 'parametrosmapa', component: MapaCustomComponent, canActivate: [AuthGuard] },
  { path: 'paradas', component: ParadasComponent, canActivate: [AuthGuard,RoleGuard], 
    data: { expectedRole: ['Administrador'] } },
  { path: 'ruta', component: RutaComponent, canActivate: [AuthGuard,RoleGuard], 
    data: { expectedRole: ['Administrador'] } },
  { path: 'rutaedit/:id', component: RutaComponent, canActivate: [AuthGuard,RoleGuard], 
    data: { expectedRole: ['Administrador'] } },

  { path: 'alertas/:tipo/:ruta/:timeFullInicio/:timeFullFin/:dateInicio/:timeInicio/:dateFin/:timeFin', component: AlertsComponent, canActivate: [AuthGuard] },
  { path: 'velocidades/:tipo/:ruta/:timeFullInicio/:timeFullFin/:dateInicio/:timeInicio/:dateFin/:timeFin/:velocidad', component: VelocidadesComponent, canActivate: [AuthGuard] },
  { path: 'minutoaminuto/:tipo/:ruta/:timeFullInicio/:timeFullFin/:dateInicio/:timeInicio/:dateFin/:timeFin/:unidad', component: MinutoaminutoComponent, canActivate: [AuthGuard] },
  { path: 'parosdemotor/:tipo/:ruta/:timeFullInicio/:timeFullFin/:dateInicio/:timeInicio/:dateFin/:timeFin', component: ParosdemotorComponent, canActivate: [AuthGuard] },
  { path: 'geovirtual', component: GeovirtualComponent, canActivate: [AuthGuard] },
  { path: 'distanciatiempo', component: DistanciatiempoComponent, canActivate: [AuthGuard] },
  { path: 'ubicaciondeexesosdevelocidad', component: ReportevelocidadesComponent, canActivate: [AuthGuard] },
  { path: 'zonasrojas', component: ZonasrojasComponent, canActivate: [AuthGuard] },
  { path: 'conexion', component: ConexionComponent, canActivate: [AuthGuard] },
  { path: 'planchadasbases', component: PlanchadasbasesComponent, canActivate: [AuthGuard] },
  { path: 'ignicion', component: IgnicionComponent, canActivate: [AuthGuard] },

  { path: 'reload/:url', component: ReloadComponent, canActivate: [AuthGuard] },
  { path: 'reloadid/:url/:id', component: ReloadidComponent, canActivate: [AuthGuard] },
  { path: 'reloadalerts/:tipo/:ruta/:timeFullInicio/:timeFullFin/:dateInicio/:timeInicio/:dateFin/:timeFin', component: ReloadalertsComponent, canActivate: [AuthGuard] },
  { path: 'reloadvelocidades/:tipo/:ruta/:timeFullInicio/:timeFullFin/:dateInicio/:timeInicio/:dateFin/:timeFin/:velocidad', component: ReloadvelocidadesComponent, canActivate: [AuthGuard] },
  { path: 'reloadminutoaminuto/:tipo/:ruta/:timeFullInicio/:timeFullFin/:dateInicio/:timeInicio/:dateFin/:timeFin/:unidad', component: ReloadminutoaminutoComponent, canActivate: [AuthGuard] },
  { path: 'reloadparos/:tipo/:ruta/:timeFullInicio/:timeFullFin/:dateInicio/:timeInicio/:dateFin/:timeFin', component: ReloadparosComponent, canActivate: [AuthGuard] },

  { path: 'transmision', component: TransmicionComponent, canActivate: [AuthGuard] },
  { path: 'ultimasposiciones', component: PosicionesComponent, canActivate: [AuthGuard] },
  { path: 'camaras/:imei', component: CamarasComponent, canActivate: [AuthGuard] },
  { path: 'geocercasdata', component: GeoreportComponent, canActivate: [AuthGuard] },
  { path: 'geocercasalertas', component: GeocercasalertasComponent, canActivate: [AuthGuard] },

  { path: 'alertaspanico/:imei', component: AlertaspanicoComponent, canActivate: [AuthGuard] },
  { path: 'reportealertas', component: ReportealertaComponent, canActivate: [AuthGuard] },

  { path: 'test', component: TestComponent, canActivate: [AuthGuard] },

  { path: 'usuarios', component: UsersComponent, canActivate: [AuthGuard,RoleGuard], 
    data: { expectedRole: ['Administrador','Particular'] } },

  /// master
  { path: 'geocercasmaster', component: GeocercasmasterComponent, canActivate: [AuthGuard,RoleGuard], 
    data: { expectedRole: ['Administrador'] } },
  { path: 'videosadmin', component: VideosadminComponent, canActivate: [AuthGuard,RoleGuard], 
    data: { expectedRole: ['Administrador'] } },
  { path: 'lagos', component: LagosComponent, canActivate: [AuthGuard,RoleGuard], 
    data: { expectedRole: ['Administrador'] } },
  { path: 'contadores', component: ContadoresComponent, canActivate: [AuthGuard,RoleGuard], 
    data: { expectedRole: ['Administrador'] } },
  { path: 'bonespanico', component: BotonespanicoComponent, canActivate: [AuthGuard,RoleGuard], 
    data: { expectedRole: ['Administrador','Analista'] } },
  { path: 'c5info', component: CsinfoComponent, canActivate: [AuthGuard,RoleGuard], 
    data: { expectedRole: ['Administrador'] } },
  { path: 'cobros', component: CobroswredComponent, canActivate: [AuthGuard,RoleGuard], 
    data: { expectedRole: ['Administrador'] } },
  { path: 'reportransmision', component: ReportransmicionComponent, canActivate: [AuthGuard,RoleGuard], 
    data: { expectedRole: ['Administrador'] } },
  { path: 'telefonosmal', component: TelefonosmalComponent, canActivate: [AuthGuard,RoleGuard], 
    data: { expectedRole: ['Administrador'] } },
  { path: 'sindatoscontactos', component: SindatoscontacotsComponent, canActivate: [AuthGuard,RoleGuard], 
    data: { expectedRole: ['Administrador'] } },
  //{ path: 'datosrutas', component: RutasdatosComponent, canActivate: [AuthGuard,RoleGuard], 
    //data: { expectedRole: ['Administrador'] } },
  { path: 'transmisionmes', component: CobrostransmisionComponent, canActivate: [AuthGuard,RoleGuard], 
    data: { expectedRole: ['Administrador'] } },
  { path: 'conteocamaras', component: CamarascountComponent, canActivate: [AuthGuard,RoleGuard], 
    data: { expectedRole: ['Administrador'] } },
  { path: 'limitecamaras/:imei', component: CamarascountuserreportComponent, canActivate: [AuthGuard,RoleGuard], 
    data: { expectedRole: ['Concesionario','Particular'] } },
  { path: 'videosolicitud', component: VideosolicitudComponent, canActivate: [AuthGuard,RoleGuard], 
    data: { expectedRole: ['Concesionario','Particular','Analista','Administrador'] } },
  { path: 'videoswhatsapp', component: SolicitudvideowhatsComponent, canActivate: [AuthGuard,RoleGuard], 
    data: { expectedRole: ['Administrador','Analista'] } },
  { path: '2diashistorialtransmision', component: Sintransmision2diasyperiodoComponent, canActivate: [AuthGuard,RoleGuard], 
    data: { expectedRole: ['Administrador'] } },
  ///
  
  { path: '**', pathMatch: 'full', redirectTo: 'login' }
];

export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES, {useHash:true});
//export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES);