import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import swal from 'sweetalert';

import {
  ScriptService,
  GetserviesService
} from '../app.filesServices';

declare var $:any;

@Component({
  selector: 'app-asociacion',
  templateUrl: './asociacion.component.html'
})
export class AsociacionComponent implements OnInit {

	loader:boolean = false;
	token = JSON.parse(localStorage.getItem("token"));
	params:any = { buttons: false, timer: 2000 };

	geocercaslist:any;
	rutasoclientesArray:any;


	///asociar
	asociar:boolean = false;
	asociarIdGeo:number;
	asociartitulogeo:string;
	asociarTipoAccion:string;

  constructor(
  	public script:ScriptService,
  	public snackBar:MatSnackBar,
  	public getservices:GetserviesService,
  	private router:Router,
    private activatedRoute: ActivatedRoute,
  	) {
  	this.script.loadScript();
  }

  ngOnInit() {
  	// trae geocercas
    this.loader = true;
    let data = {
      pin: this.token.pin
    };
    this.getservices.getData('geocercasAsociacion',data)
    .subscribe( res => {
      this.geocercaslist = res;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
    });
    this.loader = false;
  }

  asociarSaveFull(idRuta:any,nameClass:any,){
    $("."+nameClass+" > option").prop("selected","selected");
    $("."+nameClass).trigger("change");
    this.asociarSave(idRuta,nameClass,false);
  }

  asociarSave(idRuta:any,nameClass:any,unidadesLimpia:any){
  	let relacionUnidades;
  	if(unidadesLimpia == true){
  		relacionUnidades = '';
  	}else{
  		relacionUnidades = $("."+nameClass).val();
  	}
  	if(!relacionUnidades.length && unidadesLimpia == false){
	  	this.snackBar.open('Los campos estan vacios... Geocerca sin relación.', null, { duration: 3000 });
	  }else{
	  this.loader = true;
		let datasave = {
			geofence: this.asociarIdGeo,
			routesorclients_id: idRuta,
			unidades: relacionUnidades
		};
		this.getservices.setData('geocercasAsociacionEquiposSave',datasave)
		.subscribe( data => {
		  if(data['res']==true){
		    swal("¡Datos  guardados!", this.params );
		    this.router.navigate(['reload','asociacion']);
		  }else{
		    this.snackBar.open('Error de conexión en el servidor 1', null, { duration: 3000 });
		  }
		},err => {
		    this.snackBar.open('Error de conexión en el servidor 2', null, { duration: 3000 });
		});
	    this.loader = false;
    }
  }

  rutasAsociadasUnidades(idGeo:any){
  	this.loader = true;
    let data = {
      pin: this.token.pin,
      idGeo: idGeo
    };
    this.getservices.getData('equiposAsociacion',data)
    .subscribe( res => {
        this.rutasoclientesArray = res;
        this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
      });
  }

  asociarFun(item:any,editAsociacion:any){
  	this.asociar = true;
  	this.asociarIdGeo = item['id'];
  	this.asociartitulogeo = item['nombre'];
  	if(editAsociacion=='asociar'){
  		this.rutasAsociadasUnidades(item['id']);
  		this.asociarTipoAccion = 'Asociar geocerca';
  	}else{
  		this.asociarTipoAccion = 'Editar geocerca';
  		this.rutasAsociadasUnidades(item['id']);
  	}
  }

  regresar(){
  	this.router.navigate(['reload','asociacion']);
  }

}
