import { ElementRef, Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MapsAPILoader } from '@agm/core';
import { AgmMap, AgmPolygon, AgmCircle, AgmPolyline } from '@agm/core';

import { DomSanitizer  } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import swal from 'sweetalert';

import {
  ScriptService,
  GetserviesService,
  GetPathCoordsService
} from '../app.filesServices';

import { CONST } from '../app.const';

// Declaramos las variables para jQuery
declare var $:any;
declare var Codebase:any;
declare var google:any;

@Component({
  selector: 'app-geocercas',
  templateUrl: './geocercas.component.html',
})
export class GeocercasComponent implements OnInit {
    @ViewChild(AgmMap) map: any;
    @ViewChild("search") searchElementRef: ElementRef;

  	loader:boolean = false;
  
  	public mapseting: any;
  	public mapReady(mapseting) {
  	  this.mapseting = mapseting;
  	}

  	token = JSON.parse(localStorage.getItem("token"));
    mapa = JSON.parse(localStorage.getItem("mapa"));
    heightMap:any = window.screen.height - 250;
    zoom:number;

  	params:any = { buttons: false, timer: 2000 }; 
  	swal:any = {
  	  title: "¿Seguro que quiere eliminar el registro?",
  	  text: "No se podra recuperar el registro",
  	  icon: "warning",
  	  buttons: true,
  	  dangerMode: true,
  	};
  	icon:any = {icon: "success"};	  

    //geocercas list
    geocercaslist:any;

    //edit
    editgeo:any;
    editgeotipo:any;

    /// front actions
    guardar: boolean = true;
    asociacion = false;
    desacer = true;

    /// tipo de geocerca
    tipoGeocerca:any = '';

    //// form datos
    nombreGeo:any;
    visibleGeo:any = 'no';
    colorGeo:any = '#42a5f5';
    hora1:any = '00:00';
    hora2:any = '23:59';
    colorGeoEdit:any;
    eventoGeo:any = 'entrar';
    alertaGeo:any = 'ninguno';
    nivelGeo:any = '0';
    alertaCorreo:any = 'no';
    alertaSms:any = 'no';

    /// geocercas 
    polygon: any;
    circle: any;
    polyline: any;
    //// getPathData
    getPathData:any;
    arreglo:Array<any> = [];

  constructor(
    private router:Router,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    public script:ScriptService,
    public snackBar:MatSnackBar,
    private _loader: MapsAPILoader,
    private _zone: NgZone,
    public getservices:GetserviesService,
    public getpathcoords:GetPathCoordsService) {
    this.script.loadScript();
    this.activatedRoute.params.subscribe( params =>{
      this.editgeo = params['id'];
    });
    /// hago que se active la funcion para colorpiker
    $(function () {
        Codebase.helpers(['colorpicker']);
    });
  }

  ngOnInit() {
    // trae geocercas
    this.loader = true;
    let data = {
      pin: this.token.pin
    };
    this.getservices.getData('geocercas',data)
    .subscribe( res => {
      this.geocercaslist = res;
      if(this.geocercaslist.length){
        this.asociacion = true;
        this.checkGeo();
      }
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
    });
    this.loader = false;
    //// traer posicion por direccion
    this._loader.load().then(() => {
        let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {});
        google.maps.event.addListener(autocomplete, 'place_changed', () => {
            this.loader = true;
            this._zone.run(() => {
              let place = autocomplete.getPlace();

              this.mapseting.setCenter({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
              this.mapa['zoom'] = 16;
              //console.log(place);
              this.loader = false;
            });
        });
    });
    ////
  }

  /// selec tipo geocerca
  selectTipo(tipo){
    this.tipoGeocerca = tipo;
  }
  /// limpiar tipo geocerca
  crearSelect(){
    this.tipoGeocerca = '';
    this.nombreGeo = '';
  }


  ///creates
  createPoligon(){
    let cenZoo = this.centerZoom();
    let init_point = 10000/(cenZoo['zoom']*cenZoo['zoom']*cenZoo['zoom']*cenZoo['zoom']*cenZoo['zoom']*cenZoo['zoom']);
    let path_poly = [
    { lat: cenZoo['center'].lat()-init_point, lng: cenZoo['center'].lng()-init_point },
    { lat: cenZoo['center'].lat()-init_point, lng: cenZoo['center'].lng()+init_point },
    { lat: cenZoo['center'].lat()+init_point, lng: cenZoo['center'].lng()+init_point }
    ];
    this.map._mapsWrapper.createPolygon({
      paths: path_poly,
      strokeColor: this.colorGeo,
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillColor: this.colorGeo,
      fillOpacity: 0.3,
      editable: true,
      draggable: true,
      visible: true
    }).then((polygon: any) => {
      this.polygon = polygon;
    });
  }

  createCircle(){
    let cenZoo = this.centerZoom();
    var init_radius = 60000000/(cenZoo['zoom']*cenZoo['zoom']*cenZoo['zoom']*cenZoo['zoom']*cenZoo['zoom']);
    this.map._mapsWrapper.createCircle({
      center: { lat: cenZoo['center'].lat(), lng: cenZoo['center'].lng() },
      radius: init_radius,
      strokeColor: this.colorGeo,
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillColor: this.colorGeo,
      fillOpacity: 0.3,
      editable: true,
      draggable: true,
      visible: true
    }).then((circle: any) => {
      this.circle = circle;
    });
  }

  createLine(){
    let cenZoo = this.centerZoom();
    let init_point = 10000/(cenZoo['zoom']*cenZoo['zoom']*cenZoo['zoom']*cenZoo['zoom']*cenZoo['zoom']*cenZoo['zoom']);
    let path = [
        {lat: cenZoo['center'].lat()-init_point, lng: cenZoo['center'].lng()-init_point},
        {lat: cenZoo['center'].lat()+init_point, lng: cenZoo['center'].lng()+init_point}
    ];
    this.map._mapsWrapper.createPolyline({
      clickable: true,
      draggable: true,
      editable: true,
      strokeColor: this.colorGeo,
      strokeOpacity: 0.6,
      strokeWeight: 5,
      visible: true,
      path: path
    }).then((polyline: any) => {
      this.polyline = polyline;
    });
  }
  /////
  /// datos center y zoom
  centerZoom(){
    let center = this.mapseting.getCenter();            
    let mzoom = this.mapseting.getZoom();
    if (mzoom==0)
    mzoom = 1;
    let data = {'center':center,'zoom':mzoom};
    return data;
  }
  /////

  //offs botons front and on save boton
  onoffbotons(evento:boolean){
    this.guardar = evento;
    this.desacer = evento;
  }

  ///crear
  crearGeocerca(){
    this.colorGeo = $("#example-colorpicker5").val();
    if(this.tipoGeocerca && this.nombreGeo && this.colorGeo && this.visibleGeo){
      $("#modal-geocercas").modal("hide");
      this.onoffbotons(false);
      if(this.tipoGeocerca == 'poligonal'){
        this.createPoligon();
      }else if(this.tipoGeocerca == 'circular'){
        this.createCircle();
      }else {
        this.createLine();
      }
    }else{
      this.snackBar.open('Faltan campos por llenar o seleccionar...', null, { duration: 3000 });
    }
  }

  ///saves
  saveGeocerca(){
    if(this.polygon){
      let count = this.polygon.getPath().length;
      this.getPathData = this.polygon.getPath().getArray().toString();
      let tra = this.getpathcoords.getPathFromCoordsArray(this.getPathData);
      for (var i = count - 1; i >= 0; i--) {
        this.arreglo.push({ lat: tra[i].lat(), lng: tra[i].lng() });//creates a point now want to add draw path 
      }
      //console.log(JSON.stringify(this.arreglo));
      this.savePost(this.arreglo);
      this.arreglo = [];
      this.polygon.setMap(null);
      this.polygon = '';
    }
    if(this.circle){
      this.getPathData = { 'lat':this.circle.getCenter().lat(), 'lng':this.circle.getCenter().lng(), 'radio':this.circle.getRadius().toString() };
      //console.log(this.getPathData);
      this.savePost(this.getPathData);
      this.circle.setMap(null);
      this.circle = '';
    }
    if (this.polyline) { 
      let count = this.polyline.getPath().length;
      this.getPathData = this.polyline.getPath().getArray().toString();
      let tra = this.getpathcoords.getPathFromCoordsArray(this.getPathData);
      for (var i = count - 1; i >= 0; i--) {
        this.arreglo.push({ lat: tra[i].lat(), lng: tra[i].lng() });//creates a point now want to add draw path 
      }
      //console.log(JSON.stringify(this.arreglo));
      this.savePost(this.arreglo);
      this.arreglo = [];
      this.polyline.setMap(null);
      this.polyline = '';
    }
    this.getPathData='';
    this.onoffbotons(true);
    this.crearSelect();
  }

  savePost(coords:any){
    let cenZoo = this.centerZoom();
    this.loader = true;
    let data = {
      pin: this.token.pin,
      tipo: this.tipoGeocerca,
      nombre: this.nombreGeo,
      color: this.colorGeo,
      hora1: $(".hora1").val(),
      hora2: $(".hora2").val(),
      visible: this.visibleGeo,
      evento: this.eventoGeo,
      alerta: this.alertaGeo,
      nivel: this.nivelGeo,
      correo: this.alertaCorreo,
      sms: this.alertaSms,
      coords: JSON.stringify(coords),
      zoom: cenZoo['zoom'],
      lat: cenZoo['center'].lat(),
      lng: cenZoo['center'].lng()
    };
    this.getservices.setData('geocercaSave',data)
      .subscribe( data => {
        if(data['res']==true){
          swal("¡Geocerca guardada!", this.params );
          this.router.navigate(['reload','geocercas']);
        }else{
          this.snackBar.open('Error al guardar los datos...', null, { duration: 3000 });
        }
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
      });
    this.loader = false;
  }


  checkGeo(){
    if(!this.editgeo){
      let datosGeo = {
        clickable: false,
        draggable: false,
        editable: false
      };
      for (var i = 0; i < this.geocercaslist.length; ++i) {
        if(this.geocercaslist[i].tipo == 'circular'){
          this.createCircleGeo(this.geocercaslist[i],datosGeo);
        }else if(this.geocercaslist[i].tipo == 'poligonal'){
          this.createPolygonGeo(this.geocercaslist[i],datosGeo);
        }else if(this.geocercaslist[i].tipo == 'lineal'){
          this.createPolylineGeo(this.geocercaslist[i],datosGeo);
        }
      }
    }else{
      let datosGeo = {
        clickable: true,
        draggable: true,
        editable: true
      };
      let nombre = '';
      for (var i = 0; i < this.geocercaslist.length; ++i) {
        if(this.geocercaslist[i].id == this.editgeo){
          nombre = this.geocercaslist[i].nombre;
          /// asigno datos al mapa
          this.mapa['zoom'] = this.geocercaslist[i].zoom;
          this.mapa['lat'] = this.geocercaslist[i].lat;
          this.mapa['lng'] = this.geocercaslist[i].lng;
          /// asignar datos
          this.nombreGeo = this.geocercaslist[i].nombre;
          this.colorGeoEdit = this.geocercaslist[i].color;
          this.hora1 = this.geocercaslist[i].hora1.substring(0,5);
          this.hora2 = this.geocercaslist[i].hora2.substring(0,5);
          this.visibleGeo = this.geocercaslist[i].visible;
          this.eventoGeo = this.geocercaslist[i].evento;
          this.alertaGeo = this.geocercaslist[i].alerta;
          this.nivelGeo = this.geocercaslist[i].nivel;
          this.alertaCorreo = this.geocercaslist[i].correo;
          this.alertaSms = this.geocercaslist[i].sms;
          /// mando los datos segun la coincidencia
          if(this.geocercaslist[i].tipo == 'circular'){
            this.editgeotipo = 'circular';
            this.createCircleGeo(this.geocercaslist[i],datosGeo);
          }else if(this.geocercaslist[i].tipo == 'poligonal'){
            this.editgeotipo = 'poligonal';
            this.createPolygonGeo(this.geocercaslist[i],datosGeo);
          }else if(this.geocercaslist[i].tipo == 'lineal'){
            this.editgeotipo = 'lineal';
            this.createPolylineGeo(this.geocercaslist[i],datosGeo);
          }
        }
      }
      if(nombre){
        this.snackBar.open('Editar... '+nombre, null, { duration: 3000 });
      }else{
        if(this.token.modouser == 'sistemas' || this.token.modouser == 'monitoreo'){
          this.traerGeoMaster();
        }else{
          this.snackBar.open('No hay geocerca con ese identificador...', null, { duration: 3000 });
        }
      }
    }
  }

  traerGeoMaster(){
    this.loader = true;
    let data = {
      geo: this.editgeo
    };
    this.getservices.getData('geoGet',data)
    .subscribe( res => {
      if(res['res'] == true){
        this.loader = false;
        let datosGeo = {
          clickable: true,
          draggable: true,
          editable: true
        };
        /// asigno datos al mapa
        this.mapa['zoom'] = res['data'][0].zoom;
        this.mapa['lat'] = res['data'][0].lat;
        this.mapa['lng'] = res['data'][0].lng;
        /// asignar datos
        this.nombreGeo = res['data'][0].nombre;
        this.colorGeoEdit = res['data'][0].color;
        this.hora1 = res['data'][0].hora1.substring(0,5);
        this.hora2 = res['data'][0].hora2.substring(0,5);
        this.visibleGeo = res['data'][0].visible;
        this.eventoGeo = res['data'][0].evento;
        this.alertaGeo = res['data'][0].alerta;
        this.nivelGeo = res['data'][0].nivel;
        this.alertaCorreo = res['data'][0].correo;
        this.alertaSms = res['data'][0].sms;
        /// mando los datos segun la coincidencia
        if(res['data'][0].tipo == 'circular'){
          this.editgeotipo = 'circular';
          this.createCircleGeo(res['data'][0],datosGeo);
        }else if(res['data'][0].tipo == 'poligonal'){
          this.editgeotipo = 'poligonal';
          this.createPolygonGeo(res['data'][0],datosGeo);
        }else if(res['data'][0].tipo == 'lineal'){
          this.editgeotipo = 'lineal';
          this.createPolylineGeo(res['data'][0],datosGeo);
        }
      }else{
        this.snackBar.open('Error en geocerca...', null, { duration: 3000 });
      }
      },err => {
        this.loader = false;
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
      });
  }

  createCircleGeo(cir:any,datosGeo:any){
    let color = cir['color'];
    cir = JSON.parse(cir.coords);
    this.map._mapsWrapper.createCircle({
      center: { lat: cir['lat'], lng: cir['lng'] },
      radius: Number(cir['radio']),
      strokeColor: color,
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillColor: color,
      fillOpacity: 0.3,
      visible: true,
      editable: datosGeo['editable'],
      draggable: datosGeo['draggable'],
    }).then((circle: any) => {
      if(this.editgeo){
        this.circle = circle;
      }
    });
  }

  createPolygonGeo(poly:any,datosGeo:any){
    this.map._mapsWrapper.createPolygon({
      paths: eval(poly['coords']),
      strokeColor: poly['color'],
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillColor: poly['color'],
      fillOpacity: 0.3,
      visible: true,
      editable: datosGeo['editable'],
      draggable: datosGeo['draggable'],
    }).then((polygon: any) => {
      if(this.editgeo){
        this.polygon = polygon;
      }
    });
  }

  createPolylineGeo(line:any,datosGeo:any){
    this.map._mapsWrapper.createPolyline({
      strokeColor: line['color'],
      strokeOpacity: 0.6,
      strokeWeight: 5,
      visible: true,
      editable: datosGeo['editable'],
      draggable: datosGeo['draggable'],
      clickable: datosGeo['clickable'],
      path: eval(line['coords'])
    }).then((polyline: any) => {
      if(this.editgeo){
        this.polyline = polyline;
      }
    });
  }

  /// clear
  clearGeocercas(){
    if(this.polygon){
      this.polygon.setMap(null);
    }
    if(this.circle){
      this.circle.setMap(null);
    }
    if (this.polyline) { 
      this.polyline.setMap(null);
    }
    this.onoffbotons(true);
    this.crearSelect();
    this.router.navigate(['reload','geocercas']);
  }

  eliminargeo(id:any){
    swal(this.swal)
    .then((willDelete) => {
      if (willDelete) {
        this.loader = true;
        let data = {
          id: id
        };
        this.getservices.setData('geocercasDelete',data)
        .subscribe( res => {
          if(res['res']==true){
            swal("!Geocerca eliminada¡", this.params);
            this.loader = false;
            this.router.navigate(['reload','geocercas']);
          }else{
            this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
            this.loader = false;
          }
        },err => {
          this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
          this.loader = false;
        });
      }
    });
  }

  editargeo(id:any){
    this.router.navigate(['reloadid','geocercasedit',id]);
  }

  getPathEdit(){
    if(this.editgeotipo == 'circular'){
      this.getPathData = { 'lat':this.circle.getCenter().lat(), 'lng':this.circle.getCenter().lng(), 'radio':this.circle.getRadius().toString() };
      ///console.log(this.getPathData);
      this.updatePost(this.getPathData);
      this.circle.setMap(null);
      this.circle = '';
    }else if(this.editgeotipo == 'poligonal'){
      let count = this.polygon.getPath().length;
      this.getPathData = this.polygon.getPath().getArray().toString();
      let tra = this.getpathcoords.getPathFromCoordsArray(this.getPathData);
      for (var i = count - 1; i >= 0; i--) {
        this.arreglo.push({ lat: tra[i].lat(), lng: tra[i].lng() });//creates a point now want to add draw path 
      }
      ///console.log(JSON.stringify(this.arreglo));
      this.updatePost(this.arreglo);
      this.arreglo = [];
      this.polygon.setMap(null);
      this.polygon = '';
    }else if(this.editgeotipo == 'lineal'){
      let count = this.polyline.getPath().length;
      this.getPathData = this.polyline.getPath().getArray().toString();
      let tra = this.getpathcoords.getPathFromCoordsArray(this.getPathData);
      for (var i = count - 1; i >= 0; i--) {
        this.arreglo.push({ lat: tra[i].lat(), lng: tra[i].lng() });//creates a point now want to add draw path 
      }
      ///console.log(JSON.stringify(this.arreglo));
      this.updatePost(this.arreglo);
      this.arreglo = [];
      this.polyline.setMap(null);
      this.polyline = '';
    }
    this.getPathData = '';
  }

  updatePost(coords:any){
    let cenZoo = this.centerZoom();
    this.colorGeoEdit = $("#colorGeoEdit").val();
    this.loader = true;
    let data = {
      id: this.editgeo,
      tipo: this.editgeotipo,
      nombre: this.nombreGeo,
      color: this.colorGeoEdit,
      hora1: $(".hora1").val(),
      hora2: $(".hora2").val(),
      visible: this.visibleGeo,
      evento: this.eventoGeo,
      alerta: this.alertaGeo,
      nivel: this.nivelGeo,
      correo: this.alertaCorreo,
      sms: this.alertaSms,
      coords: JSON.stringify(coords),
      zoom: cenZoo['zoom'],
      lat: cenZoo['center'].lat(),
      lng: cenZoo['center'].lng()
    };
    this.getservices.setData('geocercaUpdate',data)
      .subscribe( data => {
        if(data['res']==true){
          swal("¡Geocerca editada!", this.params );
          this.router.navigate(['reload','geocercas']);
        }else{
          this.snackBar.open('Error al guardar los datos...', null, { duration: 3000 });
        }
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
      });
    this.loader = false;
  }

  //position actual
  setCurrentPosition() {
    if ("geolocation" in navigator) {
      this.loader = true;
      this.mapa['zoom'] = 10;
      navigator.geolocation.getCurrentPosition((position) => {
        this.mapseting.setCenter({ lat: position.coords.latitude, lng: position.coords.longitude });
        this.mapa['zoom'] = 16;
        this.loader = false;
      });
    }
  }

  getStyle() {
    // snip snip -> fetch the url from somewhere
    let height = this.heightMap;
    let style = `height: ${height}px;`;
    
    // sanitize the style expression
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }
}
