import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'distancia'
})
export class DistanciaPipe implements PipeTransform {

  transform(value: any, args?: any): any {
  	var lat = value;
    var lng = args[0];
    var latend = args[1];
    var lngend = args[2];
    return this.getDistanceFromLatLonInKm(lat,lng,latend,lngend);
  }

  getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
  var R = 6371; // Radius of the earth in km
  var dLat = this.deg2rad(lat2-lat1);  // deg2rad below
  var dLon = this.deg2rad(lon2-lon1); 
  var a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * 
    Math.sin(dLon/2) * Math.sin(dLon/2)
    ; 
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  var d = R * c; // Distance in km
  var total = d * 1000;
  return this.redondearDecimales(total,2)+' M.';
  }

  deg2rad(deg) {
    return deg * (Math.PI/180);
  }

  redondearDecimales(numero, decimales) {
    var numeroRegexp = new RegExp('\\d\\.(\\d){' + decimales + ',}');   // Expresion regular para numeros con un cierto numero de decimales o mas
    if (numeroRegexp.test(numero)) {         // Ya que el numero tiene el numero de decimales requeridos o mas, se realiza el redondeo
        return Number(numero.toFixed(decimales));
    } else {
        return Number(numero.toFixed(decimales)) === 0 ? 0 : numero;  // En valores muy bajos, se comprueba si el numero es 0 (con el redondeo deseado), si no lo es se devuelve el numero otra vez.
    }
  }

}
