import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import {
  ScriptService,
  GetserviesService
} from '../app.filesServices';

@Component({
  selector: 'app-csinfo',
  templateUrl: './csinfo.component.html',
  styleUrls: ['./csinfo.component.css']
})
export class CsinfoComponent implements OnInit {

	loader:boolean = false;

	token = JSON.parse(localStorage.getItem("token"));
	params:any = { buttons: false, timer: 2000 };

	datosGeneral:any;
	datosGps:any;
	datosMdvr:any;

  datosTotal:any;

  constructor(
  	public script:ScriptService,
  	public snackBar:MatSnackBar,
  	public getservices:GetserviesService
  	) {
  	this.script.loadScript();
  }

 
  ngOnInit() {
  	this.loader = true;
    this.getservices.getData('generalInfoC5',{report: 'general'})
    .subscribe( res => {
    	this.datosGeneral = res;
      	this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
      });
    this.getservices.getData('generalInfoC5',{report: 'gps'})
    .subscribe( res => {
    	this.datosGps = res;
      	this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
      });
    this.getservices.getData('generalInfoC5',{report: 'mdvr'})
    .subscribe( res => {
    	this.datosMdvr = res;
      	this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
      });
    ///
    this.getservices.getData('generalInfoC5',{report: 'count'})
    .subscribe( res => {
      this.datosTotal = res[0];
        this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
      });
  }

}