import { ElementRef, Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer  } from '@angular/platform-browser';
import { MapsAPILoader } from '@agm/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AgmMap, AgmCircle } from '@agm/core';
import { HttpClient } from '@angular/common/http';

import {
  ScriptService,
  GetserviesService
} from '../app.filesServices';

import { environment } from '../../environments/environment';

declare var google:any;
declare var $:any;

@Component({
  selector: 'app-geovirtual',
  templateUrl: './geovirtual.component.html'
})
export class GeovirtualComponent implements OnInit {

	  @ViewChild("search") searchElementRef: ElementRef;
	  @ViewChild(AgmMap) map: any;
  	@ViewChild(AgmMap) mapGeo: any;

  	public mapseting: any;
  	public mapReady(mapseting) {
  	  this.mapseting = mapseting;
  	}

	  loader:boolean = false;
	  token = JSON.parse(localStorage.getItem("token"));
  	mapa = JSON.parse(localStorage.getItem("mapa"));
  	heightMap:any = window.screen.height - 250;

  	direccion:any = '';

  	lat:number = this.mapa['lat'];
  	lng:number = this.mapa['lng'];
  	zoom:number = this.mapa['zoom'];


  	consulta:boolean = true;
  	geocircle:boolean = false;

  	circle: any;

    url:any;


    rutaocliente:any;
    ruta:any;
    unidadesList:any;
    unidadCon:any = 'All';
    selectedDevice:any = 'Filtro';

    market:boolean = false;

    fecha:any;
    horaInicio:any;
    horaFin:any;

    dateInicioGeoVir:any;
    timeInicioGeoVir:any;
    dateFinGeoVir:any;
    timeFinGeoVir:any;

    nom:number;

    hoy:any;
    periodo:any;
    nomhoyperiodo:any;

  constructor(
    public http:HttpClient,
    public snackBar:MatSnackBar,
  	private router:Router,
    private sanitizer: DomSanitizer,
    private _loader: MapsAPILoader,
    private _zone: NgZone,
    private activatedRoute: ActivatedRoute,
    public script:ScriptService,
    public getservices:GetserviesService
    ) {
    this.fecha = this.fechaHoy();
    this.horaInicio = '00:00';
    this.horaFin = '23:59';
    ///
    this.dateInicioGeoVir = this.fechaAyer();
    this.timeInicioGeoVir = '00:00';
    this.dateFinGeoVir = this.fechaHoy();
    this.timeFinGeoVir = '23:59';
    ///
  	this.script.loadScript();
  }

  rutas(){
    // trae datos de rutas para select
      this.loader = true;
      let data = {
        pin: this.token.pin
      };
      this.getservices.getData('rutasoclientes',data)
      .subscribe( res => {
        this.rutaocliente = res;
        this.ruta = this.rutaocliente[0].pin;
        if(this.rutaocliente.length == 1){
          $('.rutas').hide();
        }
        this.loader = false;
        this.unidades();
        },err => {
          this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
          this.loader = false;
      });
  }

  onChangeruta(id:any) {
      this.ruta = id;
      this.unidades();
  }

  hidden(dato){
    $('.dinamic').hide();
    $('.'+dato).show();
  }

  onChange(newValue:any) {
    if(newValue != 'Filtro'){
      this.hidden(newValue);
    }else{
      $('.dinamic').show();
    }
  }

  unidades(){
    //// unidades segun  ruta
    this.loader = true;
    let data = {
      ruta: this.ruta,
      pin: this.token.pin
    };
    this.getservices.getData('unidadesNombreFiltro',data)
    .subscribe( res => {
      this.unidadesList = res;
    },err => {
        this.snackBar.open('Error de conexión en el servidor. Hoy', null, { duration: 3000 });
    });
    this.loader = false;
  }

  cancelargeo(){
  	this.router.navigate(['reload','geovirtual']);
  }

  virtualhoy(){
    if(this.circle != undefined){
      this.loader = true;
      this.horaInicio = $(".horaInicio").val();
      this.horaFin = $(".horaFin").val();
      let lat = this.circle.getCenter().lat();
      let lng = this.circle.getCenter().lng();
      let radio = this.circle.getRadius().toString();
      this.consulta = false;
      let data = { 
        pin: this.token.pin,
        ruta: this.ruta,
        unidad: this.unidadCon,
        horaInicio:this.horaInicio,
        horaFin:this.horaFin,
        lat:lat, 
        lng:lng, 
        radio:radio 
      };
      this.getservices.getData('geovirtualhoy',data)
      .subscribe( res => {
        this.hoy = res['precente'];
        this.periodo = res['periodo'];
        this.loader = false;
        if(this.hoy.length){
          this.nomhoyperiodo = false;
          this.nom = this.hoy.length;
        }else{
          this.nom = 0;
        }
      },err => {
          this.loader = false;
          this.snackBar.open('Error de conexión en el servidor. Hoy', null, { duration: 3000 });
      });
    }else{
      this.snackBar.open('No hay geocerca que consultar...', null, { duration: 3000 });
    }
  }

        
  virtualperiodo(){
    if(this.circle != undefined){
      this.loader = true;
      this.dateInicioGeoVir = $(".dateInicioGeoVir").val();
      this.timeInicioGeoVir = $(".timeInicioGeoVir").val();
      this.dateFinGeoVir = $(".dateFinGeoVir").val();
      this.timeFinGeoVir = $(".timeFinGeoVir").val();
      let lat = this.circle.getCenter().lat();
      let lng = this.circle.getCenter().lng();
      let radio = this.circle.getRadius().toString();
      this.consulta = false;
      let data = { 
        pin: this.token.pin,
        ruta: this.ruta,
        unidad: this.unidadCon,
        dateInicio:this.dateInicioGeoVir,
        timeInicio:this.timeInicioGeoVir,
        dateFin:this.dateFinGeoVir,
        timeFin:this.timeFinGeoVir,
        lat:lat, 
        lng:lng, 
        radio:radio 
      };
      this.getservices.getData('geovirtualPeriodos',data)
      .subscribe( res => {
        this.hoy = res['precente'];
        this.periodo = res['periodo'];
        this.loader = false;
        if(this.periodo.length){
          this.nomhoyperiodo = this.periodo.length + 1;
          this.nom = this.periodo.length;
          let hoyNumeroSuma;
          if(this.hoy.length > 0){
            hoyNumeroSuma = this.hoy.length;
          }else{
            hoyNumeroSuma = 0;
          }
          this.nom = this.nom + hoyNumeroSuma;
        }else{
          this.nom = 0;
        }
      },err => {
          this.loader = false;
          this.snackBar.open('Error de conexión en el servidor. Periodos', null, { duration: 3000 });
      });
    }else{
      this.snackBar.open('No hay geocerca que consultar...', null, { duration: 3000 });
    }
  }

  ngOnInit() {
    this.rutas();
  	this.traerGeocercas();
  	/////
  	this._loader.load().then(() => {
        let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {});
        google.maps.event.addListener(autocomplete, 'place_changed', () => {
            this.loader = true;
            this._zone.run(() => {
              let place = autocomplete.getPlace();

              this.mapseting.setCenter({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
        	    this.zoom = 16;
              //console.log(place);
              this.loader = false;
            });
        });
    });
  }

  setCurrentPosition() {
    if ("geolocation" in navigator) {
      this.loader = true;
      this.zoom = 10;
      navigator.geolocation.getCurrentPosition((position) => {
        this.mapseting.setCenter({ lat: position.coords.latitude, lng: position.coords.longitude });
        this.zoom = 16;
        this.loader = false;
      });
    }
  }

  createCircle(){
  	this.geocircle = true;
    let cenZoo = this.centerZoom();
    var init_radius = 60000000/(cenZoo['zoom']*cenZoo['zoom']*cenZoo['zoom']*cenZoo['zoom']*cenZoo['zoom']);
    this.map._mapsWrapper.createCircle({
      center: { lat: cenZoo['center'].lat(), lng: cenZoo['center'].lng() },
      radius: init_radius,
      strokeColor: '#6D98D6',
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillColor: '#6D98D6',
      fillOpacity: 0.3,
      editable: true,
      draggable: true,
      visible: true
    }).then((circle: any) => {
      this.circle = circle;
    });
  }

  centerZoom(){
    let center = this.mapseting.getCenter();            
    let mzoom = this.mapseting.getZoom();
    if (mzoom==0)
    mzoom = 1;
    let data = {'center':center,'zoom':mzoom};
    return data;
  }

  centrar(info){
    this.zoom = 10;
    this.market = true;
    this.mapseting.setCenter({ lat: Number(info['lat']), lng: Number(info['lng']) });
    this.lat = info['lat'];
    this.lng = info['lng'];
    this.zoom = 19;
    // traigo adress
    this.http.get('https://maps.googleapis.com/maps/api/geocode/json?latlng='+info['lat']+','+info['lng']+'&key='+environment.ubicacion)
    .subscribe((data) => {
      if(data['status'] == "OK"){
        this.direccion = data['results'][2].formatted_address;
      }else{
        this.direccion = 'No hay dirección';
      }
    });
  }



  traerGeocercas(){
    let data = {
      pin: this.token.pin
    };
    this.getservices.getData('geocercasMapa',data)
    .subscribe( res => {
      this.checarGeo(res);
    },err => {
      this.snackBar.open('Error de conexión en el servidor. geocercas', null, { duration: 3000 });  
    });
  }

  checarGeo(geo:any){
    for (var i = 0; i < geo.length; ++i) {
      if(geo[i]['tipo'] == 'circular'){
        this.hacerCircleGeo(geo[i]);
      }else if(geo[i]['tipo'] == 'poligonal'){
        this.hacerPolygonGeo(geo[i]);
      }else if(geo[i]['tipo'] == 'lineal'){
        this.hacerPolylineGeo(geo[i]);
      }
    }
  }

  hacerCircleGeo(cir:any){
    let color = cir['color'];
    cir = JSON.parse(cir.coords);
    this.mapGeo._mapsWrapper.createCircle({
      center: { lat: cir['lat'], lng: cir['lng'] },
      radius: Number(cir['radio']),
      strokeColor: color,
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillColor: color,
      fillOpacity: 0.3,
      visible: true
    });
  }

  hacerPolygonGeo(poly:any){
    this.mapGeo._mapsWrapper.createPolygon({
      paths: eval(poly['coords']),
      strokeColor: poly['color'],
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillColor: poly['color'],
      fillOpacity: 0.3,
      visible: true
    });
  }

  hacerPolylineGeo(line:any){
    this.mapGeo._mapsWrapper.createPolyline({
      strokeColor: line['color'],
      strokeOpacity: 0.6,
      strokeWeight: 5,
      path: eval(line['coords']),
      visible: true
    });
  }

  fechaHoy(){
    let now = new Date();
    let year = "" + now.getFullYear();
    let month = "" + (now.getMonth() + 1); if (month.length == 1) { month = "0" + month; }
    let day = "" + now.getDate(); if (day.length == 1) { day = "0" + day; }
    return year + "-" + month + "-" + day;
  }

  fechaAyer(){
    let hoy = this.fechaHoy();
    let ayer = this.editar_fecha(hoy, "-1", "d");
    return ayer;
  }

  editar_fecha(fecha, intervalo, dma) {
    var separador = separador || "-";
    var arrayFecha = fecha.split(separador);
    var dia = arrayFecha[2];
    var mes = arrayFecha[1];
    var anio = arrayFecha[0];
    
    var fechaInicial = new Date(anio, mes - 1, dia);
    var fechaFinal = fechaInicial;
    if(dma=="m" || dma=="M"){
      fechaFinal.setMonth(fechaInicial.getMonth()+parseInt(intervalo));
    }else if(dma=="y" || dma=="Y"){
      fechaFinal.setFullYear(fechaInicial.getFullYear()+parseInt(intervalo));
    }else if(dma=="d" || dma=="D"){
      fechaFinal.setDate(fechaInicial.getDate()+parseInt(intervalo));
    }else{
      return fecha;
    }
    dia = fechaFinal.getDate();
    mes = fechaFinal.getMonth() + 1;
    anio = fechaFinal.getFullYear();
   
    dia = (dia.toString().length == 1) ? "0" + dia.toString() : dia;
    mes = (mes.toString().length == 1) ? "0" + mes.toString() : mes;
   
    return anio + "-" + mes + "-" + dia;
  }

  getStyle() {
    // snip snip -> fetch the url from somewhere
    let height = this.heightMap;
    let style = `height: ${height}px;`;
    
    // sanitize the style expression
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

}
