import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule }   from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
/// install plugins
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { AgmCoreModule } from '@agm/core';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { AutocompleteModule } from 'ng2-input-autocomplete';
////firebase
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../environments/environment';
// Rutas
import { APP_ROUTING } from './app.routes';
/// componente principal
import { AppComponent } from './app.component';

///pipes
import { TruncatePipe } from './pipe/truncate.pipe';
import { DiferenciaPipe } from './pipe/diferencia.pipe';
/// reloads
import { ReloadComponent } from './reload/reload.component';
import { ReloadidComponent } from './reloadid/reloadid.component';
import { ReloadalertsComponent } from './reloadalerts/reloadalerts.component';
import { ReloadvelocidadesComponent } from './reloadvelocidades/reloadvelocidades.component';
import { ReloadminutoaminutoComponent } from './reloadminutoaminuto/reloadminutoaminuto.component';
import { ReloadparosComponent } from './reloadparos/reloadparos.component';
/// include pages
import { LoginComponent } from './login/login.component';
import { HeaderComponent } from './common/header/header.component';
import { MenuComponent } from './common/menu/menu.component';
import { FooterComponent } from './common/footer/footer.component';
import { MapaComponent } from './mapa/mapa.component';
import { MapaCustomComponent } from './mapa-custom/mapa-custom.component';
import { UnidadesComponent } from './unidades/unidades.component';
import { LoaderComponent } from './loader/loader.component';
import { RutaComponent } from './ruta/ruta.component';
import { ParadasComponent } from './paradas/paradas.component';
import { GeocercasComponent } from './geocercas/geocercas.component';
import { AsociacionComponent } from './asociacion/asociacion.component';
import { AlertsComponent } from './alerts/alerts.component';
import { VelocidadesComponent } from './velocidades/velocidades.component';
import { MinutoaminutoComponent } from './minutoaminuto/minutoaminuto.component';
import { ParosdemotorComponent } from './parosdemotor/parosdemotor.component';
import { TransmicionComponent } from './transmicion/transmicion.component';
import { GeovirtualComponent } from './geovirtual/geovirtual.component';
import { CamarasComponent } from './camaras/camaras.component';
import { GeoreportComponent } from './georeport/georeport.component';
import { PosicionesComponent } from './posiciones/posiciones.component';
import { UsersComponent } from './users/users.component';
import { DistanciatiempoComponent } from './distanciatiempo/distanciatiempo.component';
import { ReportevelocidadesComponent } from './reportevelocidades/reportevelocidades.component';
import { ZonasrojasComponent } from './zonasrojas/zonasrojas.component';
import { ConexionComponent } from './conexion/conexion.component';
import { PlanchadasbasesComponent } from './planchadasbases/planchadasbases.component';
import { IgnicionComponent } from './ignicion/ignicion.component';
import { AlertaspanicoComponent } from './alertaspanico/alertaspanico.component';
import { GeocercasalertasComponent } from './geocercasalertas/geocercasalertas.component';
import { GeocercasmasterComponent } from './geocercasmaster/geocercasmaster.component';
import { BotonespanicoComponent } from './botonespanico/botonespanico.component';
import { ReportealertaComponent } from './reportealerta/reportealerta.component';
import { CsinfoComponent } from './csinfo/csinfo.component';
import { ReportransmicionComponent } from './reportransmicion/reportransmicion.component';
import { TelefonosmalComponent } from './telefonosmal/telefonosmal.component';
import { SindatoscontacotsComponent } from './sindatoscontacots/sindatoscontacots.component';
import { RutasdatosComponent } from './rutasdatos/rutasdatos.component';
import { CobrostransmisionComponent } from './cobrostransmision/cobrostransmision.component';
import { CamarascountComponent } from './camarascount/camarascount.component';
import { CamarascountuserreportComponent } from './camarascountuserreport/camarascountuserreport.component';
import { TestComponent } from './test/test.component';
import { CobroswredComponent } from './cobroswred/cobroswred.component';
import { LagosComponent } from './lagos/lagos.component';
import { ContadoresComponent } from './contadores/contadores.component';
import { DetectaComponent } from './detecta/detecta.component';
import { DistanciaPipe } from './distancia.pipe';
import { CalculocobroPipe } from './pipe/calculocobro.pipe';
import { VideosolicitudComponent } from './videosolicitud/videosolicitud.component';
import { SegundosminutosPipe } from './pipe/segundosminutos.pipe';
import { VideosadminComponent } from './videosadmin/videosadmin.component';
import { Sintransmision2diasyperiodoComponent } from './sintransmision2diasyperiodo/sintransmision2diasyperiodo.component';
import { SolicitudvideowhatsComponent } from './solicitudvideowhats/solicitudvideowhats.component';
import { ContadoresmdbractualComponent } from './contadoresmdbractual/contadoresmdbractual.component';

const customNotifierOptions: NotifierOptions = {
  position: {
        horizontal: {
            position: 'middle',
            distance: 12
        },
        vertical: {
            position: 'bottom',
            distance: 12,
            gap: 10
        }
    },
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'slide',
      speed: 300,
      easing: 'ease-out',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    MenuComponent,
    FooterComponent,
    MapaComponent,
    MapaCustomComponent,
    UnidadesComponent,
    LoaderComponent,
    RutaComponent,
    ReloadComponent,
    ReloadidComponent,
    ParadasComponent,
    GeocercasComponent,
    AsociacionComponent,
    AlertsComponent,
    TruncatePipe,
    ReloadalertsComponent,
    ReloadvelocidadesComponent,
    VelocidadesComponent,
    MinutoaminutoComponent,
    ReloadminutoaminutoComponent,
    ReloadparosComponent,
    ParosdemotorComponent,
    TransmicionComponent,
    GeovirtualComponent,
    CamarasComponent,
    GeoreportComponent,
    PosicionesComponent,
    UsersComponent,
    DistanciatiempoComponent,
    ReportevelocidadesComponent,
    ZonasrojasComponent,
    ConexionComponent,
    PlanchadasbasesComponent,
    IgnicionComponent,
    AlertaspanicoComponent,
    DiferenciaPipe,
    GeocercasalertasComponent,
    GeocercasmasterComponent,
    BotonespanicoComponent,
    ReportealertaComponent,
    CsinfoComponent,
    ReportransmicionComponent,
    TelefonosmalComponent,
    SindatoscontacotsComponent,
    RutasdatosComponent,
    CobrostransmisionComponent,
    CamarascountComponent,
    CamarascountuserreportComponent,
    TestComponent,
    CobroswredComponent,
    LagosComponent,
    ContadoresComponent,
    DetectaComponent,
    DistanciaPipe,
    CalculocobroPipe,
    VideosolicitudComponent,
    SegundosminutosPipe,
    VideosadminComponent,
    Sintransmision2diasyperiodoComponent,
    SolicitudvideowhatsComponent,
    ContadoresmdbractualComponent
  ],
  imports: [
    ReactiveFormsModule,
  	BrowserModule,
    APP_ROUTING,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatTabsModule,
    AutocompleteModule.forRoot(),
    NotifierModule.withConfig(customNotifierOptions),
    AgmCoreModule.forRoot({
      apiKey: environment.ubicacion,
      libraries: ['places']
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
