import { Component, OnDestroy, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';

import { AgmMap, AgmMarker, AgmPolygon, AgmCircle, AgmPolyline } from '@agm/core';

import { environment } from '../../environments/environment';

import {
  ScriptService,
  GetserviesService
} from '../app.filesServices';

declare var google:any;
declare var swal:any;

@Component({
  selector: 'app-alertaspanico',
  templateUrl: './alertaspanico.component.html',
  styleUrls: ['./alertaspanico.component.css']
})
export class AlertaspanicoComponent implements OnDestroy  {

  swal:any = {
      title: "¿Seguro que quiere seguir con la acción?",
      text: "De click en ok para confirmar si no cancele.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
  };
  swalc5:any = { 
      title: "¿Seguro de que es una verdadera emergencia?",
      text: "En caso contrario cancelar...",
      icon: "warning",
      buttons: true,
      dangerMode: false,
  };
  swaldetallealert:any = {
      title: "En caso de ser 'botón de pánico' favor de calificar el tipo de evento...",
      text: "De clic en 'OK' para calificar o cancelar para dejar como 'falsa alarma' gracias.",
      icon: "warning",
      buttons: true,
      dangerMode: false,
  };
  icon:any = {icon: "success"};
  paramsNotificaciones:any = {button: "Enterado!"};

  @ViewChild(AgmMap) map: any;

  loader:boolean = false;
  token = JSON.parse(localStorage.getItem("token"));
  mapa = JSON.parse(localStorage.getItem("mapa"));
  heightMap:any = window.screen.height - 250;
  lat:number = this.mapa['lat'];
  lng:number = this.mapa['lng'];
  zoom:number = 17;

  urlview:any;

  miparodemotor:any;
  imei:any;
  title:any;

  fechahora:any;
  velocidad:any;
  ruta:any;
  unidad:any;
  direccion:any = '...';
  metros:any;
  transmision:any;
  nombre:any;
  tel1:any;
  tel2:any;
  mdvr:any;
  cargo:any;
  responsable:any;
  tel_responsable:any;
  responsableunidad:any;

  col3:boolean = false;
  col12:boolean = true;

  stopinterval:any;

  contadorUrl:number = 0;

  constructor(
    private db: AngularFirestore,
    public script:ScriptService,
    public getservices:GetserviesService,
    private sanitizer: DomSanitizer,
    private router:Router,
    private activatedRoute: ActivatedRoute,
    public snackBar:MatSnackBar,
    public http:HttpClient,
  ) { 
    this.loader = true;
    this.script.loadScript();
    ///
    this.activatedRoute.params.subscribe( params =>{
      this.imei = params['imei'];
    });
    ///
    this.db.collection('parosdemotor', ref => 
      ref.where('imei', '==', this.imei)
    ).valueChanges()
     .subscribe((data:any[]) => {
       this.miparodemotor = data[0]['tipo'];
       this.seguiralerta();
    });
    ///
    this.stopinterval = setInterval(() => this.seguiralerta(), 15000);
  }

  energeciac5(){
    swal(this.swalc5)
        .then((willDelete) => {
          if (willDelete) {
            this.loader = true;
            let datasave = {
              imei: this.imei
            };
            this.getservices.setData('emergenciac5Edit',datasave)
            .subscribe( data => {
              if(data['res']==true){
                this.loader = false;
                this.snackBar.open('C5 Emergencia Ok', null, { duration: 10000 });
              }else{
                this.snackBar.open('C5 Emergencia Error', null, { duration: 10000 });
                this.loader = false;
              }
            },err => {
                this.snackBar.open('Error de conexión en el servidor. Set C5', null, { duration: 10000 });
                this.loader = false;
            });
          }
        });
  }

  checkAccesos(idUnidad:any,plan:any){
    let dataSet = {
        user: this.token.pin,
        unidad: idUnidad,
        plan: plan
    };
    this.getservices.setData('concesionariosSessions',dataSet)
      .subscribe( data => {
        if(data['res']==false){
          this.snackBar.open('Ya llego a su limite...', null, { duration: 3000 });
          setTimeout(()=>{
            let url = "https://sibive.airview.com.mx/#/limitecamaras/"+this.imei;
            this.urlview = this.sanitizer.bypassSecurityTrustResourceUrl(url);
          }, 1000);
        }else{
          this.snackBar.open('Inicia tiempo...', null, { duration: 3000 });
          setTimeout(()=>{
            let url = "https://airview.com.mx/offlinemdvr/";
            this.urlview = this.sanitizer.bypassSecurityTrustResourceUrl(url);
          }, 66000);
        }
      },err => {
        this.snackBar.open('Error de conexión en el servidor... Sesiones de Concesionarios', null, { duration: 3000 });
      });
  }

  online(url:any,idUnidad:any,plan:any){
    this.http.get(url)
      .subscribe( datosOnline => {
        if(datosOnline['onlines'][0]['online'] == 1){
          if(this.token.nivel == 'Concesionario' && this.contadorUrl == 1){
            this.checkAccesos(idUnidad,plan);
          }
        }else{
          let url = "https://airview.com.mx/mdvrapagado/";
          this.urlview = this.sanitizer.bypassSecurityTrustResourceUrl(url);
          swal("¡Unidad Apagada!", "Esta unidad esta fuera de linea...", this.paramsNotificaciones)
          .then((value) => {
            this.router.navigate(['reload','mapa']);
          });
        }
      },err => {
        this.snackBar.open('Algo malo salio en la conección Online', null, { duration: 3000 });
      });
  }

  queUrl(gps:any,idUnidad:any,plan:any){
    if(gps == 'gaxiola' && this.contadorUrl == 1){
      let url = "https://monitorvial.com/808gps/open/player/video.html?lang=en&devIdno="+this.imei+"&account=admin&password=admin";
      this.urlview = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this.online('https://monitorvial.com/StandardApiAction_getDeviceOlStatus.action?devIdno='+this.imei,idUnidad,plan);
    }else if(gps == 'worldcam' && this.contadorUrl == 1){
      let url = "https://airviewcam.club/808gps/open/player/video.html?lang=en&devIdno="+this.imei+"&account=admin&password=2022admin131088";
      this.urlview = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this.online('https://airviewcam.club/StandardApiAction_getDeviceOlStatus.action?devIdno='+this.imei,idUnidad,plan);
    }else if(gps == 'airview_count' && this.contadorUrl == 1){
      let url = "https://airviewcam.club/808gps/open/player/video.html?lang=en&devIdno="+this.imei+"&account=admin&password=2022admin131088";
      this.urlview = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this.online('https://airviewcam.club/StandardApiAction_getDeviceOlStatus.action?devIdno='+this.imei,idUnidad,plan);
    }else if(gps == 'airview_dash' && this.contadorUrl == 1){
      let url = "https://74.208.181.150/808gps/open/player/video.html?lang=en&devIdno=002022120101&account=admin&password=admin";
      //let url = "https://airviewcam.club/808gps/open/player/video.html?lang=en&devIdno="+this.imei+"&account=admin&password=2022admin131088";
      this.urlview = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      //this.online('https://airviewcam.club/StandardApiAction_getDeviceOlStatus.action?devIdno='+this.imei,idUnidad,plan);
      this.online('https://74.208.181.150/StandardApiAction_getDeviceOlStatus.action?devIdno='+this.imei,idUnidad,plan);
      alert('ok');
    }else if(gps == 'airview_m' && this.contadorUrl == 1){
      let url = "https://airviewme.club/h5/index.html?deviceno="+this.imei;
      this.urlview = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this.online('https://manager.airview.com.mx/api/V1/onlinemeriaepcomp/'+this.imei,idUnidad,plan);
    }else if(gps == 'airview_e' && this.contadorUrl == 1){
      let url = "https://airviewme.club/h5/index.html?deviceno="+this.imei;
      this.urlview = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this.online('https://manager.airview.com.mx/api/V1/onlinemeriaepcomp/'+this.imei,idUnidad,plan);
    }else if(gps == 'accesa_m' && this.contadorUrl == 1){
      let url = "https://train-api.accesa.me/h5/video.html?deviceno="+this.imei;
      this.urlview = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this.online('https://manager.airview.com.mx/api/V1/onlinemerivaAccesa/'+this.imei,idUnidad,plan);
    }
  }

  adress(){
    // traigo adress // panico con env
          this.http.get('https://maps.googleapis.com/maps/api/geocode/json?latlng='+this.lat+','+this.lng+'&key='+environment.ubicacion)
          .subscribe((data) => {
            if(data['status'] == "OK"){
              this.direccion = data['results'][2].formatted_address;
            }else{
              this.direccion = 'No hay dirección';
            }
          });
  }

  seguiralerta(){
    if(this.token.nivel == 'Concesionario'){
      let data = {
        imei: this.imei,
        pin: this.token.pin
      };
      this.getservices.getData('unidadRelacionConcesionario',data)
      .subscribe( data => {
        if(data['res']==true){
          this.seguiralertaDatos();
        }else if(data['res']==false){
          this.loader = false;
          clearTimeout(this.stopinterval);
          this.snackBar.open('Esta unidad no le pertence...', null, { duration: 9000 });
          setTimeout(()=>{
            this.router.navigate(['mapa']);
          }, 1000);
        }else{
          this.loader = false;
          this.snackBar.open('Algo malo paso...', null, { duration: 3000 });
        }
      },err => {
        this.loader = false;
        this.snackBar.open('Error de conexión en el servidor... Ultimo datos de unidad', null, { duration: 3000 });
      });
    }else{
      this.seguiralertaDatos();
    }
  }

  seguiralertaDatos(){
    let data = {
        imei: this.imei
    };
    this.getservices.getData('unidadDatos',data)
      .subscribe( data => {
        if(data['res']==true){
          this.contadorUrl = this.contadorUrl + 1;
          this.title = 'Unidad '+data['data'][0]['unidad'];
          this.fechahora = data['data'][0]['fechahora'];
          this.velocidad = data['data'][0]['speed'];
          this.ruta = data['data'][0]['ruta'];
          this.unidad = data['data'][0]['unidad'];
          this.metros = data['data'][0]['metros'];
          this.transmision = data['data'][0]['tiempo'];
          this.lat = Number(data['data'][0]['lat']);
          this.lng = Number(data['data'][0]['lng']);
          this.nombre = data['data'][0]['nombre'];
          this.tel1 = data['data'][0]['tel1'];
          this.tel2 = data['data'][0]['tel2'];
          this.mdvr = data['data'][0]['mdvr'];

          this.cargo = data['data'][0]['cargo'];
          this.responsable = data['data'][0]['responsable'];
          this.tel_responsable = data['data'][0]['tel_responsable'];

          this.responsableunidad = data['data'][0]['responsableunidad'];
          ///
          if(this.mdvr == 1){
            if(this.token.modouser == 'sistemas' || this.token.modouser == 'monitoreo' || this.token.nivel == 'Administrador' && this.token.camaras == 'si' || this.token.nivel == 'Particular' && this.token.camaras == 'si' || this.token.nivel == 'Concesionario' && this.token.camaras == 'si' && data['data'][0]['plan'] != 0 ){
              this.col3 = true;
              this.col12 = false;
              this.queUrl(data['data'][0]['gps'], data['data'][0]['unidad_id'], data['data'][0]['plan']);
            }else{
              this.col3 = false;
              this.col12 = true;
            }
          }else{
            this.col3 = false;
            this.col12 = true;
          }
          this.loader = false;
          ///
        }else{
          this.loader = false;
          this.snackBar.open('Algo malo paso...', null, { duration: 3000 });
        }
      },err => {
        this.loader = false;
        this.snackBar.open('Error de conexión en el servidor... Ultimo datos de unidad', null, { duration: 3000 });
      });
  }

  parodemotor(tipo:any){
    if(this.token.nivel != 'Concesionario'){
      swal(this.swal)
        .then((willDelete) => {
          if (willDelete) {
            this.smsmotor(this.imei,tipo);
            this.db.collection('parosdemotor').doc(this.imei).set({
              imei: this.imei, 
              ruta: this.ruta, 
              tipo: tipo
            });
            this.saveParodeMotor(tipo);
          }
        });
    }
  }

  smsmotor(imei:number,tipo:any){
    let data = {
        imei: imei,
        cmd: tipo
    };
    this.getservices.setData('cmd',data)
      .subscribe( data => {
        if(data['res']==true){
          this.snackBar.open('Paro de motor... Send!!!', null, { duration: 3000 });
        }else{
          this.snackBar.open('Paro de motor... Error!!!', null, { duration: 3000 });
        }
      },err => {
        this.snackBar.open('Error de conexión en el servidor... SMS', null, { duration: 3000 });
      });
  }

  saveParodeMotor(tipo:any){
    if(tipo=='apagarmotor'){
    this.loader = true;
    let datasave = {
      pin: this.token.pin,
      ruta: this.ruta,
      imei: this.imei,
      unidad: this.unidad,
      fecha: this.fechahora.substr(0,10),
      hora: this.fechahora.substr(11,8),
      lat: this.lat,
      lng: this.lng,
      speed: this.velocidad
    };
    this.getservices.setData('saveParoDeMotor',datasave)
    .subscribe( data => {
      if(data['res']==true){
        this.loader = false;
      }else{
        this.snackBar.open('Save paro de motor Error', null, { duration: 10000 });
        this.loader = false;
      }
    },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 10000 });
        this.loader = false;
    });
    }
  }

  public mapReady(map) {
    let trafficLayer = new google.maps.TrafficLayer();
    trafficLayer.setMap(map);
    this.map = map;
  }

  streat(){
    let panorama = this.map.getStreetView();
    panorama.setPosition({ lat: Number(this.lat), lng: Number(this.lng) });
    panorama.setPov(({
            heading: 340,
            pitch: 0
    }));
    panorama.setVisible(true);
  }

  ngOnDestroy() {
    clearTimeout(this.stopinterval);
    if(this.token.modouser == 'sistemas' || this.token.modouser == 'monitoreo'){
    swal(this.swaldetallealert)
        .then((willDelete) => {
          if (willDelete) {
            this.router.navigate(['reload','reportealertas']);
          }else{
            window.location.reload();
          }
        });
    }else{
      window.location.reload();
    }
  }

  contadortimer(){
    setTimeout(()=>{
      this.router.navigate(['reload','mapa']);
    },20000);
  }

  mapaStyle(){
    var panelDerecha = document.getElementById("altura");
    
    // snip snip -> fetch the url from somewhere
    let height = this.heightMap - panelDerecha.clientHeight;
    let style = `height: ${height}px;`;
    
    // sanitize the style expression
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

  getStyle() {
    // snip snip -> fetch the url from somewhere
    let height = this.heightMap;
    let style = `height: ${height}px;`;
    
    // sanitize the style expression
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }
}
