import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import {
  ScriptService,
  GetserviesService
} from '../app.filesServices';

@Component({
  selector: 'app-camarascountuserreport',
  templateUrl: './camarascountuserreport.component.html',
  styleUrls: ['./camarascountuserreport.component.css']
})
export class CamarascountuserreportComponent implements OnInit {

	loader:boolean = false;
	token = JSON.parse(localStorage.getItem("token"));
	imei:any;

	detalleslistUsers:any;
	detalleslistSessions:any = false;

  constructor(
  	private activatedRoute: ActivatedRoute,
  	public script:ScriptService,
    public getservices:GetserviesService,
    public snackBar:MatSnackBar,
  	) { 
  	this.loader = true;
    this.script.loadScript();
  	this.activatedRoute.params.subscribe( params =>{
      this.imei = params['imei'];
    });
  }

  ngOnInit() {
  	this.detalleslistUsers = [];
  	this.detalleslistSessions = [];
  	this.loader = true;
    let data = {
      imei: this.imei
    };
    this.getservices.getData('detallesSesionesConsesionario',data)
    .subscribe( res => {
      this.detalleslistUsers = res['users'];
      this.detalleslistSessions = res['sesiones'];
      this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
    });
  }

}
