import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map } from 'rxjs/operators';

import { CONST } from '../app.const';

@Injectable({
  providedIn: 'root'
})
export class GetserviesService {

  constructor(
  		public snackBar:MatSnackBar,
    	public http:HttpClient
    	) {}

  getQuery(url:string,data:any){
  	const headers = new HttpHeaders({ 'Authorization' : 'Bearer ' + JSON.parse(localStorage.getItem("token")).token });
  	return this.http.post(CONST.API+url, data, { headers });
  }

  getQuerySibive(url:string,data:any){
    const headers = new HttpHeaders({ 'Authorization' : 'Bearer ' + localStorage.getItem("token_sibive") });
    return this.http.post(url, data, { headers });
  }

  getDataSibive(url:string,data:any){
    return this.getQuerySibive(url,data)
          .pipe( map( data => {
              return data;
        }) );
  }

  getData(url:string,data:any){
  	return this.getQuery(url,data)
	  			.pipe( map( data => {
			        return data;
				}) );
  }

  getData0(url:string,data:any){
  	return this.getQuery(url,data)
	  			.pipe( map( data => {
			        return data[0];
				}) );
  }

  setData(url:string,data:any){
  	return this.getQuery(url,data)
	  			.pipe( map( data => {
			        return data;
				}) );
  }

  recuperarContrasena(data:any){
    return this.http.post(CONST.API+'recuperarContrasena',data)
          .pipe( map( data => {
            return data;
          }) );
  }

  recuperarContrasenaDatos(data:any){
    return this.http.post(CONST.API+'recuperarContrasenaDatos',data)
          .pipe( map( data => {
            return data;
          }) );
  }

}
