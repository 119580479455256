import { Component, OnInit } from '@angular/core';
import { DomSanitizer  } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import swal from 'sweetalert';

import {
  ScriptService,
  GetserviesService
} from '../app.filesServices';

declare var $:any;
declare var moment:any;

@Component({
  selector: 'app-distanciatiempo',
  templateUrl: './distanciatiempo.component.html',
  styleUrls: ['./distanciatiempo.component.css']
})
export class DistanciatiempoComponent implements OnInit {

  loader:boolean = false;
  token = JSON.parse(localStorage.getItem("token"));
  heightMap:any = window.screen.height - 250;


  rutaocliente:any;
  ruta:any;

  unidadesList:any;
  unidad:any;

  dTtimeFullInicio:any = '00:00';
  dTtimeFullFin:any = '23:59';

  dTdateInicio:any = this.fechaAyer();
  dTtimeInicio:any = '00:00';
  dTdateFin:any = this.fechaHoy();
  dTtimeFin:any = '23:59';


  distancia:any;
  tiempo:any;
  kilometrosporhora:any;

  combustible:any = 1500;

  combustibleporhora:any;

  constructor(
  	private router:Router,
    public script:ScriptService,
    public getservices:GetserviesService,
    public snackBar:MatSnackBar,
    private sanitizer: DomSanitizer
  ) { 
    this.script.loadScript();
  }

  ngOnInit() {
  	// trae datos de rutas para select
    this.loader = true;
    let data = {
      pin: this.token.pin
    };
    this.getservices.getData('rutasoclientes',data)
    .subscribe( res => {
      this.rutaocliente = res;
      this.ruta = this.rutaocliente[0].label;
      this.unidades(this.ruta);
      if(this.rutaocliente.length == 1){
  	    $('.rutas').hide();
  	  }
      this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
    });
  }

  onChange(rutaID:any) {
    this.unidades(rutaID);
  }

  unidades(ruta:any){
    this.loader = true;
    let data = {
      ruta: ruta,
      pin: this.token.pin
    };
    this.getservices.getData('unidadesNombreRutaFiltro',data)
    .subscribe( res => {
      this.unidadesList = res;
      this.unidad = this.unidadesList[0].imei;
      this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
    });
  }

  distanciatiempo(tipo:string){
    this.loader = true;
    /// traer data precio combustible
    let data = {
      equipo: this.unidad
    };
    this.getservices.getData('combustibleUnidad',data)
    .subscribe( res => {
      this.combustible = res[0]['combustiblepromedio'];
      this.tiempo = 0;
      this.distancia = 0;
      this.combustibleporhora = 0;
      this.kilometrosporhora = 0;
    });
    //// traer datos distacia
    let data2;
    let hoydoble;
    if(tipo == 'presente'){
      data2 = {
        ruta: this.ruta,
        equipo: this.unidad,
        tipo: tipo,
        fechaIni: this.fechaHoy()+' '+$('.dTtimeFullInicio').val()+':00',
        fechaFin: this.fechaHoy()+' '+$('.dTtimeFullFin').val()+':00'
      };
    }else{
      /// if para ver si la fecha fin es igual a hoy
      if($('.dTdateFin').val() == this.fechaHoy()){
        hoydoble = {
          ruta: this.ruta,
          equipo: this.unidad,
          tipo: 'presente',
          fechaIni: this.fechaHoy()+' 00:00:00',
          fechaFin: this.fechaHoy()+' '+$('.dTtimeFin').val()+':00'
        };
      }
      ///
      data2 = {
        ruta: this.ruta,
        equipo: this.unidad,
        tipo: tipo,
        fechaIni: $('.dTdateInicio').val()+' '+$('.dTtimeInicio').val()+':00',
        fechaFin: $('.dTdateFin').val()+' '+$('.dTtimeFin').val()+':00'
      };
    }
    this.getservices.getData('tiemposydistancia',data2)
    .subscribe( res => {
      if(res[0] == undefined){
        this.snackBar.open('Tal ves esta pidiendo un rango de fecha mal... ', null, { duration: 6000 });
      }

      /// datos de hoy consulta doble
      if(tipo == 'historico' && $('.dTdateFin').val() == this.fechaHoy()){
        this.getservices.getData('tiemposydistancia',hoydoble)
        .subscribe( hoydatadoble => {

          this.loader = false;

          let tiempotemporal1 = res[0]['tiempoTotal'].split(':');
          let tiempotemporal2 = hoydatadoble[0]['tiempoTotal'].split(':');

          let tSeconds1 = (Number(tiempotemporal1[0])*3600)+(Number(tiempotemporal1[1])*60)+(Number(tiempotemporal1[2]));
          let tSeconds2 = (Number(tiempotemporal2[0])*3600)+(Number(tiempotemporal2[1])*60)+(Number(tiempotemporal2[2]));

          let tSeconds = tSeconds1 + tSeconds2;

          let hf = Math.trunc(tSeconds / 3600);
          let mf = Math.trunc(((tSeconds % 3600) / 60));
          let sf = (tSeconds % 3600) % 60;

          let mf2 = mf.toString();
          let sf2 = sf.toString();

          if(mf < 10){mf2 = '0'+mf;}
          if(sf < 10){sf2 = '0'+sf;}

          this.tiempo = hf+':'+mf2+':'+sf2;
          this.distancia = res[0]['metrosTotal'] + hoydatadoble[0]['metrosTotal'];

          let tiempodescompuesto = this.tiempo.split(':');
          let horas = tiempodescompuesto[0];
          let minutos = tiempodescompuesto[1]/60;
          let segundos = tiempodescompuesto[2]/3600;
          let totalhoras = Number(horas)+Number(minutos)+Number(segundos);

          this.combustibleporhora = this.combustible / totalhoras;

          this.kilometrosporhora = ((res[0]['metrosTotal']/1000)+(hoydatadoble[0]['metrosTotal']/1000))/totalhoras;

        },err => {
          this.loader = false;
          this.snackBar.open('Error de conexión en el servidor consulta presente doble.', null, { duration: 3000 });
        });
      }else{

        this.loader = false;

        this.tiempo = res[0]['tiempoTotal'];
        this.distancia = res[0]['metrosTotal'];

        let tiempodescompuesto = this.tiempo.split(':');
        let horas = tiempodescompuesto[0];
        let minutos = tiempodescompuesto[1]/60;
        let segundos = tiempodescompuesto[2]/3600;
        let totalhoras = Number(horas)+Number(minutos)+Number(segundos);

        this.combustibleporhora = this.combustible / totalhoras;

        this.kilometrosporhora = (res[0]['metrosTotal']/1000)/totalhoras;

      }

      },err => {
        this.loader = false;
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
    });
  }

  fechaHoy(){
    let now = new Date();
    let year = "" + now.getFullYear();
    let month = "" + (now.getMonth() + 1); if (month.length == 1) { month = "0" + month; }
    let day = "" + now.getDate(); if (day.length == 1) { day = "0" + day; }
    return year + "-" + month + "-" + day;
  }

  fechaAyer(){
    let hoy = this.fechaHoy();
    let ayer = this.editar_fecha(hoy, "-1", "d");
    return ayer;
  }

  editar_fecha(fecha, intervalo, dma) {
    var separador = separador || "-";
    var arrayFecha = fecha.split(separador);
    var dia = arrayFecha[2];
    var mes = arrayFecha[1];
    var anio = arrayFecha[0];
    
    var fechaInicial = new Date(anio, mes - 1, dia);
    var fechaFinal = fechaInicial;
    if(dma=="m" || dma=="M"){
      fechaFinal.setMonth(fechaInicial.getMonth()+parseInt(intervalo));
    }else if(dma=="y" || dma=="Y"){
      fechaFinal.setFullYear(fechaInicial.getFullYear()+parseInt(intervalo));
    }else if(dma=="d" || dma=="D"){
      fechaFinal.setDate(fechaInicial.getDate()+parseInt(intervalo));
    }else{
      return fecha;
    }
    dia = fechaFinal.getDate();
    mes = fechaFinal.getMonth() + 1;
    anio = fechaFinal.getFullYear();
   
    dia = (dia.toString().length == 1) ? "0" + dia.toString() : dia;
    mes = (mes.toString().length == 1) ? "0" + mes.toString() : mes;
   
    return anio + "-" + mes + "-" + dia;
  }



  getStyle() {
    // snip snip -> fetch the url from somewhere
    let height = this.heightMap;
    let style = `height: ${height}px;`;
    
    // sanitize the style expression
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

}
