import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer  } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AgmMap, AgmMarker, AgmPolygon, AgmCircle, AgmPolyline } from '@agm/core';
import { HttpClient } from '@angular/common/http';

import {
  ScriptService,
  GetserviesService
} from '../app.filesServices';

declare var $:any;
declare var moment:any;
declare var google:any;
declare var setMap:any;

@Component({
  selector: 'app-lagos',
  templateUrl: './lagos.component.html',
  styleUrls: ['./lagos.component.css']
})
export class LagosComponent implements OnInit {

  @ViewChild(AgmMap) map: any;
  @ViewChild(AgmMap) mapReportVel: any;

  loader:boolean = false;
  token = JSON.parse(localStorage.getItem("token"));
  mapa = JSON.parse(localStorage.getItem("mapa"));
  heightMap:any = window.screen.height - 250;

  lat:number = this.mapa['lat'];
  lng:number = this.mapa['lng'];
  zoom:number = this.mapa['zoom'];

  rutaocliente:any;
  ruta:any;

  dTtimeFullInicio:any = '00:00';
  dTtimeFullFin:any = '23:59';

  dTdateInicio:any = this.fechaAyer();
  dTtimeInicio:any = '00:00';
  dTdateFin:any = this.fechaHoy();
  dTtimeFin:any = '23:59';

  velocidad:any = '60';

	red:any = '3';
	condicional:any = '<=';
	satelites:any = '6';

  listaDatos:Array<any> = [];
  nom:number = 0;

  markers:Array<any> = [];

  datosmM:any = {};
  unidadesList:any;
  datosDescripcion:any;

  constructor(
  	public http:HttpClient,
  	private router:Router,
    public script:ScriptService,
    public getservices:GetserviesService,
    public snackBar:MatSnackBar,
    private sanitizer: DomSanitizer
  ) { 
    this.script.loadScript();
  }

  public mapReady(map) {
    this.map = map;
  }

  mostrar(dato:any){
    $('.boto'+dato).hide();
    $('.sate'+dato).show();
  }

  ngOnInit() {
  	this.traerGeocercas();
  	// trae datos de rutas para select
    this.loader = true;
    let data = {
      pin: this.token.pin
    };
    this.getservices.getData('rutasoclientes',data)
    .subscribe( res => {
      this.rutaocliente = res;
      this.ruta = this.rutaocliente[0].label;
      this.dotosdetecta(this.rutaocliente[0].label);
      this.unidades(this.rutaocliente[0].label);
      if(this.rutaocliente.length == 1){
  	    $('.rutas').hide();
  	  }
      this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
    });
  }

  onChange(rutaID:any) {
  	this.dotosdetecta(rutaID);
  	this.unidades(rutaID);
  }

  unidades(ruta:any){
  		this.loader = true;
	    let data = {
	      ruta: ruta,
	      pin: this.token.pin
	    };
	    this.getservices.getData('unidadesNombreRutaFiltro',data)
	    .subscribe( res => {
	      this.unidadesList = res;
	      this.loader = false;
	      },err => {
	        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
	        this.loader = false;
	    });
  	}

  mapavelocidades(tipo:string){
    this.centrarmapa(1);
    this.loader = true;
    this.listaDatos = [];
    //// traer datos distacia
    let data2;
    let hoydoble;
    let unidades = $('.unidades').val();

    if(tipo == 'presente'){
      data2 = {
        ruta: this.ruta,
        unidades: unidades,
        tipo: tipo,
        red: this.red,
        condicional: this.condicional,
        satelites: this.satelites,
        fechaIni: this.fechaHoy()+' '+$('.dTtimeFullInicio').val()+':00',
        fechaFin: this.fechaHoy()+' '+$('.dTtimeFullFin').val()+':00'
      };
    }else{
      /// if para ver si la fecha fin es igual a hoy
      if($('.dTdateFin').val() == this.fechaHoy()){
        hoydoble = {
          ruta: this.ruta,
          unidades: unidades,
          tipo: 'presente',
          red: this.red,
          condicional: this.condicional,
          satelites: this.satelites,
          fechaIni: this.fechaHoy()+' 00:00:00',
          fechaFin: this.fechaHoy()+' '+$('.dTtimeFin').val()+':00'
        };
      }
      ///
      data2 = {
        ruta: this.ruta,
        unidades: unidades,
        tipo: tipo,
        red: this.red,
        condicional: this.condicional,
        satelites: this.satelites,
        fechaIni: $('.dTdateInicio').val()+' '+$('.dTtimeInicio').val()+':00',
        fechaFin: $('.dTdateFin').val()+' '+$('.dTtimeFin').val()+':00'
      };
    }
    this.getservices.getData('lagostransmision',data2)
    .subscribe( (res:any[]) => {
      if(res[0] == undefined){
        this.snackBar.open('No hay datos en ese rango o tal ves esta pidiendo un rango de fecha mal... ', null, { duration: 6000 });
      }

      /// datos de hoy consulta doble
      if(tipo == 'historico' && $('.dTdateFin').val() == this.fechaHoy()){
        this.getservices.getData('lagostransmision',hoydoble)
        .subscribe( (hoydatadoble:any[]) => {

          this.loader = false;
          ///
          var countR = res.length;
          if(countR > 0){
          	for (var i = 0; i < res.length; ++i) {
	        	  this.listaDatos.push(res[i]);
	          }
          }
          var countD = hoydatadoble.length;
          if(countD > 0){
        	  for (var i = 0; i < hoydatadoble.length; ++i) {
        	    this.listaDatos.push(hoydatadoble[i]);
            }
	        }

          this.nom = this.listaDatos.length;
          if(this.nom > 0){
    			  this.crearmarker(this.listaDatos);
            	  this.centrarmapa(2);
    		  }

        },err => {
          this.loader = false;
          this.snackBar.open('Error de conexión en el servidor consulta presente doble.', null, { duration: 3000 });
        });
      }else{

        this.loader = false;
        ///
        var count = res.length;
        if(count > 0){
        	for (var i = 0; i < res.length; ++i) {
	        	this.listaDatos.push(res[i]);
	        }
	      }

        this.nom = this.listaDatos.length;
    		if(this.nom > 0){
    			this.crearmarker(this.listaDatos);
          		this.centrarmapa(2);
    		}

      }

      },err => {
        this.loader = false;
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
    });
  }

  centrarmapa(tipo:any){
    if(tipo == 1){
      this.zoom = this.mapa['zoom']-4;
      this.map.setCenter({ lat: this.mapa['lat']-2, lng: this.mapa['lng']+2 });
      this.lat = this.mapa['lat']-2;
      this.lng = this.mapa['lng']+2;
    }else{
      this.zoom = this.mapa['zoom'];
      this.map.setCenter({ lat: this.mapa['lat'], lng: this.mapa['lng'] });
      this.lat = this.mapa['lat'];
      this.lng = this.mapa['lng'];
    }
  }

  crearmarker(data:any){
    let color;
    if(this.satelites < 7 ){
      color = '#CB272C';
    }else if(this.satelites >= 7 && this.satelites <= 10){
      color = '#FD6417';
    }else if(this.satelites >= 11 && this.satelites <= 14){
      color = '#0A4FE5';
    }else if(this.satelites >= 15 && this.satelites <= 19){
      color = '#2900E4';
    }
  	if(this.markers){
  		for (var i = 0; i < this.markers.length; ++i) {
  			this.markers[i].setMap(null);
  		}
  		this.markers = [];
  	}
    for (var i = 0; i < data.length; ++i) {
      this.mapReportVel._mapsWrapper.createMarker({
        position: { lat: Number(data[i].lat), lng: Number(data[i].lng) },
        zIndex: 15,
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 5, //tamaño
          strokeColor: color, //color del borde
          strokeWeight: 0, //grosor del borde
          fillColor: color, //color de relleno
          fillOpacity:0.4// opacidad del relleno
        },
        title: 'U '+data[i].unidad+' - [ '+data[i].speed+' KM/H // satélites: '+data[i].sn+' ] - '+data[i].fechahora
      }).then((marker: any) => {
        this.markers[this.markers.length] = marker;
      });
    }

    this.snackBar.open('Marker ok', null, { duration: 3000 });
	  let el = document.getElementById('mapavelocidades');
	  el.scrollIntoView();  
  }

  traerGeocercas(){
    let data = {
      pin: this.token.pin
    };
    this.getservices.getData('geocercasMapa',data)
    .subscribe( res => {
      this.checarGeo(res);
    },err => {
      this.snackBar.open('Error de conexión en el servidor. geocercas', null, { duration: 3000 });  
    });
  }

  dotosdetecta(dato:any){
  	let data = {
      ruta: dato
    };
    this.getservices.getData('lagosdetect',data)
    .subscribe( res => {
    	this.datosmM = res[0];
    },err => {
      this.snackBar.open('Error de conexión en el servidor. geocercas', null, { duration: 3000 });  
    });
  }

  descripcion(tipo:any){
    let data = {
      ruta: this.ruta,
      tipo: tipo
    };
    this.getservices.getData('lagosdetect',data)
    .subscribe( res => {
      this.datosDescripcion = res;
    },err => {
      this.snackBar.open('Error de conexión en el servidor. geocercas', null, { duration: 3000 });  
    });
  }

  checarGeo(geo:any){
    for (var i = 0; i < geo.length; ++i) {
      if(geo[i]['tipo'] == 'circular'){
        this.hacerCircleGeo(geo[i]);
      }else if(geo[i]['tipo'] == 'poligonal'){
        this.hacerPolygonGeo(geo[i]);
      }else if(geo[i]['tipo'] == 'lineal'){
        this.hacerPolylineGeo(geo[i]);
      }
    }
  }

  hacerCircleGeo(cir:any){
    let color = cir['color'];
    cir = JSON.parse(cir.coords);
    this.mapReportVel._mapsWrapper.createCircle({
      center: { lat: cir['lat'], lng: cir['lng'] },
      radius: Number(cir['radio']),
      strokeColor: color,
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillColor: color,
      fillOpacity: 0.3,
      visible: true
    });
  }

  hacerPolygonGeo(poly:any){
    this.mapReportVel._mapsWrapper.createPolygon({
      paths: eval(poly['coords']),
      strokeColor: poly['color'],
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillColor: poly['color'],
      fillOpacity: 0.3,
      visible: true
    });
  }

  hacerPolylineGeo(line:any){
    this.mapReportVel._mapsWrapper.createPolyline({
      strokeColor: line['color'],
      strokeOpacity: 0.6,
      strokeWeight: 5,
      path: eval(line['coords']),
      visible: true
    });
  }

  fechaHoy(){
    let now = new Date();
    let year = "" + now.getFullYear();
    let month = "" + (now.getMonth() + 1); if (month.length == 1) { month = "0" + month; }
    let day = "" + now.getDate(); if (day.length == 1) { day = "0" + day; }
    return year + "-" + month + "-" + day;
  }

  fechaAyer(){
    let hoy = this.fechaHoy();
    let ayer = this.editar_fecha(hoy, "-1", "d");
    return ayer;
  }

  editar_fecha(fecha, intervalo, dma) {
    var separador = separador || "-";
    var arrayFecha = fecha.split(separador);
    var dia = arrayFecha[2];
    var mes = arrayFecha[1];
    var anio = arrayFecha[0];
    
    var fechaInicial = new Date(anio, mes - 1, dia);
    var fechaFinal = fechaInicial;
    if(dma=="m" || dma=="M"){
      fechaFinal.setMonth(fechaInicial.getMonth()+parseInt(intervalo));
    }else if(dma=="y" || dma=="Y"){
      fechaFinal.setFullYear(fechaInicial.getFullYear()+parseInt(intervalo));
    }else if(dma=="d" || dma=="D"){
      fechaFinal.setDate(fechaInicial.getDate()+parseInt(intervalo));
    }else{
      return fecha;
    }
    dia = fechaFinal.getDate();
    mes = fechaFinal.getMonth() + 1;
    anio = fechaFinal.getFullYear();
   
    dia = (dia.toString().length == 1) ? "0" + dia.toString() : dia;
    mes = (mes.toString().length == 1) ? "0" + mes.toString() : mes;
   
    return anio + "-" + mes + "-" + dia;
  }

  getStyle() {
    // snip snip -> fetch the url from somewhere
    let height = this.heightMap;
    let style = `height: ${height}px;`;
    
    // sanitize the style expression
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

}
