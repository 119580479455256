import { Injectable } from '@angular/core';

declare var google:any;

@Injectable({
  providedIn: 'root'
})
export class GetPathCoordsService {

  constructor() { }

  getPathFromCoordsArray(tmp_str) {
    var tmp_array = [];
    tmp_array = tmp_str.split("),(");  
    var tmp_coords = [];

    for (var i=0; i<tmp_array.length; i++)  {
        var tmp_array_2 = tmp_array[i];
        var tmp_arr_value = tmp_array_2.trim();
        var tmp_str_2;

        if ((tmp_arr_value.indexOf("(")) === 0)  {
            tmp_str_2 = tmp_arr_value.slice(1)
        }
        else if (tmp_arr_value.indexOf(")") >=0)  {
            tmp_str_2 = tmp_arr_value.substring(0, tmp_arr_value.length-1)    
        }
        else {
            tmp_str_2 = tmp_arr_value;
        }            

        var tmp_array_3 = [];
        tmp_array_3 = tmp_str_2.split(",");

        tmp_coords.push(new google.maps.LatLng(tmp_array_3[0], tmp_array_3[1]));
    }  

    return tmp_coords;
  }

}
