import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate{

  constructor(
          private router:Router,
          public snackBar:MatSnackBar,
          private auth:AuthService,
        ) {}

  canActivate(next:ActivatedRouteSnapshot){

    if(localStorage.getItem("token")){
      const token = JSON.parse(localStorage.getItem("token"));
      this.auth.tokencheck(token['token'])
      .subscribe( res => {
        if(res['res'] == true){
          return true;
        }
        },err => {
          localStorage.removeItem("token");
          this.router.navigate(['login']);
          this.snackBar.open('Token expirado o Error de conexión en el servidor', null, {
              duration: 5000
          });
          return false;
        });
    return true;
    }else{
      this.router.navigate(['login']);
      this.snackBar.open('No puede acceder a la página: '+ next.url +' sin sesión', null, {
          duration: 5000
      });
      return false;
    }  
  }

}
