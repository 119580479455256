import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AgmMap, AgmPolygon, AgmCircle, AgmPolyline, AgmMarker} from '@agm/core';
import { DomSanitizer  } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

import {
  ScriptService,
  GetserviesService
} from '../app.filesServices';

import { AngularFirestore } from '@angular/fire/firestore';

import { environment } from '../../environments/environment';

declare var google:any;
declare var $:any;
declare var Codebase:any;
declare var moment:any;
declare var swal:any;

@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
})
export class MapaComponent implements OnInit, OnDestroy {

  loader:boolean = false;

  @ViewChild(AgmMap) map: any;
  @ViewChild(AgmMap) mapGeo: any;
  //public map: any;

  unidadBusqueda:any;

  token = JSON.parse(localStorage.getItem("token"));
  mapa = JSON.parse(localStorage.getItem("mapa"));
  heightMap:any = window.screen.height - 250;

  swal:any = {
      title: "¿Seguro que quiere seguir con la acción?",
      text: "De click en ok para confirmar si no cancele.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
  };
  icon:any = {icon: "success"};

  lat:number = this.mapa['lat'];
  lng:number = this.mapa['lng'];
  zoom:number = this.mapa['zoom'];

  botonparodemotroview:boolean = false;

  unidadeslista:Array<any> = [];
  clienteslista:any;
  tipobateria:any;

  parosdemotor:any;

  //// modal info
  ruta:any;
  rutaAirView:any;
  unidad:any;
  tipounidad:any;
  alias:any;
  imei:any;
  cargo:any;
  responsable:any;
  responsableunidad:any;
  tel_responsable:any;
  mdvr:any;
  plan:any;
  direccion:any;
  fecha:any;
  hora:any;
  metros:any;
  tiempo:any;
  speed:any;
  nombre:any;
  tel:any;
  tel1:any;
  tel2:any;
  latParo:any;
  lngParo:any;

  ///sigueme
  siguemeVar:boolean = false;
  siguemeUnidad:any = '';
  siguemeImei:any;

  transmision:boolean = false;


  arreglo:Array<any> = [];

  nobandera:number = 0;

  markers:any = [];

  rutasGet:any;


  ////consulta de movimiento o parado

  MPfechahora1:any;
  MPlat1:any;
  MPlng1:any;
  MPtipo1:any;
  ///
  MPfechahora2:any;
  MPlat2:any;
  MPlng2:any;
  MPtipo2:any;
  ///
  MPmetros:any;
  MPresult:boolean = false;
  tiempoMP:any;
  metrosMP:any;

  sibive:any = 'no';

  datasibive: any = {
    salida_hora: '00:00',
    excesos: 0,
    justificantes: 0,
    sanciones: 0,
    incidencias: 0,
    salidas: 0,
    foto: false,
    cd: false,
    anp: false,
    barras: 'x',
  };

  verFoto:boolean = false;
  verFotocd:boolean = false;
  verFotoanp:boolean = false;

  stopinterval:any;


  circulares: any = [];
  poligonales: any = [];
  visibleOculto:any = false;

  items2: any = [];
  rutaSeach: any;
  rutaSeachURL: any;
  config2: any = {'max': 7, 'placeholder': 'Ruta', 'sourceField': ['airview']};

  constructor(
    private router: Router, 
    private activatedRoute: ActivatedRoute,
    public http:HttpClient,
    private db: AngularFirestore,
    private sanitizer: DomSanitizer,
    public script:ScriptService,
    public getservices:GetserviesService,
    public snackBar:MatSnackBar,
    ) {
    this.script.loadScript();
    /// hago que se active la funcion para colorpiker
    $(function () {
        Codebase.helpers(['select2']);
    });
    ///cacho las rutas en bariables
    this.activatedRoute.params.subscribe( params =>{
      if(params['rutas']){
        this.rutasGet = params['rutas'];
      }
    });
  }

  onSelect(item: any) {
    this.rutaSeachURL = item['label'];
    this.rutaSeach = item['airview'];
  }

  public mapReady(map) {
    let trafficLayer = new google.maps.TrafficLayer();
    trafficLayer.setMap(map);
    this.map = map;
  }

  ngOnInit() {
    ///
    this.traerGeocercas();
    ///// checar si hay rutas por GET para cambiar la consulta
    if(this.rutasGet){
      /// traer datos una ves
      let data = {
        pin: this.token.pin,
        rutas: this.rutasGet
      };
      this.getservices.getData('rutasoclientesGet',data)
      .subscribe( res => {
      this.items2 = res;
      this.clienteslista = res;
        ////// traer datos una ves
        this.getservices.getData('rutasGet',data)
        .subscribe( (ultomosdatos:any[]) => {
          //console.log(ultomosdatos);
          if(ultomosdatos == undefined || ultomosdatos.length == 0){
            this.router.navigate(['reload','mapa']);
          }else{
            for (var i = 0; i < ultomosdatos.length; ++i) {
              for (var n = 0; n < ultomosdatos[i].length; ++n) {
                this.unidadeslista.push(ultomosdatos[i][n]);
              }
            }
            this.crearmarker(this.unidadeslista);
            this.checktransmision(this.unidadeslista);
            this.checkparosdemotorbotoon();
          }
        });
        this.stopinterval = setInterval(()=> {
          ////// traer datos para setear
          this.getservices.getData('rutasGet',data)
          .subscribe( (ultomosdatos:any[]) => {
            if(ultomosdatos == undefined || ultomosdatos.length == 0){
            }else{
              this.unidadeslista = [];
              for (var i = 0; i < ultomosdatos.length; ++i) {
                for (var n = 0; n < ultomosdatos[i].length; ++n) {
                  this.unidadeslista.push(ultomosdatos[i][n]);
                }
              }
              this.setpositionnew(this.unidadeslista);
              this.seguirFuncion(this.unidadeslista);
              this.checktransmision(this.unidadeslista);
            }
          });
        },30000);
        /// traigo paros de motor por lote
        this.db.collection('parosdemotor').valueChanges()
         .subscribe((data:any[]) => {
           this.parosdemotor = data;
        });
        ////
      },err => {
        this.snackBar.open('Error de conexión en el servidor. rutas o clientes', null, { duration: 3000 });  
      });
    }else{
      // trae datos de rutas
      let data = {
        pin: this.token.pin
      };
      this.getservices.getData('rutasoclientes',data)
      .subscribe( res => {
        this.items2 = res;
        this.rutaSeach = res[0]['airview'];
        this.clienteslista = res;
        let countData:any = res;
        countData = countData.length;
        ///
        if(countData == 1){
          //// consultas de unidades y datos por ruta
          let data = { ruta: res[0]['label'], pin: this.token.pin };
          this.traerultomosdatos(data,'ultimosdatosruta');
          this.stopinterval = setInterval(()=> {
            this.setearultomosdatos(data,'ultimosdatosruta'); 
          },30000);
          /// traigo paros de motor
          this.db.collection('parosdemotor', ref => 
            ref.where('ruta', '==', res[0]['label'])
          ).valueChanges()
           .subscribe((data:any[]) => {
             this.parosdemotor = data;
          });
        }else{
          $("#modal-inforutasselect").modal("show");
        }
        ///
      },err => {
        this.snackBar.open('Error de conexión en el servidor. rutas o clientes', null, { duration: 3000 });  
      });
    }
  }

  geocercas(param){
    if(param == false){
      this.visibleOculto = true;
    }else{
      this.visibleOculto = false;
    }
    for (var i = 0; i < this.poligonales.length; ++i) {
      this.poligonales[i].setVisible(param);
    }
    for (var i = 0; i < this.circulares.length; ++i) {
      this.circulares[i].setVisible(param);
    }
  }

  ngOnDestroy() {
    clearTimeout(this.stopinterval);
  }

  consultarRutasSearch(){
    this.router.navigate(['reloadid','maparutas',this.rutaSeachURL]);
  }

  consultarRutas(){
    let datoSelect = $('.queRutasmapa').val().length;
    if(datoSelect == this.clienteslista.length){
      this.todosDatosRutas();
    }else{
      let rutas = '';
      for (var i = 0; i < datoSelect; ++i) {
        rutas = rutas + $('.queRutasmapa').val()[i];
        if(datoSelect-1 != i){
          rutas = rutas+':';
        }
      }
      this.router.navigate(['reloadid','maparutas',rutas]);
    }
  }

  todosDatosRutas(){
    let data = { pin: this.token.pin };
    this.traerultomosdatos(data,'ultimosdatosporrutas');
    this.stopinterval = setInterval(()=> {
      this.setearultomosdatos(data,'ultimosdatosporrutas'); 
    },30000);
    /// traigo paros de motor por lote
    this.db.collection('parosdemotor').valueChanges()
     .subscribe((data:any[]) => {
       this.parosdemotor = data;
    });
  }

  traerultomosdatos(data:any,url:string){
    this.getservices.getData(url,data)
    .subscribe( (ultomosdatos:any[]) => {
      //console.log(ultomosdatos);
      if(ultomosdatos == undefined || ultomosdatos.length == 0){
        this.router.navigate(['reload','mapa']);
      }else{
        if(url == 'ultimosdatosporrutas'){
          for (var i = 0; i < ultomosdatos.length; ++i) {
            for (var n = 0; n < ultomosdatos[i].length; ++n) {
              this.unidadeslista.push(ultomosdatos[i][n]);
            }
          }
          this.crearmarker(this.unidadeslista);
          this.checktransmision(this.unidadeslista);
        }else{
          this.unidadeslista = ultomosdatos;
          this.crearmarker(ultomosdatos);
          this.checktransmision(ultomosdatos);
        }
        this.checkparosdemotorbotoon();
      }
    });
  }
  setearultomosdatos(data:any,url:string){
    this.getservices.getData(url,data)
    .subscribe( (ultomosdatos:any[]) => {
      if(ultomosdatos == undefined || ultomosdatos.length == 0){
      }else{
        this.unidadeslista = [];
        if(url == 'ultimosdatosporrutas'){
          for (var i = 0; i < ultomosdatos.length; ++i) {
            for (var n = 0; n < ultomosdatos[i].length; ++n) {
              this.unidadeslista.push(ultomosdatos[i][n]);
            }
          }
          this.setpositionnew(this.unidadeslista);
          this.seguirFuncion(this.unidadeslista);
          this.checktransmision(this.unidadeslista);
        }else{
          this.unidadeslista = ultomosdatos;
          this.setpositionnew(ultomosdatos);
          this.seguirFuncion(ultomosdatos);
          this.checktransmision(ultomosdatos);
        }
      }
    });
  }
  
  crearmarker(data:any){
    for (var i = 0; i < data.length; ++i) {
      this.mapGeo._mapsWrapper.createMarker({
        position: { lat: Number(data[i].lat), lng: Number(data[i].lng) },
        zIndex: 5,
        icon: {
          //path: google.maps.SymbolPath.CIRCLE,
          path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z',
          scale: 0.9,
          fillColor: data[i].coloricon,
          fillOpacity: 1,
          strokeWeight: 1,
          labelOrigin: {x: 0, y: -30}
        },
        label: {
          text: data[i].unidad,
          fontWeight: 'bold',
          color: data[i].colortext
        },
        title: data[i].imei
      }).then((marker: any) => {
        this.markers[this.markers.length] = marker;
        marker.addListener('click', () => {
          this.imeimodal(marker.getTitle());
        });

      });
    }
    this.snackBar.open('Marker ok', null, { duration: 3000 }); 
  }
  setpositionnew(data:any){
    for (var i = 0; i < data.length; ++i) {
      this.markers[i].setPosition({ lat: Number(data[i].lat), lng: Number(data[i].lng) });
    }
  }

  checkparosdemotorbotoon(){
    return;
    setTimeout(()=>{
      if(this.parosdemotor.length==0){
        this.botonparodemotroview = true;
      }else if(this.parosdemotor.length != this.unidadeslista.length){
        this.botonparodemotroview = true;
      }
    },5000);
  }

  /*
  crearDatosParosdeMotor(){
    this.botonparodemotroview = false;
    for (var i = 0; i < this.unidadeslista.length; ++i) {
      this.setParodemotor(this.unidadeslista[i]);
    }
  }

  setParodemotor(info:any){
    this.db.collection('parosdemotor').doc(info.imei).set({
      imei: info.imei, 
      ruta: info.ruta, 
      tipo: 'prendermotor'
    });
  }
  */

  checktransmision(data:any){
    let num = 0;
    let hoy = this.js_yyyy_mm_dd_hh_mm_ss();
    let fechahoy = this.js_yyyy_mm();

    for (var i = data.length - 1; i >= 0; i--) {
      if(data[i]['fecha'] == fechahoy){ /// PARA OMITIR UNIDADES QUE SIN TRANSMITIR TODO EL DIA
        var fecha1 = moment(data[i]['fecha']+' '+data[i]['hora'], "YYYY-MM-DD HH:mm:ss");
        var fecha2 = moment(hoy, "YYYY-MM-DD HH:mm:ss");

        var diff = fecha2.diff(fecha1, 'm');
        if(diff > 30){
          num = num + 1;
        }
      }
    }

    if(num > 0){
      this.transmision = true;
    }

  }

  traerGeocercas(){
    let data = {
      pin: this.token.pin
    };
    this.getservices.getData('geocercasMapa',data)
    .subscribe( res => {
      this.checarGeo(res);
    },err => {
      this.snackBar.open('Error de conexión en el servidor. geocercas', null, { duration: 3000 });  
    });
  }

  checarGeo(geo:any){
    for (var i = 0; i < geo.length; ++i) {
      if(geo[i]['tipo'] == 'circular'){
        this.hacerCircleGeo(geo[i]);
      }else if(geo[i]['tipo'] == 'poligonal'){
        this.hacerPolygonGeo(geo[i]);
      }else if(geo[i]['tipo'] == 'lineal'){
        this.hacerPolylineGeo(geo[i]);
      }
    }
  }

  hacerCircleGeo(cir:any){
    let nombre = cir['nombre'];
    let color = cir['color'];
    let vista = cir['visible'];
    cir = JSON.parse(cir.coords);
    if(vista == 'si'){
      this.mapGeo._mapsWrapper.createCircle({
        center: { lat: cir['lat'], lng: cir['lng'] },
        radius: Number(cir['radio']),
        strokeColor: color,
        strokeOpacity: 0.8,
        strokeWeight: 3,
        fillColor: color,
        fillOpacity: 0.3
      }).then((circle: any) => {
        this.circulares.push(circle);
        var popup = new google.maps.InfoWindow();
        circle.addListener('click', (e) => {
          popup.setContent(nombre);
          popup.setPosition(e.latLng);
          popup.open(this.map);
        });
      });
    }
  }

  hacerPolygonGeo(poly:any){
    let nombre = poly['nombre'];
    let visible = poly['visible'];
    if(visible == 'si'){
      this.mapGeo._mapsWrapper.createPolygon({
        paths: eval(poly['coords']),
        strokeColor: poly['color'],
        strokeOpacity: 0.8,
        strokeWeight: 3,
        fillColor: poly['color'],
        fillOpacity: 0.3
      }).then((polygon: any) => {
        this.poligonales.push(polygon);
        var popup = new google.maps.InfoWindow();
        polygon.addListener('click', (e) => {
          popup.setContent(nombre);
          popup.setPosition(e.latLng);
          popup.open(this.map);
        });
      });
    }
  }

  hacerPolylineGeo(line:any){
    let visible = line['visible'];
    if(visible == 'si'){
      this.mapGeo._mapsWrapper.createPolyline({
        strokeColor: line['color'],
        strokeOpacity: 0.6,
        strokeWeight: 5,
        visible: visible,
        path: eval(line['coords'])
      });
    }
  }

  imeimodal(imei:any){
    for (var i = 0; i < this.unidadeslista.length; ++i) {
      if(this.unidadeslista[i]['imei'] === imei){
        this.centermap(this.unidadeslista[i]);
        this.marcket(this.unidadeslista[i]);
      }
    }

  }

  marcket(info:any){
    $("#modal-infogps").modal("show");
    this.info(info);
  }

  adress(){
    // traigo adress // con evn
    this.http.get('https://maps.googleapis.com/maps/api/geocode/json?latlng='+this.latParo+','+this.lngParo+'&key='+environment.ubicacion)
    .subscribe((data) => {
      if(data['status'] == "OK"){
        this.direccion = data['results'][2].formatted_address;
      }else{
        this.direccion = 'No hay dirección';
      }
    });
  }

  info(info:any){
    this.direccion = '';
    this.paradoMovimiento(info['imei'],info['ruta']);
    // primero centro el mapa en el market
    this.map.setCenter({ lat: Number(info['lat']), lng: Number(info['lng']) });
    this.zoom = 19;
    this.ruta = info['ruta'];
    this.rutaAirView = info['airview'];
    this.sibive = info['sibive'];
    this.unidad = info['unidad'];
    this.tipounidad = info['tipounidad'];
    this.alias = info['alias'];
    this.imei = info['imei'];
    this.mdvr = info['mdvr'];
    this.cargo = info['cargo'];
    this.responsable = info['responsable'];
    this.tel_responsable = info['tel_responsable'];
    this.responsableunidad = info['responsableunidad'];
    this.plan = info['plan'];
    this.fecha = info['fecha'];
    this.hora = info['hora'];
    this.speed = info['speed'];
    this.metros = info['metros'];
    this.tiempo = info['tiempo'];
    this.nombre = info['nombre'];
    this.tel = info['tel'];
    this.tel1 = info['tel1'];
    this.tel2 = info['tel2'];
    this.latParo = info['lat'];
    this.lngParo = info['lng'];
    if(info['tipo']=='tracker'){
      this.tipobateria = 'Conectado directo a la corriente.';
    }else if(info['tipo']=='ac alarm'){
      this.tipobateria = 'Equipo desconectado.';
    }else if(info['tipo']=='low battery'){
      this.tipobateria = 'Transmitiendo con bateria.';
    }
    ///
    if(info['sibive'] == 'si'){
      this.verFoto = false;
      this.datasibive = { salida_hora: '...', excesos: '...', justificantes: '...', sanciones: '...', incidencias: '...', salidas: '...', foto: false, cd: false, anp: false, barras: '...' };
      let misibive = {
        ruta: info['ruta'],
        unidad: info['unidad'],
      };
      this.getservices.getDataSibive('https://admin.wred.mx/api/V1/sibive',misibive)
      .subscribe( (sibive:any[]) => {
        this.datasibive = sibive;
      });
    }
    ///
  }

  miFoto(){
    this.verFoto = true;
    
    this.verFotocd = false;
    this.verFotoanp = false;
  }

  miFotocd(){
    this.verFotocd = true;

    this.verFoto = false;
    this.verFotoanp = false;
  }

  miFotoanp(){
    this.verFotoanp = true;

    this.verFoto = false;
    this.verFotocd = false;
  }

  paradoMovimiento(imei:any,ruta:any){
    ///// traer primer ultimo dato
    let data = {
        imei: imei,
        ruta: ruta
      };
    this.getservices.getData('tiempoDetenidoMovimiento',data)
    .subscribe( (ultomodato:any[]) => {
      if(ultomodato['res']==true){
        this.MPresult = true;
        this.MPtipo1 = ultomodato['tipo'];
        this.MPfechahora1 = ultomodato['data'][0]['fechahora'];
        this.MPlat1 = ultomodato['data'][0]['lat'];
        this.MPlng1 = ultomodato['data'][0]['lng'];
        ////
        this.tiempoMP = 'Calculando...';
        this.metrosMP = 'Calculando...';
        this.segundaParadoMovimiento(ruta,imei,ultomodato['data'][0]['fechahora']);
      }else{
        this.MPresult = false;
      }
    });
  }

  segundaParadoMovimiento(ruta:any,imei:any,fechahora:any){
    ///// traer primer ultimo dato
    let data = {
        imei: imei,
        ruta: ruta,
        fechahora: fechahora
      };
    this.getservices.getData('tiempoDetenidoMovimientoDos',data)
    .subscribe( (ultomodato:any[]) => {
      if(ultomodato['res']==true){
        if(this.MPtipo1 == ultomodato['tipo']){
          this.MPfechahora2 = ultomodato['data'][0]['fechahora'];
          this.MPlat2 = ultomodato['data'][0]['lat'];
          this.MPlng2 = ultomodato['data'][0]['lng'];
          this.segundaParadoMovimiento(ruta,imei,ultomodato['data'][0]['fechahora']);
        }else{
          this.MPfechahora2 = ultomodato['data'][0]['fechahora'];
          this.MPlat2 = ultomodato['data'][0]['lat'];
          this.MPlng2 = ultomodato['data'][0]['lng'];
          this.checkdataMP();
        }
      }else{
        if(this.MPfechahora2){
          this.MPfechahora2 = this.MPfechahora2;
          this.MPlat2 = this.MPlat2;
          this.MPlng2 = this.MPlng2;
        }else{
          this.MPfechahora2 = this.MPfechahora1;
          this.MPlat2 = this.MPlat1;
          this.MPlng2 = this.MPlng1;
        }
        this.checkdataMP();
      }
    });
  }

  checkdataMP(){
    this.tiempoMP = moment.utc(moment(this.MPfechahora1,"YYYY-MM-DD HH:mm:ss").diff(moment(this.MPfechahora2,"YYYY-MM-DD HH:mm:ss"))).format("HH:mm:ss");
    if(this.MPtipo1 == 'detenido'){
      this.metrosMP = '0.0 M.';
    }else{
      this.metrosMP = this.getDistanceFromLatLonInKm(this.MPlat1,this.MPlng1,this.MPlat2,this.MPlng2);
    }
  }

  getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
  var R = 6371; // Radius of the earth in km
  var dLat = this.deg2rad(lat2-lat1);  // deg2rad below
  var dLon = this.deg2rad(lon2-lon1); 
  var a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * 
    Math.sin(dLon/2) * Math.sin(dLon/2)
    ; 
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  var d = R * c; // Distance in km
  var total = d * 1000;
  return this.redondearDecimales(total,2)+' M.';
  }

  deg2rad(deg) {
    return deg * (Math.PI/180);
  }

  redondearDecimales(numero, decimales) {
    var numeroRegexp = new RegExp('\\d\\.(\\d){' + decimales + ',}');   // Expresion regular para numeros con un cierto numero de decimales o mas
    if (numeroRegexp.test(numero)) {         // Ya que el numero tiene el numero de decimales requeridos o mas, se realiza el redondeo
        return Number(numero.toFixed(decimales));
    } else {
        return Number(numero.toFixed(decimales)) === 0 ? 0 : numero;  // En valores muy bajos, se comprueba si el numero es 0 (con el redondeo deseado), si no lo es se devuelve el numero otra vez.
    }
  }

  panicoalert(imei:any){
    //console.log(info);
    swal(this.swal)
    .then((willDelete) => {
      if (willDelete) {
        //window.open("http://monitorvial.com:88/808gps/open/player/video.html?lang=en&vehiIdno="+info.imei+"&account=admin&password=admin", "_blank");
        this.router.navigate(['alertaspanico',imei]);
      }
    });
  }

  parodemotor(info:any,tipo:any){
    swal(this.swal)
    .then((willDelete) => {
      if (willDelete) {
        this.smsmotor(info.imei,tipo);
        this.db.collection('parosdemotor').doc(info.imei).set({
          imei: info.imei, 
          ruta: info.ruta, 
          tipo: tipo
        });
        this.saveParodeMotor(info,tipo);
      }
    });
  }

  parodemotor2(imei:any,ruta:any,tel:any,unidad:any,tipobateria:any,fecha:any,hora:any,latParo:any,lngParo:any,speed:any,tipo:any){
    swal(this.swal)
    .then((willDelete) => {
      if (willDelete) {
        this.smsmotor(imei,tipo);
        this.db.collection('parosdemotor').doc(imei).set({
          imei: imei, 
          ruta: ruta, 
          tipo: tipo
        }); 
        let info = {
          ruta: ruta,
          imei: imei,
          unidad: unidad,
          fecha: fecha,
          hora: hora,
          lat: latParo,
          lng: lngParo,
          speed: speed
        };
        this.saveParodeMotor(info,tipo);
      }
    });
  }

  saveParodeMotor(info:any,tipo:any){
    if(tipo=='apagarmotor'){
    this.loader = true;
    let datasave = {
      pin: this.token.pin,
      ruta: info.ruta,
      imei: info.imei,
      unidad: info.unidad,
      fecha: info.fecha,
      hora: info.hora,
      lat: info.lat,
      lng: info.lng,
      speed: info.speed
    };
    this.getservices.setData('saveParoDeMotor',datasave)
    .subscribe( data => {
      if(data['res']==true){
        this.loader = false;
      }else{
        this.snackBar.open('Save paro de motor Error', null, { duration: 3000 });
        this.loader = false;
      }
    },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
    });
    }
  }

  smsmotor(imei:number,tipo:any){
    let data = {
        imei: imei,
        cmd: tipo
    };
    this.getservices.setData('cmd',data)
      .subscribe( data => {
        if(data['res']==true){
          this.snackBar.open('Paro de motor... Send!!!', null, { duration: 3000 });
        }else{
          this.snackBar.open('Paro de motor... Error!!!', null, { duration: 3000 });
        }
      },err => {
        this.snackBar.open('Error de conexión en el servidor... SMS', null, { duration: 3000 });
      });
  }

  zoomChange(event){
    this.zoom = event;
  }

  seguirUnidad(info:any){
    this.siguemeVar = true;
    this.siguemeUnidad = info['unidad'];
    this.siguemeImei = info['imei'];
    this.centermap(info);
  }

  seguirFuncion(info:any){
    if(this.siguemeVar == true){
      for (var i = 0; i < info.length; ++i) {
        if(info[i]['imei'] == this.siguemeImei){
          this.centermap(info[i]);
        }
      }
    }
  }

  seguirUnidadOff(){
    this.siguemeVar = false;
    this.siguemeUnidad = '';
    this.siguemeImei = '';
    this.map.setCenter({ lat: this.mapa['lat'], lng: this.mapa['lng'] });
    this.zoom = this.mapa['zoom'];
  }

  sizemap(){
    this.map.setCenter({ lat: this.mapa['lat'], lng: this.mapa['lng'] });
    this.zoom = this.mapa['zoom'];
  }

  centermap(info:any){
    this.map.setCenter({ lat: Number(info['lat']), lng: Number(info['lng']) });
    this.zoom = 19;
  }

  onSerch(value: string) { 
    let num = 0;
    let dataPoint:any;

    let valueCheck = value.split(' ');

    for (var i = 0; i < this.unidadeslista.length; ++i) {
      if(valueCheck[1]){
        if(this.unidadeslista[i]['unidad'] === valueCheck[0] && this.unidadeslista[i]['ruta'] === valueCheck[1]){
          num = 1;
          dataPoint = this.unidadeslista[i];
        }
      }else{
        if(this.unidadeslista[i]['unidad'] === valueCheck[0]){
          num ++;
          dataPoint = this.unidadeslista[i];
        }
      }
    }
    if(num === 1){
        this.centermap(dataPoint);
        this.marcket(dataPoint);
    }else if(num > 1){
      this.centermap(dataPoint);
      this.marcket(dataPoint);
      this.snackBar.open('Hay mas de una coincidencia... Se tomo de '+dataPoint['ruta'], null, { duration: 3000 });  
    }else{
      this.snackBar.open('No hay concidencias...', null, { duration: 3000 });  
    }
  }

  js_yyyy_mm_dd_hh_mm_ss () {
    let now = new Date();
    let year = "" + now.getFullYear();
    let month = "" + (now.getMonth() + 1); if (month.length == 1) { month = "0" + month; }
    let day = "" + now.getDate(); if (day.length == 1) { day = "0" + day; }
    let hour = "" + now.getHours(); if (hour.length == 1) { hour = "0" + hour; }
    let minute = "" + now.getMinutes(); if (minute.length == 1) { minute = "0" + minute; }
    let second = "" + now.getSeconds(); if (second.length == 1) { second = "0" + second; }
    return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
  }

  js_yyyy_mm () {
    let now = new Date();
    let year = "" + now.getFullYear();
    let month = "" + (now.getMonth() + 1); if (month.length == 1) { month = "0" + month; }
    let day = "" + now.getDate(); if (day.length == 1) { day = "0" + day; }
    return year + "-" + month + "-" + day;
  }

  streat(info:any){
    let panorama = this.map.getStreetView();
    panorama.setPosition({ lat: Number(info['lat']), lng: Number(info['lng']) });
    panorama.setPov(({
            heading: 340,
            pitch: 0
    }));
    panorama.setVisible(true);
  }

  getStyle() {
    // snip snip -> fetch the url from somewhere
    let height = this.heightMap;
    let style = `height: ${height}px;`;
    
    // sanitize the style expression
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

}
