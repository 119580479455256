import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';

import {
  ScriptService,
  GetserviesService
} from '../app.filesServices';

import { environment } from '../../environments/environment';

declare var $:any;

@Component({
  selector: 'app-posiciones',
  templateUrl: './posiciones.component.html',
  styleUrls: ['./posiciones.component.css']
})
export class PosicionesComponent implements OnInit {

	public map: any;

  	loader:boolean = false;
  	token = JSON.parse(localStorage.getItem("token"));
  	mapa = JSON.parse(localStorage.getItem("mapa"));

    unidad:any;
    direccion:any;
  	fecha:any;
  	hora:any;
  	speed:any;

    lat:any = this.mapa['lat'];
  	lng:any = this.mapa['lng'];
  	zoom:any = 17;

    rutaSearchPosicion:any;
    rutaocliente:any;
    unidadesClientesArray:any = [];

  constructor(
  	public script:ScriptService,
  	public snackBar:MatSnackBar,
  	public http:HttpClient,
  	public getservices:GetserviesService,
  	) {
  	this.script.loadScript();
  }

  public mapReady(map) {
    this.map = map;
  }

  ngOnInit() {
    this.onChangeSelect();
    // trae datos de rutas para select
    this.loader = true;
    let data = {
      pin: this.token.pin
    };
    this.getservices.getData('rutasoclientes',data)
    .subscribe( res => {
      this.rutaocliente = res;
      this.rutaSearchPosicion = this.rutaocliente[0].pin;
      this.datosUnidades();
      if(this.rutaocliente.length == 1){
        $('.rutas').hide();
      }
      this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
    });
  }

  onChangeSelect() {
    $('.rutaSearchPosicion').on("change",()=>{
        this.unidadesClientesArray = [];
        this.rutaSearchPosicion = $(".rutaSearchPosicion").val();
        this.datosUnidades();
    });
  }

  ubicar(ruta:any,unidad:any,imei:any){
  	this.unidad = unidad+' || Ruta: '+this.querutaAirView(ruta);
  	this.loader = true;
    let data = {
      imei: imei
    };
    this.getservices.getData('ultimaposicionporunidad',data)
    .subscribe( res => {
    	this.fecha = res[0]['fecha'];
    	this.hora = res[0]['hora'];
    	this.speed = res[0]['speed'];
    	///
	    this.http.get('https://maps.googleapis.com/maps/api/geocode/json?latlng='+res[0]['lat']+','+res[0]['lng']+'&key='+environment.ubicacion)
	    .subscribe((data) => {
	      if(data['status'] == "OK"){
	        this.direccion = data['results'][2].formatted_address;
	      }else{
	        this.direccion = 'No hay dirección';
	      }
	    });
    	//// centro mapa
    	this.map.setCenter({ lat: Number(res[0]['lat']), lng: Number(res[0]['lng']) });
    	this.lat = res[0]['lat'];
    	this.lng = res[0]['lng'];
    	this.zoom = 19;
    	///
        this.loader = false;
        ////
        $("#modal-posicionultima").modal("show");
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
      });
  }

  datosUnidades() {
    this.loader = true;
    let data = {
      pin: this.token.pin,
      ruta: this.rutaSearchPosicion
    };
    this.getservices.getData('equiposConFiltro',data)
    .subscribe( res => {
        this.unidadesClientesArray = res;
        this.snackBar.open('Busqueda correcta - '+this.querutaAirView(this.rutaSearchPosicion), null, { duration: 3000 });
        this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
      });
  }

  querutaAirView(id){
    for (var i = 0; i < this.rutaocliente.length; ++i) {
      if(this.rutaocliente[i].pin == id){
        return this.rutaocliente[i].airview;
      }
    }
  }

}	