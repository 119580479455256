import { Component, OnInit } from '@angular/core';
import { DomSanitizer  } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';

import {
  ScriptService,
  GetserviesService
} from '../app.filesServices';

declare var $:any;
declare var moment:any;

@Component({
  selector: 'app-georeport',
  templateUrl: './georeport.component.html'
})
export class GeoreportComponent implements OnInit {

	loader:boolean = false;
	token = JSON.parse(localStorage.getItem("token"));
	heightMap:any = window.screen.height - 250;

  geocercaslist:any;
  rutaocliente:any;
  ruta:any;
  geocerca:any;

  datageocercas:any;

  unidadesList:any;

  tiemposcalculo:any;
  tiemposcalculoOK:any;

  dateReport:any;
  timeInicio:any;
  timeFin:any;

  reporte:boolean = false;

  numrep:number = 0;

  selectedDevice:any = 'Filtro';

  constructor(
    public script:ScriptService,
    public getservices:GetserviesService,
  	private sanitizer: DomSanitizer,
    public snackBar:MatSnackBar
  ) { 
    this.dateReport = this.fechaHoy();
    this.timeInicio = '00:00';
    this.timeFin = '23:59';
  	this.script.loadScript();
  }

  ngOnInit() {
    this.rutas();
  }

  unidades(){
    //// unidades segun  ruta
    this.loader = true;
    let data = {
      ruta: this.ruta,
      pin: this.token.pin
    };
    this.getservices.getData('unidadesNombreFiltro',data)
    .subscribe( res => {
      this.unidadesList = res;
    },err => {
        this.snackBar.open('Error de conexión en el servidor. Hoy', null, { duration: 3000 });
    });
    this.loader = false;
  }

  rutas(){
    // trae datos de rutas para select
      this.loader = true;
      let data = {
        pin: this.token.pin
      };
      this.getservices.getData('rutasoclientes',data)
      .subscribe( res => {
        this.rutaocliente = res;
        this.ruta = this.rutaocliente[0].pin;
        this.geocercas(this.ruta);
        this.loader = false;
        },err => {
          this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
          this.loader = false;
      });
  }

  onChange(id:any) {
      this.geocercas(id);
  }

  hidden(dato){
    $('.dinamic').hide();
    $('.'+dato).show();
  }

  onChangefiltro(newValue:any) {
    if(newValue != 'Filtro'){
      this.hidden(newValue);
    }else{
      $('.dinamic').show();
    }
  }

  geocercas(id:any){
    // trae geocercas
    this.loader = true;
    let data = {
      pin: id
    };
    this.getservices.getData('geocercasreport',data)
    .subscribe( res => {
      this.geocercaslist = res;
      if(this.geocercaslist.length){
        this.geocerca = this.geocercaslist[0].id;
      }else{
        this.snackBar.open('No tiene Geocercas...', null, { duration: 3000 });
      }
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
    });
    this.loader = false;
  }

  consultaGeocercas(){
    this.selectedDevice = 'Filtro';
    this.unidades();
    this.timeInicio = $(".horaInicio").val();
    this.timeFin = $(".horaFin").val();
    this.dateReport = $(".dateReport").val();
    // trae geocercas
    this.loader = true;
    let data = {
      pin: this.token.pin,
      ruta: this.ruta,
      geocerca: this.geocerca,
      timeInicio: this.timeInicio,
      timeFin: this.timeFin,
      dateReport: this.dateReport
    };
    this.getservices.getData('geocercasevents',data)
    .subscribe( data => {
      this.datageocercas = data;
      this.tiemposcalculo = data;
      this.numrep = this.datageocercas.length;
      if(this.datageocercas.length == 0){
        this.snackBar.open('No hay datos de Geocercas...', null, { duration: 3000 });
      }
      this.reporte = true;
      this.loader = false;
      },err => {
        this.loader = false;
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
    });
  }

  calculartiempos(){

   
    var temporaldetiempos = [];   

    for (var i = 0; i < this.tiemposcalculo.length; ++i) {
      var nom = 0;
      if(this.tiemposcalculo.length == i+1){
        if(this.tiemposcalculo[i].unidad == this.tiemposcalculo[i-1].unidad){
          nom = 2;
          //console.log(this.tiemposcalculo[i].unidad+' '+this.tiemposcalculo[i].fecha+' '+this.tiemposcalculo[i].hora+' '+nom);
          temporaldetiempos.push([this.tiemposcalculo[i].unidad, this.tiemposcalculo[i].fecha, this.tiemposcalculo[i].hora, nom]);
        }else{
          nom += 1;
          //console.log(this.tiemposcalculo[i].unidad+' '+this.tiemposcalculo[i].fecha+' '+this.tiemposcalculo[i].hora+' '+nom);
          temporaldetiempos.push([this.tiemposcalculo[i].unidad, this.tiemposcalculo[i].fecha, this.tiemposcalculo[i].hora, nom]);
        }
      }else{
        if(this.tiemposcalculo[i].unidad == this.tiemposcalculo[i+1].unidad){
          nom += 1;
          //console.log(this.tiemposcalculo[i].unidad+' '+this.tiemposcalculo[i].fecha+' '+this.tiemposcalculo[i].hora+' '+nom);
          temporaldetiempos.push([this.tiemposcalculo[i].unidad, this.tiemposcalculo[i].fecha, this.tiemposcalculo[i].hora, nom]);
        }else{
          if(i-1 > 0){
          if(this.tiemposcalculo[i].unidad == this.tiemposcalculo[i-1].unidad){
            nom = 2;
            //console.log(this.tiemposcalculo[i].unidad+' '+this.tiemposcalculo[i].fecha+' '+this.tiemposcalculo[i].hora+' '+nom);
            temporaldetiempos.push([this.tiemposcalculo[i].unidad, this.tiemposcalculo[i].fecha, this.tiemposcalculo[i].hora, nom]);
          }else{
            nom = 0;
            //console.log(this.tiemposcalculo[i].unidad+' '+this.tiemposcalculo[i].fecha+' '+this.tiemposcalculo[i].hora+' '+nom);
            temporaldetiempos.push([this.tiemposcalculo[i].unidad, this.tiemposcalculo[i].fecha, this.tiemposcalculo[i].hora, nom]);
          }
          }else{
            nom = 2;
            //console.log(this.tiemposcalculo[i].unidad+' '+this.tiemposcalculo[i].fecha+' '+this.tiemposcalculo[i].hora+' '+nom);
            temporaldetiempos.push([this.tiemposcalculo[i].unidad, this.tiemposcalculo[i].fecha, this.tiemposcalculo[i].hora, nom]);
          }
        }
      }
    }

    let temporaldetiempos2 = [];

    for( var i = 0; i < temporaldetiempos.length; i++){ 
      if(temporaldetiempos[i][3] != 0){
        temporaldetiempos2.push(temporaldetiempos[i]);
      }
    }


    let temporaldetiempos3 = [];
    var numeroConta = 1;
    for( var i = 0; i < temporaldetiempos2.length; i++){ 

      if(numeroConta > 2){numeroConta = 1}

      if(numeroConta == temporaldetiempos2[i][3]){
        numeroConta = numeroConta + 1;
        temporaldetiempos3.push(temporaldetiempos2[i]);
      }

    }


    this.tiemposcalculoOK = [];

    for (var i = 0; i < temporaldetiempos3.length; ++i) {
      if(temporaldetiempos3[i][3] == 1 && temporaldetiempos3.length > i){
        //console.log(temporaldetiempos3[i][0] +' '+ temporaldetiempos3[i][1] +' '+ temporaldetiempos3[i][2] +' '+ temporaldetiempos3[i+1][2] +' '+ this.chechhora(temporaldetiempos3[i][1],temporaldetiempos3[i][2],temporaldetiempos3[i+1][2]));
        this.tiemposcalculoOK.push({unidad: temporaldetiempos3[i][0], fecha: temporaldetiempos3[i][1], hora1: temporaldetiempos3[i][2], hora2: temporaldetiempos3[i+1][2], tiempo: this.chechhora(temporaldetiempos3[i][1],temporaldetiempos3[i][2],temporaldetiempos3[i+1][2]) });
      }
    }

  }

  chechhora(ano,hora1,hora2){
    return moment.utc(moment(ano+' '+hora2,"YYYY-MM-DD HH:mm:ss").diff(moment(ano+' '+hora1,"YYYY-MM-DD HH:mm:ss"))).format("HH:mm:ss");
  }

  fechaHoy(){
    let now = new Date();
    let year = "" + now.getFullYear();
    let month = "" + (now.getMonth() + 1); if (month.length == 1) { month = "0" + month; }
    let day = "" + now.getDate(); if (day.length == 1) { day = "0" + day; }
    return year + "-" + month + "-" + day;
  }

  getStyle() {
    // snip snip -> fetch the url from somewhere
    let height = this.heightMap;
    let style = `height: ${height}px;`;
    
    // sanitize the style expression
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

}
