import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import swal from 'sweetalert';
import { AngularFirestore } from '@angular/fire/firestore';

import {
  ScriptService,
  GetserviesService
} from '../app.filesServices';

declare var $:any;

@Component({
  selector: 'app-videosolicitud',
  templateUrl: './videosolicitud.component.html',
  styleUrls: ['./videosolicitud.component.css']
})
export class VideosolicitudComponent implements OnInit {

	loader:boolean = false;
	token = JSON.parse(localStorage.getItem("token"));

	dataUserAgent:any;

	tablaLista:any = false;

	tipo:any = 'asalto';
	unidad:any = '';
	ruta:any = '';
	diav:any = '';
	mesv:any = '';
	entre:any = '15:40 y 15:45';
	nombre:any = this.token['nombre'];
  descripcionubicacion:any = '';
  cel:any = '';
  correo:any = '';

	clienteslista:any;
	unidadesList:any;

	listahistorialUser:any;

  apagarsolicitud:any = false;

	icon:any = {icon: "success"};

  meses:any = [];

  acepto:any = false;

  horasVideoTitulo:any = '';
  costo:any = '';

  constructor(
  	public script:ScriptService,
  	public snackBar:MatSnackBar,
  	public getservices:GetserviesService,
  	private router:Router,
    private activatedRoute: ActivatedRoute,
    private db: AngularFirestore
  	) {
  	this.script.loadScript();
  }

  ngOnInit() {
    ///
  	this.traerHistorial();
  	// trae datos de rutas para traer alertas
    let data = {
      pin: this.token.pin
    };
    this.getservices.getData('rutasoclientes',data)
    .subscribe( (res:any[]) => {
      this.clienteslista = res;
      this.ruta = this.clienteslista[0].nombre_videos;
      this.unidades(this.clienteslista[0].nombre_videos);
      ///
    },err => {
      this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });  
    });
  	// 
    this.loader = true;
    this.getservices.getData('useragent',{})
    .subscribe( res => {
      this.dataUserAgent = res;
      this.dataUserAgent = eval(this.dataUserAgent);
      this.queMes();
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
    });
    this.loader = false;
  }

  queMes(){
    let elMes = this.dataUserAgent['mes'];
    if(elMes == '12'){
      this.mesv = 'diciembre';
      this.meses = [
      {mes: 'noviembre', mesM: 'Noviembre'},
      {mes: 'diciembre', mesM: 'Diciembre'}
      ];
    }else if(elMes == '11'){
      this.mesv = 'noviembre';
      this.meses = [
      {mes: 'octubre', mesM: 'Octubre'},
      {mes: 'noviembre', mesM: 'Noviembre'}
      ];
    }else if(elMes == '10'){
      this.mesv = 'octubre';
      this.meses = [
      {mes: 'septiembre', mesM: 'Septiembre'},
      {mes: 'octubre', mesM: 'Octubre'}
      ];
    }else if(elMes == '09'){
      this.mesv = 'septiembre';
      this.meses = [
      {mes: 'agosto', mesM: 'Agosto'},
      {mes: 'septiembre', mesM: 'Septiembre'}
      ];
    }else if(elMes == '08'){
      this.mesv = 'agosto';
      this.meses = [
      {mes: 'julio', mesM: 'Julio'},
      {mes: 'agosto', mesM: 'Agosto'}
      ];
    }else if(elMes == '07'){
      this.mesv = 'julio';
      this.meses = [
      {mes: 'junio', mesM: 'Junio'},
      {mes: 'julio', mesM: 'Julio'}
      ];
    }else if(elMes == '06'){
      this.mesv = 'junio';
      this.meses = [
      {mes: 'mayo', mesM: 'Mayo'},
      {mes: 'junio', mesM: 'Junio'}
      ];
    }else if(elMes == '05'){
      this.mesv = 'mayo';
      this.meses = [
      {mes: 'abril', mesM: 'Abril'},
      {mes: 'mayo', mesM: 'Mayo'}
      ];
    }else if(elMes == '04'){
      this.mesv = 'abril';
      this.meses = [
      {mes: 'marzo', mesM: 'Marzo'},
      {mes: 'abril', mesM: 'Abril'}
      ];
    }else if(elMes == '03'){
      this.mesv = 'marzo';
      this.meses = [
      {mes: 'febrero', mesM: 'Febrero'},
      {mes: 'marzo', mesM: 'Marzo'}
      ];
    }else if(elMes == '02'){
      this.mesv = 'febrero';
      this.meses = [
      {mes: 'enero', mesM: 'Enero'},
      {mes: 'febrero', mesM: 'Febrero'}
      ];
    }else if(elMes == '01'){
      this.mesv = 'enero';
      this.meses = [
      {mes: 'diciembre', mesM: 'Diciembre'},
      {mes: 'enero', mesM: 'Enero'}
      ];
    }
  }

  modelChangeFn(val:any){
    if(val == '5horas'){
      this.horasVideoTitulo = '5 Horas máximo de video';
      this.costo = 'Descarga de 5 hrs.   $350.00';
      $("#modal-horasVideo").modal("show");
    }else if(val == '10horas'){
      this.horasVideoTitulo = '10 Horas máximo de video';
      this.costo = 'Descarga de 10 hrs.  $650.00';
      $("#modal-horasVideo").modal("show");
    }else if(val == '16horas'){
      this.horasVideoTitulo = '16 Horas máximo de video';
      this.costo = 'Descarga de 16hrs.  $850.00';
      $("#modal-horasVideo").modal("show");
    }
  }

  traerHistorial(){
  	// trae datos de alestas hechas
    let datah = {
      pin: this.token.pin
    };
    this.getservices.getData('solicitudVideosUser',datah)
    .subscribe( (res:any[]) => {
      this.listahistorialUser = res;
      if(this.listahistorialUser.length > 0){
      	this.tablaLista = true;
      }
      ///
    },err => {
      this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });  
    });
  }

  onChange(rutaID:any) {
    this.unidades(rutaID);
  }

  unidades(ruta:any){
    this.loader = true;
    let data = {
      ruta: ruta,
      pin: this.token.pin
    };
    this.getservices.getData('unidadesNombreRutaFiltroVideos',data)
    .subscribe( res => {
      this.unidadesList = res;
      this.unidad = this.unidadesList[0].unidad;
      this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
    });
  }

  solicitud(){
  	if(this.tipo && this.unidad && this.ruta && this.diav && this.mesv && this.entre && this.nombre && this.descripcionubicacion && this.cel){
      this.apagarsolicitud = true;
  		let clave = this.dataUserAgent.ano+this.dataUserAgent.mes+this.dataUserAgent.dia+this.dataUserAgent.hora+this.ruta+this.unidad;
  		this.db.collection('videosolicitud').doc(clave).set({
  		  clave: clave,
	      solicitud: $('.texto').text(), 
	      tipo: this.tipo, 
	      unidad: this.unidad,
	      ruta: this.ruta,
        cel: this.cel,
        correo: this.correo,
        descripcionubicacion: this.descripcionubicacion
	    });
	    this.sendSolicitud();
	    swal("!Solicitud enviada correctamente¡", this.icon);
  	}else{
  		this.snackBar.open('¡Algún campo está vacío! No se puede hacer la solicitud...', null, { duration: 3000 });
  	}
  }

  sendSolicitud(){
  	this.loader = true;
  	let datasave = {
  		ip: this.dataUserAgent['ip'],
  		nav: this.dataUserAgent['nav'],
  		fechahora: this.dataUserAgent['ano']+'-'+this.dataUserAgent['mes']+'-'+this.dataUserAgent['dia']+' '+this.dataUserAgent['hora']+':00',
  		solicitud: $('.texto').text(), 
	    tipo: this.tipo, 
	    unidad: this.unidad,
	    ruta: this.ruta,
	    diav: this.diav,
	    mesv: this.mesv,
	    entre: this.entre,
	    responsable: this.nombre,
	    pin: this.token['pin'],
      cel: this.cel,
      correo: this.correo,
      descripcionubicacion: this.descripcionubicacion
    };
	this.getservices.setData('solicitudVideos',datasave)
	.subscribe( data => {
	  if(data['res']==true){
	  	this.snackBar.open('!Solicitud enviada correctamente¡', null, { duration: 3000 });
	  	this.traerHistorial();
	  	this.loader = false;
	  }else{
	    this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
	    this.loader = false;
	  }
	},err => {
	    this.snackBar.open('Error de conexión en el servidor. RESPUESTA', null, { duration: 3000 });
		this.loader = false;
	});
  }

}
