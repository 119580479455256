import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calculocobro'
})
export class CalculocobroPipe implements PipeTransform {

  transform(value: any, args?: any): any {
	let porcentajeestudiante = args[3];
    let porcentajenormal = (100 - porcentajeestudiante);
    let pasajeros:any = value;
    let totalestudiante = ((pasajeros * porcentajeestudiante) / 100) * args[2];
    let normal = ((pasajeros * porcentajenormal) / 100) * args[1];
    let importe = totalestudiante+normal;
    let totalcombustible = args[0];
    return importe - totalcombustible;
  }

}
