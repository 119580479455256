import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';

import swal from 'sweetalert';

import {
  ScriptService,
  GetserviesService
} from '../app.filesServices';

declare var $:any;

@Component({
  selector: 'app-geocercasmaster',
  templateUrl: './geocercasmaster.component.html',
})
export class GeocercasmasterComponent implements OnInit {

	loader:boolean = false;

	token = JSON.parse(localStorage.getItem("token"));
	params:any = { buttons: false, timer: 2000 };

	geoList:any;
	userId:any;
	listUser:any;
  unidades:any;

  listGeoView:boolean = true;
  geocercaNombre:any;
  rutaNombre:any;
  geoID:any;
  rutaID:any;

  swal:any = {
      text: "Se asignaran todas las unidades a cada Geocerca",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    };
  icon:any = {icon: "success"};

  constructor(
  	public script:ScriptService,
  	public snackBar:MatSnackBar,
  	public getservices:GetserviesService,
    private router: Router, 
  	) {
  	this.script.loadScript();
    if(this.token['modouser'] != 'sistemas'){
      this.router.navigate(['reload','mapa']);
    }
  }

  ngOnInit() {
    this.loader = true;
    let data = {};
    this.getservices.getData('usersGeos',data)
    .subscribe( res => {
      this.listUser = res;
      this.userId = this.listUser[0].user_id;
      this.geocercasGetMaster(this.userId);
      this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
    });
  }

  asignartodas(){
    this.swal['title'] = "¿Seguro que quiere relacionar la ruta "+this.geoList[0]['rutas'][0]['nombre']+"?";
    swal(this.swal)
    .then((willDelete) => {
      if (willDelete) {
        for (var i = 0; i < this.geoList.length; ++i) {
          this.loader = true;
          let loteData = {
            geofence: this.geoList[i]['id'],
            routesorclients_id: this.geoList[0]['rutas'][0]['routesorclients_id']
          };
          this.getservices.setData('saveRutaGeocercasUnidadesLote',loteData)
          .subscribe( data => {
            this.listGeoView = true; 
            if(data['res']!=true){
              this.snackBar.open('Error (Save Lote Unidades Geocercas)', null, { duration: 3000 });
              this.loader = false;
            }
          },err => {
              this.snackBar.open('Error de conexión en el servidor (Save Lote Unidades Geocercas)', null, { duration: 3000 });
              this.loader = false;
          });
        }
        this.loader = false;
        swal("!Datos Asignados", this.icon);
      }
    });
  }

  onChange(IDuser:any) {
  	this.geocercasGetMaster(IDuser);
  }

  geocercasGetMaster(IDuser:any){
  	this.loader = true;
    let data = {
      userId: IDuser
    };
    this.getservices.getData('geocercasGetMaster',data)
    .subscribe( res => {
    	if(res['res'] == true){
    		this.geoList = res['data'];
    	}else{
    		this.snackBar.open('Error de identificador...', null, { duration: 3000 });
    	}
      	this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
    });
  }

  unidadesRelacion(id,idGeo,nombre,ruta){
    this.geocercaNombre = nombre;
    this.rutaNombre = ruta;
    this.listGeoView = false;
    this.geoID = idGeo;
    this.rutaID = id;
    this.loader = true;
    let data = {
      ruta:id,
      geo:idGeo
    };
    this.getservices.getData('unidadesGetMaster',data)
    .subscribe( res => {
        this.unidades = res;
        this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
    });
  }

  asociarSaveFull(idGeo,idRuta){
    $(".rutaUnidades > option").prop("selected","selected");
    $(".rutaUnidades").trigger("change");
    this.asociarSave(idGeo,idRuta,false);
  }

  asociarSave(idGeo,idRuta,unidadesLimpia){
    let relacionUnidades;
    if(unidadesLimpia == true){
      relacionUnidades = '';
    }else{
      relacionUnidades = $(".rutaUnidades").val();
    }
    if(!relacionUnidades.length && unidadesLimpia == false){
      this.snackBar.open('Los campos estan vacios... Geocerca sin relación.', null, { duration: 3000 });
    }else{
      this.loader = true;
      let datasave = {
        geofence: idGeo,
        routesorclients_id: idRuta,
        unidades: relacionUnidades
      };
      this.getservices.setData('geocercasAsociacionEquiposSave',datasave)
      .subscribe( data => {
        this.listGeoView = true; 
        if(data['res']==true){
          swal("¡Datos  guardados!", this.params );
          this.loader = false;
        }else{
          this.snackBar.open('Error de conexión en el servidor 1', null, { duration: 3000 });
          this.loader = false;
        }
      },err => {
          this.snackBar.open('Error de conexión en el servidor 2', null, { duration: 3000 });
          this.loader = false;
      });
    }
  }

  regresar(){
   this.listGeoView = true; 
  }

}
