import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScriptService {

  constructor() { }

  loadScript() {
      let node = document.createElement('script');
      node.src = '/assets/js/codebase.js';
      node.type = 'text/javascript';
      document.getElementsByTagName('head')[0].appendChild(node);
      return true;
  }
}
