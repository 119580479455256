import { ElementRef, Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MapsAPILoader } from '@agm/core';
import { AgmMap, AgmPolyline } from '@agm/core';

import { DomSanitizer  } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import swal from 'sweetalert';

import {
  ScriptService,
  GetserviesService,
  GetPathCoordsService
} from '../app.filesServices';

import { CONST } from '../app.const';

declare var google:any;

@Component({
  selector: 'app-ruta',
  templateUrl: './ruta.component.html'
})
export class RutaComponent implements OnInit {
  @ViewChild(AgmMap) map: any;
  @ViewChild("search") searchElementRef: ElementRef;

  loader:boolean = false;
  
  public mapseting: any;
  public mapReady(mapseting) {
      this.mapseting = mapseting;
  }

  token = JSON.parse(localStorage.getItem("token"));
  mapa = JSON.parse(localStorage.getItem("mapa"));

  zoom:number;
  params:any = { buttons: false, timer: 2000 }; 
  swal:any = {
      title: "¿Seguro que quiere eliminar el registro?",
      text: "No se podra recuperar el registro",
      icon: "warning",
      buttons: true,
      dangerMode: true,
  };
  icon:any = {icon: "success"};

  rutaocliente:any;

  editruta:any;

	heightMap:any = window.screen.height - 250;

  ///data post
  ruta:any;
  arreglo:Array<any> = [];

  /// polyline
  polyline:any;
  getPathData:any;
  guardar:boolean = false;
  bottonline:boolean = true;
  tiporuta:string;

  //font
  unidadesYrutas:any;

  constructor(
    private router:Router,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    public script:ScriptService,
    public snackBar:MatSnackBar,
    private _loader: MapsAPILoader,
    private _zone: NgZone,
    public getservices:GetserviesService,
    public getpathcoords:GetPathCoordsService) {
    this.script.loadScript();
    this.activatedRoute.params.subscribe( params =>{
      this.editruta = params['id'];
    });
  }

  ngOnInit() {
    // trae datos de rutas para select
    this.loader = true;
    let data = {
      pin: this.token.pin
    };
    this.getservices.getData('rutasoclientes',data)
    .subscribe( res => {
      //console.log(res);
      this.rutaocliente = res;
      this.ruta = this.rutaocliente[0].pin;
      this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
    });
    //// traer posicion por direccion
    this._loader.load().then(() => {
        let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {});
        google.maps.event.addListener(autocomplete, 'place_changed', () => {
            this.loader = true;
            this._zone.run(() => {
              let place = autocomplete.getPlace();

              this.mapseting.setCenter({ lat: Number(place.geometry.location.lat()), lng: Number(place.geometry.location.lng()) });
              this.zoom = 16;
              //console.log(place);
              this.loader = false;
            });
        });
    });
    ////
    this.getDataRuta();
  }

  agregar_line(color,tipo){
    this.guardar = true;
    this.bottonline = false;
    this.tiporuta = tipo;
    let center = this.mapseting.getCenter();            
    let mzoom = this.mapseting.getZoom();
    if (mzoom==0)
    mzoom = 1;
    let init_point = 10000/(mzoom*mzoom*mzoom*mzoom*mzoom*mzoom);
    let path = [
        {lat: center.lat()-init_point, lng: center.lng()-init_point},
        {lat: center.lat()+init_point, lng: center.lng()+init_point}
    ];

    this.map._mapsWrapper.createPolyline({
      clickable: true,
      draggable: true,
      editable: true,
      strokeColor: color,
      strokeOpacity: 0.6,
      strokeWeight: 4,
      visible: true,
      path: path
    }).then((polyline: any) => {
      this.polyline = polyline;
    });
  }

  getPath() {
    let count = this.polyline.getPath().length;
    this.getPathData = this.polyline.getPath().getArray().toString();
    let tra = this.getpathcoords.getPathFromCoordsArray(this.getPathData);
    for (var i = count - 1; i >= 0; i--) {
      this.arreglo.push({ lat: tra[i].lat(), lng: tra[i].lng() });//creates a point now want to add draw path 
    }
    //// guardar datos
    this.loader = true;
      let data = {
        rutaocliente: this.ruta,
        tipo: this.tiporuta,
        coords: JSON.stringify(this.arreglo)
      };
      this.getservices.setData('maps_routesSave',data)
      .subscribe( data => {
          if(data['res']==true){
            swal("¡Parametros guardados!", this.params );
            this.arreglo = [];
            this.polyline.setMap(null);
            this.getDataRuta();
          }else if(data['res'] == false && data['exist'] == true) {
            this.snackBar.open('Ya existe una ruta en esa direccíon', null, { duration: 3000 });
            this.guardar = true;
            this.bottonline = false;
            return;
          }else{
            this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
          }
        },err => {
          this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        });
    this.loader = false;
    this.guardar = false;
    this.bottonline = true;
    ///
  }

  getPathEdit() {
    let count = this.polyline.getPath().length;
    this.getPathData = this.polyline.getPath().getArray().toString();
    let tra = this.getpathcoords.getPathFromCoordsArray(this.getPathData);
    for (var i = count - 1; i >= 0; i--) {
      this.arreglo.push({ lat: tra[i].lat(), lng: tra[i].lng() });//creates a point now want to add draw path 
    }
    //// guardar datos
    this.loader = true;
      let data = {
        id: this.editruta,
        coords: JSON.stringify(this.arreglo)
      };
    this.getservices.setData('maps_routesUpdate',data)
    .subscribe( data => {
        if(data['res']==true){
          swal("¡Parametros guardados!", this.params );
          this.loader = false;
          this.router.navigate(['ruta']);
        }else{
          this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        }
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
      });
    this.loader = false;
  }


  getDataRuta(){
    this.loader = true;
    let data = {
      pin: this.token.pin
    };
    this.getservices.getData('mapsDataroutes',data)
    .subscribe( res => {
        //console.log(res);
        this.loader = false;
        this.unidadesYrutas = res;
        for (var i = 0; i < this.unidadesYrutas.length; ++i) {
          //console.log(this.unidadesYrutas[i].ruta_ida[0].id);
          //console.log(this.unidadesYrutas[i].ruta_ida[0].coords);
          //console.log(this.unidadesYrutas[i].ruta_ida[0]);
          this.crearPolylineas(this.unidadesYrutas[i].ruta_ida[0]);
          this.crearPolylineas(this.unidadesYrutas[i].ruta_regreso[0]);
        }
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
    });
  }

  crearPolylineas(dataPath:any){
    if(dataPath){
      let queColor;
      if (dataPath.tipo == 'ida') { 
        queColor = '#FF0000';
      } else {
        queColor = '#1919FF';
      }
      let editableono;
      if(this.editruta == dataPath.id){
        editableono = true;
      } else {
        editableono = false;
      }
      this.map._mapsWrapper.createPolyline({
        clickable: editableono,
        draggable: editableono,
        editable: editableono,
        strokeColor: queColor,
        strokeOpacity: 0.6,
        strokeWeight: 4,
        visible: true,
        path: eval(dataPath.coords)
      }).then((polyline: any) => {
        if(this.editruta == dataPath.id){
          this.polyline = polyline;
        }
      });
    }
  }

  deleteLine(id:any){
    swal(this.swal)
    .then((willDelete) => {
      if (willDelete) {
        this.loader = true;
        let data = {
          id: id
        };
        this.getservices.setData('maps_routesDelite',data)
        .subscribe( res => {
            if(res['res']==true){
              swal("!Unidad eliminada¡", this.params);
              this.loader = false;
              this.router.navigate(['reload','ruta']);
            }else{
              this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
              this.loader = false;
            }
            },err => {
              this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
              this.loader = false;
            });
      }
    });
  }

  rutaedit(id:any){
    this.router.navigate(['reloadid','rutaedit',id]);
  }

  setCurrentPosition() {
    if ("geolocation" in navigator) {
      this.loader = true;
      this.zoom = 10;
      navigator.geolocation.getCurrentPosition((position) => {
        this.mapseting.setCenter({ lat: Number(position.coords.latitude), lng: Number(position.coords.longitude) });
        this.zoom = 16;
        this.loader = false;
      });
    }
  }

  getStyle() {
    // snip snip -> fetch the url from somewhere
    let height = this.heightMap;
    let style = `height: ${height}px;`;
    
    // sanitize the style expression
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }
}
