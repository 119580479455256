import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer  } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import {
  ScriptService,
  GetserviesService
} from '../app.filesServices';

declare var $:any;
declare var Codebase:any;

@Component({
  selector: 'app-contadores',
  templateUrl: './contadores.component.html',
  styleUrls: ['./contadores.component.css']
})
export class ContadoresComponent implements OnInit {

	loader:boolean = false;
	token = JSON.parse(localStorage.getItem("token"));

	rutasContadores:any = [];
  rutaDetallesLista:any = [];
  rutaDetallesListaBloqueos:any = 0;
  rutaDetallesListatotalPasaje:any = 0;
  rutaDetallesListatotalPasajem:any = 0;
  rutaDetallesListatotalPasajet:any = 0;
  rutaDetallesListatotalPasajen:any = 0;
  rutaDetallesListatotalSubenm:any = 0;
  rutaDetallesListatotalSubent:any = 0;
  rutaDetallesListatotalSubenn:any = 0;
  rutaDetallesListatotalBajanm:any = 0;
  rutaDetallesListatotalBajant:any = 0;
  rutaDetallesListatotalBajann:any = 0;
  promedio:any = 0;

  rutaDetallesListaRangos:any = 0;
  rutaDetallesListaBloqueosRangos:any = 0;
  rutaDetallesListatotalPasajeRangos:any = 0;
  promedioRangos:any = 0;

  contadores:any = [];

  totalruta:any = 0;
  totalunidades:any = 0;

  modoSistemas:any = false;
  modoUsiario:any = false;
  detalleRuta:any = false;

  modoUser:any = 'normal';

  ruta:any;
  detalleFecha:any;
  detalleFechaInicio:any;
  detalleFechaFin:any;
 
  constructor(
    public snackBar:MatSnackBar,
  	private router:Router,
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    public script:ScriptService,
    public getservices:GetserviesService
    ) {
  	this.script.loadScript();
    this.detalleFecha = this.fechaAyer();
    this.detalleFechaInicio = this.diasmenos(7);
    this.detalleFechaFin = this.fechaAyer();
  }

  ngOnInit() {
    this.modoUser = this.token['modouser'];
    ///  checo que modo de usuario es para saber que mostrar
    if (this.token['modouser'] == 'sistemas') {
      this.getSistemas();
    }else{ // usuario normal 
      this.getUsuario();
    } 
  }

  getSistemas(){
    this.limpiar();
    this.modoSistemas = true;
    //// request
    this.loader = true;
      let data = {};
      this.getservices.getData('rutasContadoresLista',data)
      .subscribe( res => {
        this.rutasContadores = res;
        this.counttotal();
      },err => {
          this.snackBar.open('Error de conexión en el servidor. Hoy', null, { duration: 3000 });
      });
    this.loader = false;
  }

  getRuta(ruta:any){
    this.modoSistemas = false;
    this.modoUsiario = true;
    this.loader = true;
    this.contadores = [];
    this.getservices.getData('contadoresRuta',{ruta: ruta})
    .subscribe( res => {
        this.contadores = res;
        this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
      });
  }

  reporteRuta(ruta:any,fecha:any){
    this.limpiar();
    this.ruta = ruta;
    this.detalleRuta = true;
    this.loader = true;
    this.rutaDetallesLista = [];
    this.rutaDetallesListaBloqueos = 0;
    this.rutaDetallesListatotalPasaje = 0;
    this.rutaDetallesListatotalPasajem = 0;
    this.rutaDetallesListatotalPasajet = 0;
    this.rutaDetallesListatotalPasajen = 0;
    this.rutaDetallesListatotalSubenm = 0;
    this.rutaDetallesListatotalSubent = 0;
    this.rutaDetallesListatotalSubenn = 0;
    this.rutaDetallesListatotalBajanm = 0;
    this.rutaDetallesListatotalBajant = 0;
    this.rutaDetallesListatotalBajann = 0;
    this.promedio = 0;
    this.getservices.getData('contadoresRutaDetalle',{ruta: ruta, fecha: fecha})
    .subscribe( res => {
        this.rutaDetallesLista = res['data'];
        this.rutaDetallesListaBloqueos = res['bloqueos'];
        this.rutaDetallesListatotalPasaje = res['totalsuma'];
        this.rutaDetallesListatotalPasajem = res['totalsumam'];
        this.rutaDetallesListatotalPasajet = res['totalsumat'];
        this.rutaDetallesListatotalPasajen = res['totalsuman'];
        this.rutaDetallesListatotalSubenm = res['totalsubenm'];
        this.rutaDetallesListatotalSubent = res['totalsubent'];
        this.rutaDetallesListatotalSubenn = res['totalsubenn'];
        this.rutaDetallesListatotalBajanm = res['totalbajanm'];
        this.rutaDetallesListatotalBajant = res['totalbajant'];
        this.rutaDetallesListatotalBajann = res['totalbajann'];
        this.promedio = res['promedio'];
        this.loader = false;
        this.reporteRutaRangos($(".detalleFechaInicio").val(),$(".detalleFechaFin").val());
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
        this.reporteRutaRangos($(".detalleFechaInicio").val(),$(".detalleFechaFin").val());
      });
    $(function () {
      Codebase.helpers(['datepicker']);
    });
  }

  reporteRutaFecha(){
    this.reporteRuta(this.ruta,$(".detalleFecha").val());
  }

  reporteRutaRangosBoton(){
    this.reporteRutaRangos($(".detalleFechaInicio").val(),$(".detalleFechaFin").val());
  }

  reporteRutaRangos(inicio:any,fin:any){
    this.loader = true;
    this.rutaDetallesListaRangos = [];
    this.rutaDetallesListaBloqueosRangos = 0;
    this.rutaDetallesListatotalPasajeRangos = 0;
    this.promedioRangos = 0;
    this.getservices.getData('contadoresRutaDetalleRango',{ruta: this.ruta, fechaInicial: inicio, fechaFinal: fin})
    .subscribe( res => {
        this.rutaDetallesListaRangos = res['data'];
        this.rutaDetallesListaBloqueosRangos = res['bloqueos'];
        this.rutaDetallesListatotalPasajeRangos = res['totalsuma'];
        this.promedioRangos = res['promedio'];
        this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
      });
  }

  getUsuario(){
    this.modoUsiario = true;
    this.loader = true;
    this.getservices.getData('contadoresUser',{pin: this.token['pin']})
    .subscribe( res => {
      this.contadores = res;
        this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
      });
  }

  counttotal(){
    let totalU = 0;
    for (var i = this.rutasContadores.length - 1; i >= 0; i--) {
      totalU += this.rutasContadores[i]['unidades'];
    }
    this.totalruta = this.rutasContadores.length;
    this.totalunidades = totalU;

  }

  limpiar(){
    this.detalleRuta = false;
    this.modoUsiario = false;
    this.modoSistemas = false;
  }

  fechaHoy(){
    let now = new Date();
    let year = "" + now.getFullYear();
    let month = "" + (now.getMonth() + 1); if (month.length == 1) { month = "0" + month; }
    let day = "" + now.getDate(); if (day.length == 1) { day = "0" + day; }
    return year + "-" + month + "-" + day;
  }

  diasmenos(dias:any){
    let hoy = this.fechaHoy();
    let data = this.editar_fecha(hoy, "-"+dias, "d");
    return data;
  }

  fechaAyer(){
    let hoy = this.fechaHoy();
    let ayer = this.editar_fecha(hoy, "-1", "d");
    return ayer;
  }

  editar_fecha(fecha, intervalo, dma) {
    var separador = separador || "-";
    var arrayFecha = fecha.split(separador);
    var dia = arrayFecha[2];
    var mes = arrayFecha[1];
    var anio = arrayFecha[0];
    
    var fechaInicial = new Date(anio, mes - 1, dia);
    var fechaFinal = fechaInicial;
    if(dma=="m" || dma=="M"){
      fechaFinal.setMonth(fechaInicial.getMonth()+parseInt(intervalo));
    }else if(dma=="y" || dma=="Y"){
      fechaFinal.setFullYear(fechaInicial.getFullYear()+parseInt(intervalo));
    }else if(dma=="d" || dma=="D"){
      fechaFinal.setDate(fechaInicial.getDate()+parseInt(intervalo));
    }else{
      return fecha;
    }
    dia = fechaFinal.getDate();
    mes = fechaFinal.getMonth() + 1;
    anio = fechaFinal.getFullYear();
   
    dia = (dia.toString().length == 1) ? "0" + dia.toString() : dia;
    mes = (mes.toString().length == 1) ? "0" + mes.toString() : mes;
   
    return anio + "-" + mes + "-" + dia;
  }

}