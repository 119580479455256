import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import {
  ScriptService,
  GetserviesService
} from '../app.filesServices';

declare var moment:any;
declare var $:any;

@Component({
  selector: 'app-videosadmin',
  templateUrl: './videosadmin.component.html',
  styleUrls: ['./videosadmin.component.css']
})
export class VideosadminComponent implements OnInit {

	loader:boolean = false;
	token = JSON.parse(localStorage.getItem("token"));

	listaSolicitudesVideosAdmin:any;
	datosVideos:any;

  nom:any = 0;

  tiposolicitud:any = 1;

	vistacancelar:any = false;
  vistaterminar:any = false;
	descripcion:any = '';
  path:any = '';
  url:any = '';
  userresponsable:any = '';
  clave:any = '';
	idcancelar:any = '';
  idterminar:any = '';

  terminadasCanceladas:any = false;

  fechaVideoInicio:any = moment(this.fechaHoy(), 'YYYY-MM-DD').subtract(7, 'days').format('YYYY-MM-DD');
  fechaVideoFin:any = moment(this.fechaHoy(), 'YYYY-MM-DD').subtract(1, 'days').format('YYYY-MM-DD');

  constructor(
  	public script:ScriptService,
  	public snackBar:MatSnackBar,
  	public getservices:GetserviesService,
  	private router:Router,
    private activatedRoute: ActivatedRoute
  	) {
  	this.script.loadScript();
  }

  ngOnInit() {
  	this.datosGeneralesVideos();
  	// trae lista de alertas videos
    this.loader = true;
    this.getservices.getData('solicitudVideosListAdmin',{})
    .subscribe( res => {
      this.listaSolicitudesVideosAdmin = res;
      this.nom = this.listaSolicitudesVideosAdmin.length;
      this.terminadasCanceladas = false;
      this.loader = false;
      },err => {
      	this.loader = false;
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
    });
  }

  datosGeneralesVideos(){
  	// trae lista de alertas videos
    this.loader = true;
    this.getservices.getData('solicitudVideosDatosAdmin',{})
    .subscribe( res => {
      this.datosVideos = res;
      this.datosVideos = eval(this.datosVideos[0]);
      this.loader = false;
      },err => {
      	this.loader = false;
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
    });
  }

  terminar(){
    if(this.path && this.url && this.userresponsable){
      if(this.userresponsable == 'isaiasmorelos' && this.clave == 'Morelos0919' || this.userresponsable == 'israelwilchis' && this.clave == 'wilchis1350' || this.userresponsable == 'paolagarcia' && this.clave == 'Olap09' || this.userresponsable == 'alexaosorio' && this.clave == '91471' || this.userresponsable == 'hectorsanchez' && this.clave == '00992' || this.userresponsable == 'julioalberto' && this.clave == 'abcdf147963' || this.userresponsable == 'eduardocarvajal' && this.clave == '01310' || this.userresponsable == 'monisanchez' && this.clave == 'moni9108' ){
      	this.loader = true;
      	let data = {
      		id: this.idterminar,
      		status: 1,
      		descripcion: null,
          path: this.path,
          url: this.url,
          userresponsable: this.userresponsable
      	};
        this.getservices.setData('solicitudVideosListAdminEdit',data)
        .subscribe( res => {
        	if(res['res'] == true){
        		this.ngOnInit();
        		this.snackBar.open('Solicitud terminada correctamente...', null, { duration: 3000 });
        	}else{
        		this.snackBar.open('Solicitud terminada incorrectamente... Intente de nuevo.', null, { duration: 3000 });
        	}
          	this.loader = false;
          },err => {
          	this.loader = false;
            this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        });
      	this.vistacancelar = false;
        this.vistaterminar = false;
      }else{
        this.snackBar.open('¡Clave incorrecta!', null, { duration: 3000 });
      }
    }else{
      this.snackBar.open('¡Path, URL o Responsable vacío!', null, { duration: 3000 });
    }
  }

  cancelarvista(id:any){
  	this.idcancelar = id;
  	this.vistacancelar = true;
    this.vistaterminar = false;
  }

  terminarvista(id:any){
    this.idterminar = id;
    this.vistacancelar = false;
    this.vistaterminar = true;
  }

  cancelar(){
    if(this.descripcion && this.userresponsable){
      if(this.userresponsable == 'isaiasmorelos' && this.clave == 'Morelos0919' || this.userresponsable == 'israelwilchis' && this.clave == 'wilchis1350' || this.userresponsable == 'paolagarcia' && this.clave == 'Olap09' || this.userresponsable == 'alexaosorio' && this.clave == '91471' || this.userresponsable == 'hectorsanchez' && this.clave == '00992' || this.userresponsable == 'julioalberto' && this.clave == 'abcdf147963' || this.userresponsable == 'eduardocarvajal' && this.clave == '01310' || this.userresponsable == 'monisanchez' && this.clave == 'moni9108' ){
      	this.loader = true;
      	let data = {
      		id: this.idcancelar,
      		status: 2,
      		descripcion: this.descripcion,
          userresponsable: this.userresponsable,
          path: null,
          url: null
      	};
        this.getservices.setData('solicitudVideosListAdminEdit',data)
        .subscribe( res => {
        	if(res['res'] == true){
        		this.ngOnInit();
        		this.snackBar.open('Solicitud terminada correctamente...', null, { duration: 3000 });
        	}else{
        		this.snackBar.open('Solicitud terminada incorrectamente... Intente de nuevo.', null, { duration: 3000 });
        	}
          	this.loader = false;
          },err => {
          	this.loader = false;
            this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        });
      	this.vistacancelar = false;
        this.vistaterminar = false;
      }else{
        this.snackBar.open('¡Clave incorrecta!', null, { duration: 3000 });
      }
    }else{
      this.snackBar.open('¡Descripción o User Responsable vacío!', null, { duration: 3000 });
    }
  }

  porPeriodoVieos(){
    this.loader = true;
    let data = {
      tipo: this.tiposolicitud,
      fechaVideoInicio: $(".fechaVideoInicio").val(),
      fechaVideoFin: $(".fechaVideoFin").val()
    };
    this.getservices.getData('solicitudVideosListAdminPeriodo',data)
    .subscribe( res => {
        this.listaSolicitudesVideosAdmin = res;
        this.nom = this.listaSolicitudesVideosAdmin.length;
        this.terminadasCanceladas = true;
        this.loader = false;
      },err => {
        this.loader = false;
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
    });
    this.vistacancelar = false;
  }

  fechaHoy(){
    let now = new Date();
    let year = "" + now.getFullYear();
    let month = "" + (now.getMonth() + 1); if (month.length == 1) { month = "0" + month; }
    let day = "" + now.getDate(); if (day.length == 1) { day = "0" + day; }
    return year + "-" + month + "-" + day;
  }

}
