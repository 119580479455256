import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import {
  ScriptService,
  GetserviesService
} from '../app.filesServices';

declare var $:any;

@Component({
  selector: 'app-cobroswred',
  templateUrl: './cobroswred.component.html',
  styleUrls: ['./cobroswred.component.css']
})
export class CobroswredComponent implements OnInit {

	loader:boolean = false;
	token = JSON.parse(localStorage.getItem("token"));

	rutaocliente:any;
	unidadeslist:any;
	ruta:any;

  constructor(
  	public script:ScriptService,
  	public snackBar:MatSnackBar,
  	public getservices:GetserviesService
  	) {
  	this.script.loadScript();
  }

  recargartransmicion(){
    this.unidadesTransmicion(this.ruta); 
  }

  ngOnInit() {
  	// trae datos de rutas para select
    this.loader = true;
    let data = {
      pin: this.token.pin
    };
    this.getservices.getData('rutasoclientes',data)
    .subscribe( res => {
      this.rutaocliente = res;
      this.ruta = this.rutaocliente[0].pin;
      this.unidadesTransmicion(this.ruta);
      if(this.rutaocliente.length == 1){
	    $('.rutas').hide();
	  }
      this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
    });
  }

  onChange(rutaID:any) {
  	this.unidadesTransmicion(rutaID);
  }

  unidadesTransmicion(rutaID:any){
  	this.loader = true;
    let data = {
      ruta: rutaID
    };
    this.getservices.getData('equiposCobros',data)
    .subscribe( res => {
    //console.log(res);
      this.unidadeslist = res;
      this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
    });
  }

}
