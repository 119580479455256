import { Component, NgModule } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../services/auth.service';
import { HttpClient } from '@angular/common/http';

import { version } from '../../version/version';

//import * as CryptoJS from 'crypto-js';  npm install @types/crypto-js

import {
  ScriptService,
  GetserviesService
} from '../app.filesServices';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent{

  ipAddress:any;

  loader:boolean = false;

  usuario:string = '';
  password:string = '';
  version:any = version.no;

  correo:string;
  token:string;
  nuevacontrasena:string;

  banderarecuperar:boolean = false;

  text:any;
  text2:any;

  constructor(
      public snackBar:MatSnackBar,
      public auth:AuthService,
      public script:ScriptService,
      public getservices:GetserviesService,
      private http: HttpClient
             ) {
      this.http.get<{ip:string}>('https://jsonip.com')
      .subscribe( data => {
        this.ipAddress = data
      });
      this.script.loadScript();
      /* codigo comentado para encriptar y desincriptar
      this.text = CryptoJS.AES.encrypt('Hola mundo', 'deftones');
      var bytes  = CryptoJS.AES.decrypt(this.text.toString(), 'deftones');
      this.text2 = bytes.toString(CryptoJS.enc.Utf8);
      */
  }

  login(){
    if(this.usuario && this.password){
      this.auth.loginServe(this.usuario,this.password);
    }else{
      this.snackBar.open('Falta usuario o contraseña', null, { duration: 3000 });
    }
  }

  recuperar(){
    this.loader = true;
    let data = {
      correo: this.correo
    };
    this.getservices.recuperarContrasena(data)
    .subscribe( res => {
      if(res['res']==true){
        this.snackBar.open('Correo enviado', null, { duration: 3000 });
        this.banderarecuperar = true;
        this.loader = false;
      }else{
        this.snackBar.open('Error de conexión en el servidor o correo no valido', null, { duration: 3000 });
        this.loader = false;
      }
    },err => {
      this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
      this.loader = false;
    });
  }

  cambiar(){
    if(this.correo && this.token && this.nuevacontrasena){
    this.loader = true;
    let data = {
      correo: this.correo,
      token: this.token,
      nuevacontrasena: this.nuevacontrasena
    };
    this.getservices.recuperarContrasenaDatos(data)
    .subscribe( res => {
      if(res['res']==true){
        this.snackBar.open('Contraseña cambiada exitosamente', null, { duration: 3000 });
        this.banderarecuperar = false;
        this.loader = false;
        this.auth.loginServe(this.correo,this.nuevacontrasena);
      }else{
        this.snackBar.open('Error de conexión en el servidor o correo no valido', null, { duration: 3000 });
        this.loader = false;
      }
    },err => {
      this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
      this.loader = false;
    });
    }else{
      this.snackBar.open('Falta Token o la nueva contraseña', null, { duration: 3000 });
      this.loader = false;
    }
  }

}
