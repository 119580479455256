import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'reloadparos',
  template: ''
})
export class ReloadparosComponent implements OnInit{
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe( params =>{
    	this.router.navigate(['parosdemotor',params['tipo'],params['ruta'],params['timeFullInicio'],params['timeFullFin'],params['dateInicio'],params['timeInicio'],params['dateFin'],params['timeFin']]);
    });
  }
}