import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import swal from 'sweetalert';

import {
  ScriptService,
  GetserviesService
} from '../app.filesServices';

declare var $:any;

@Component({
  selector: 'app-transmicion',
  templateUrl: './transmicion.component.html'
})
export class TransmicionComponent implements OnInit {

	loader:boolean = false;

	token = JSON.parse(localStorage.getItem("token"));
	params:any = { buttons: false, timer: 2000 };

	rutaocliente:any;
	unidadeslist:any;
	ruta:any;

  imei:any;
  cmd:any;
  tel:any = '2225983859';

  ///
  swal:any = {
    title: "¿Seguro que quiere eliminar el registro?",
    text: "No se podra recuperar el registro",
    icon: "warning",
    buttons: true,
    dangerMode: true,
  };
  icon:any = {icon: "success"};

  theCheckboxMala = true;
  theCheckboxSin = true;
  theCheckboxTodas = false;

  constructor(
    private router: Router, 
    public script:ScriptService,
    public snackBar:MatSnackBar,
    public getservices:GetserviesService
    ) {
  	this.script.loadScript();
  }

  recargartransmicion(){
    this.ruta = $(".rutaSearch").val();
    this.unidadesTransmicion(this.ruta); 
  }

  toggleVisibilityMala(e){
    this.theCheckboxTodas = false; 
    this.theCheckboxMala = e.target.checked; 
    //alert(this.theCheckboxMala);
    this.unidadesTransmicion(this.ruta);
  }

  toggleVisibilitySin(e){
    this.theCheckboxTodas = false; 
    this.theCheckboxSin = e.target.checked; 
    //alert(this.theCheckboxSin);
    this.unidadesTransmicion(this.ruta);
  }

  toggleVisibilityTodas(e){
    this.theCheckboxMala = false; 
    this.theCheckboxSin = false; 
    this.theCheckboxTodas = e.target.checked; 
    //alert(this.theCheckboxTodas);
    this.unidadesTransmicion(this.ruta);
  }

  ngOnInit() {
    this.onChangeSelect();
    // trae datos de rutas para select
    this.loader = true;
    let data = {
      pin: this.token.pin
    };
    this.getservices.getData('rutasoclientes',data)
    .subscribe( res => {
      this.rutaocliente = res;
      this.ruta = this.rutaocliente[0].pin;
      this.unidadesTransmicion(this.ruta);
      if(this.rutaocliente.length == 1){
        $('.rutas').hide();
      }
      this.loader = false;
    },err => {
      this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
      this.loader = false;
    });
  }

  editStatus(imei,status){
    let data = {
      imei: imei,
      status: status
    };
    this.getservices.setData('edit_status',data)
    .subscribe( data => {
      if(data['res']==true){
        this.snackBar.open('Status editado correctamente...', null, { duration: 3000 });
        this.unidadesTransmicion(this.ruta); 
      }else{
        this.snackBar.open('Status Error!!!', null, { duration: 3000 });
      }
    },err => {
      this.snackBar.open('Error de conexión en el servidor... Status Transmición', null, { duration: 3000 });
    });
  }

  onChangeSelect() {
    $('.js-select2').on("change",()=>{
      this.ruta = $(".rutaSearch").val();
      this.theCheckboxMala = true; 
      this.theCheckboxSin = true; 
      this.theCheckboxTodas = false; 
      this.unidadesTransmicion(this.ruta);
    });
  }

  gpscmd(imei:any,cmd:any,gps:any){
    if(gps == 'suntech' && cmd == 'reset'){
      this.imei = imei;
      this.cmd = cmd;
      $("#modal-telgps").modal("show");
    }else{
      //$('.trans-'+imei).hide();
      let data = {
        imei: imei,
        cmd: cmd
      };
      this.getservices.setData('cmd',data)
      .subscribe( data => {
        if(data['res']==true){
          this.snackBar.open(cmd+'... Send!!!', null, { duration: 3000 });
        }else{
          this.snackBar.open(cmd+'... Error!!!', null, { duration: 3000 });
        }
      },err => {
        this.snackBar.open('Error de conexión en el servidor... SMS', null, { duration: 3000 });
      });
    }
  }

  cmdsuntech(){
    if(this.tel.length == 10){
      ///
      let datasuntech = {
        imei: this.imei,
        tel: this.tel
      };
      this.getservices.setData('cmdsuntech',datasuntech)
      .subscribe( data => {
        if(data['res']==true){
          this.snackBar.open('SMS en proceso... Send!!!', null, { duration: 3000 });
        }else{
          this.snackBar.open('SMS en proceso... Error!!!', null, { duration: 3000 });
        }
      },err => {
        this.snackBar.open('Error de conexión en el servidor... SMS', null, { duration: 3000 });
      });
      ///
      let data = {
        imei: this.imei,
        cmd: this.cmd
      };
      this.getservices.setData('cmd',data)
      .subscribe( data => {
        if(data['res']==true){
          this.snackBar.open('Reset... Send!!!', null, { duration: 3000 });
        }else{
          this.snackBar.open('Reset... Error!!!', null, { duration: 3000 });
        }
      },err => {
        this.snackBar.open('Error de conexión en el servidor... SMS', null, { duration: 3000 });
      });
      ///
      this.imei = '';
      this.cmd = '';
      this.tel = '';
      ///
    }else{
      this.snackBar.open('Teléfono no valido o vacio...', null, { duration: 3000 });
      setTimeout(function() {
        $("#modal-telgps").modal("show");
      }, 1000);
    }
  }

  unidadesTransmicion(rutaID:any){
  	this.loader = true;
    let data = {
      mala: this.theCheckboxMala,
      sin: this.theCheckboxSin,
      todos: this.theCheckboxTodas,
      ruta: rutaID,
      pin: this.token.pin
    };
    this.getservices.getData('unidadesTransmicion',data)
    .subscribe( res => {
      this.unidadeslist = res;
      this.snackBar.open('Busqueda correcta - '+this.querutaAirView(rutaID), null, { duration: 3000 });
      this.loader = false;
    },err => {
      this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
      this.loader = false;
    });
  }

  verVideo(imei:any){
    this.router.navigate(['alertaspanico',imei]);
  }

  resetDevice(imei:any){
    let data = {
      imei: imei,
      cmd: 'reset'
    };
    this.getservices.setData('cmd',data)
    .subscribe( data => {
      if(data['res']==true){
        this.snackBar.open('Reset... Send!!!', null, { duration: 3000 });
      }else{
        this.snackBar.open('Reset... Error!!!', null, { duration: 3000 });
      }
    },err => {
      this.snackBar.open('Error de conexión en el servidor... SMS', null, { duration: 3000 });
    });
  }


  querutaAirView(id){
    for (var i = 0; i < this.rutaocliente.length; ++i) {
      if(this.rutaocliente[i].pin == id){
        return this.rutaocliente[i].airview;
      }
    }
  }

}
