import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AgmMap } from '@agm/core';

import swal from 'sweetalert';

import {
  ScriptService,
  GetserviesService
} from '../app.filesServices';

declare var $:any;

@Component({
  selector: 'app-cobrostransmision',
  templateUrl: './cobrostransmision.component.html',
  styleUrls: ['./cobrostransmision.component.css']
})
export class CobrostransmisionComponent implements OnInit {

	loader:boolean = false;

	public map: any;

	token = JSON.parse(localStorage.getItem("token"));
	params:any = { buttons: false, timer: 2000 };
	mapa = JSON.parse(localStorage.getItem("mapa"));
  	heightMap:any = window.screen.height;

  	lat:any = this.mapa['lat'];
  	lng:any = this.mapa['lng'];

  	latA:any;
  	lngA:any;
  	latS:any;
  	lngS:any;
  	zoom:any = 17;

	rutaocliente:any;
	unidadeslist:any;
	ruta:any;
	nombreRuta:any;
	mes:any = 0;
	detalleslist:any;
	detallesmapa:any = false;

	unidadD:any;
	unidadID:any;

  constructor(
  	public script:ScriptService,
  	public snackBar:MatSnackBar,
  	public getservices:GetserviesService
  	) {
  	this.script.loadScript();
  }

  public mapReady(map) {
    this.map = map;
  }

  recargartransmicion(){
    this.unidadesTransmicion(this.ruta); 
  }

  ngOnInit() {
  	// trae datos de rutas para select
    this.loader = true;
    let data = {
      pin: this.token.pin
    };
    this.getservices.getData('rutasoclientes',data)
    .subscribe( res => {
      this.rutaocliente = res;
      this.ruta = this.rutaocliente[0].pin;
      this.nombreRuta = this.rutaocliente[0].label;
      this.unidadesTransmicion(this.ruta);
      this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
    });
  }

  detalle(unidadID:any,nombreUnidad:any){
  	this.detalleslist = [];
  	this.unidadD = nombreUnidad;
  	this.unidadID = unidadID;
  	this.loader = true;
    let data = {
      unidad: unidadID,
      mes: this.mes,
      ruta: this.ruta
    };
    this.getservices.getData('unidadesTransmicionPorMesDetalle',data)
    .subscribe( res => {
      this.detalleslist = res;
      this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
    });
  }

  mapaview(fecha:any){
  	this.loader = true;
  	this.detallesmapa = {};
  	this.latS = false;
	this.lngS = false;
	this.latA = false;
	this.lngA = false;
    let data = {
      unidad: this.unidadID,
      fecha: fecha,
      ruta: this.ruta
    };
    this.getservices.getData('unidadesTransmicionPorMesDetalleMapa',data)
    .subscribe( res => {
    	if(res == undefined){
    		this.detallesmapa = false;
    		this.snackBar.open('No se encontro datos...', null, { duration: 3000 });
    	}else{
    		this.detallesmapa = res;
    		if(this.detallesmapa['tipo'] == 'todos'){
    			this.lat = this.detallesmapa['latA'];
				this.lng = this.detallesmapa['lngA'];
				this.latA = this.detallesmapa['latA'];
				this.lngA = this.detallesmapa['lngA'];
				this.latS = this.detallesmapa['latS'];
				this.lngS = this.detallesmapa['lngS'];
    		}else  if(this.detallesmapa['tipo'] == 'primero'){
    			this.lat = this.detallesmapa['latA'];
				this.lng = this.detallesmapa['lngA'];
				this.latA = this.detallesmapa['latA'];
				this.lngA = this.detallesmapa['lngA'];
				this.latS = false;
				this.lngS = false;
    		}else if(this.detallesmapa['tipo'] == 'segundo'){
    			this.lat = this.detallesmapa['latS'];
				this.lng = this.detallesmapa['lngS'];
				this.latA = false;
				this.lngA = false;
				this.latS = this.detallesmapa['latS'];
				this.lngS = this.detallesmapa['lngS'];
    		}else if(this.detallesmapa['tipo'] == 'ninguno'){
    			this.detallesmapa = false;
    			this.snackBar.open('No se encontro datos...', null, { duration: 3000 });
    		}

    	}
      	this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
    });
  }

  onChange(rutaID:any) {
  	this.mes = 0;
  	for (var i = 0; i < this.rutaocliente.length; ++i) {
  		if(this.rutaocliente[i].pin == rutaID){
  			this.ruta = rutaID;
  			this.nombreRuta = this.rutaocliente[i].label;
  		}
  	}
  	this.unidadesTransmicion(rutaID);
  }

  mesQuery(consulta:any){
  	this.mes = consulta;
  	this.unidadesTransmicion(this.ruta);
  }

  unidadesTransmicion(rutaID:any){
  	this.loader = true;
    let data = {
      ruta: rutaID,
      mes: this.mes
    };
    this.getservices.getData('unidadesTransmicionPorMes',data)
    .subscribe( res => {
    //console.log(res);
      this.unidadeslist = res;
      this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
    });
  }

}
