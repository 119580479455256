import { Component, OnInit } from '@angular/core';
import { DomSanitizer  } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

import {
  ScriptService,
  GetserviesService
} from '../app.filesServices';

import { environment } from '../../environments/environment';

declare var $:any;

@Component({
  selector: 'app-reportealerta',
  templateUrl: './reportealerta.component.html',
  styleUrls: ['./reportealerta.component.css']
})
export class ReportealertaComponent implements OnInit {

	public map: any;

  loader:boolean = false;
  token = JSON.parse(localStorage.getItem("token"));
  mapa = JSON.parse(localStorage.getItem("mapa"));
  heightMap:any = window.screen.height - 250;

  ultimosMinData:any;
  numUltimosMin:any = 0;

  alersGeneral1:any;
  alersGeneral2:any;
  numGeneral:any = 0;

  alersRuta1:any;
  alersRuta2:any;
  numRutas:any = 0;

  fechainicioAlertasReporte:any;
  fechafinAlertasReporte:any;

  fechainicioAlertasReporteR:any;
  fechafinAlertasReporteR:any;
  fechaC5AlertasReporte:any;

  ruta:any;
  rutaocliente:any;


  unidad:any;
  direccion:any = '...';
	fechahora:any;
	speed:any;
  imei:any;

  observacion:any = '';
  observacionView:any = false;
  observacionText:any;
  observacionViewText:any = false;

  observacionimei:any;
  observacionfechahora:any;

  lat:any = this.mapa['lat'];
  lng:any = this.mapa['lng'];
  zoom:any = 17;

  constructor(
    private router: Router, 
    private activatedRoute: ActivatedRoute,
    public script:ScriptService,
    public getservices:GetserviesService,
    private sanitizer: DomSanitizer,
    public http:HttpClient,
    public snackBar:MatSnackBar,
  ) { 
    this.script.loadScript();
    if(this.token['modouser'] == 'sistemas' || this.token['modouser'] == 'monitoreo' || this.token['modouser'] == 'monitoreoanalistas' || this.token['modouser'] == 'monitoreoadmin'){}else{
      this.router.navigate(['reload','mapa']); 
    }
    this.fechainicioAlertasReporte = this.fechaAnterior(5);
    this.fechafinAlertasReporte = this.fechaAnterior(1);
    ///
    this.fechainicioAlertasReporteR = this.fechaAnterior(5);
    this.fechafinAlertasReporteR = this.fechaAnterior(1);
    //
    this.fechaC5AlertasReporte = this.fechaHoy();
  }

  public mapReady(map) {
    this.map = map;
  }

  ngOnInit() {
    this.ultimosMin('15','off');
    this.loader = true;
    let data = {
      pin: this.token.pin
    };
    this.getservices.getData('rutasoclientes',data)
    .subscribe( res => {
    	this.rutaocliente = res;
      	this.ruta = this.rutaocliente[0].pin;
      this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
      });
  }

  editAlert(imei,fechahora,tipo){
    for (var i = 0; i < this.ultimosMinData.length; ++i) {
      if(this.ultimosMinData[i]['fechahora'] == fechahora && this.ultimosMinData[i]['imei'] == imei){
        this.ultimosMinData[i]['detalle'] = tipo;
      }
    }
    this.loader = true;
    let data = {
      imei: imei,
      fechahora: fechahora,
      tipo: tipo
    };
    this.getservices.setData('editAlertDetalle',data)
    .subscribe( res => {
      if(res['res']==true){
        this.snackBar.open('Editado correctamente...', null, { duration: 3000 });
      }
      this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
      });
  }

  panicoalert(imei:any){
    this.router.navigate(['alertaspanico',imei]);
  }

  observacionFuncion(imei:any,fechahora:any){
    this.observacion = '';
    this.observacionView = true;
    this.observacionViewText = false;
    this.observacionimei = imei;
    this.observacionfechahora = fechahora;
  }

  guardarObservacion(){
    for (var i = 0; i < this.ultimosMinData.length; ++i) {
      if(this.ultimosMinData[i]['fechahora'] == this.observacionfechahora && this.ultimosMinData[i]['imei'] == this.observacionimei){
        this.ultimosMinData[i]['observacion'] = this.observacion;
      }
    }
    this.observacionView = false;
    this.observacionViewText = false;
    this.loader = true;
    let data = {
      imei: this.observacionimei,
      fechahora: this.observacionfechahora,
      observacion: this.observacion
    };
    this.getservices.setData('editAlertObservacion',data)
    .subscribe( res => {
      if(res['res']==true){
        this.snackBar.open('Editado correctamente...', null, { duration: 3000 });
      }
      this.loader = false;
      },err => {
        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
        this.loader = false;
      });
  }

  observacionFuncionText(ruta:any,unidad:any,valor:any){
    this.observacionViewText = true;
    this.observacionView = false;
    this.observacionText = 'Ruta: '+ ruta +', Unidad: '+unidad+ ' || Observación: '+valor;
  }

  ultimosMin(tiempo,tipo){
    if(tipo == 'on'){
      window.open('https://manager.airview.com.mx/api/V1/alertasTiempo/'+tiempo, "_blank");
    }else{
    	this.loader = true;
      let data = {
        tiempo: tiempo,
        pin: this.token.pin,
        modouser: this.token.modouser
      };
      this.getservices.getData('alertasTiempo',data)
      .subscribe( res => {
      	this.loader = false;
      	if(res['res'] == true){
      		this.ultimosMinData = res['data'];
      	}else{
      		this.ultimosMinData = [];
      	}
      	this.numUltimosMin = this.ultimosMinData.length;
      	if(this.numUltimosMin == 0){
      		this.snackBar.open('No hay datos...', null, { duration: 3000 });
      	}
      	this.numGeneral = 0;
  	    this.alersGeneral1 = [];
      	this.alersGeneral2 = [];
      	this.numRutas = 0;
      	this.alersRuta1 = [];
      	this.alersRuta2 = [];
        },err => {
          this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
          this.loader = false;
      });
    }
  }

  alertasC5(tipo){
    if(tipo == 'on'){
      window.open('https://manager.airview.com.mx/api/V1/alertasC5Report/'+$(".fechaC5AlertasReporte").val(), "_blank");
    }else{
      this.loader = true;
      let data = {
        tiempo: $(".fechaC5AlertasReporte").val()
      };
      this.getservices.getData('alertasC5Report',data)
      .subscribe( res => {
        this.loader = false;
        if(res['res'] == true){
          this.ultimosMinData = res['data'];
        }else{
          this.ultimosMinData = [];
        }
        this.numUltimosMin = this.ultimosMinData.length;
        if(this.numUltimosMin == 0){
          this.snackBar.open('No hay datos...', null, { duration: 3000 });
        }
        this.numGeneral = 0;
        this.alersGeneral1 = [];
        this.alersGeneral2 = [];
        this.numRutas = 0;
        this.alersRuta1 = [];
        this.alersRuta2 = [];
        },err => {
          this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
          this.loader = false;
      });
    }
  }

  pordiaperiodosGeneral(url,tipo){
    if(tipo == 'on'){
      if(url == 'alertasdiasGeneral'){
        window.open('https://manager.airview.com.mx/api/V1/alertasdiasGeneral', "_blank");
      }else{
        window.open('https://manager.airview.com.mx/api/V1/alertasperiodosGeneral/'+$(".fechainicioAlertasReporte").val()+'/'+$(".fechafinAlertasReporte").val(), "_blank");
      }
    }else{
    	this.loader = true;
    	let data;
    	if(url == 'alertasdiasGeneral'){
    		data = {};
    	}else{
    		data = {
    			inicio: $(".fechainicioAlertasReporte").val(),
    			fin: $(".fechafinAlertasReporte").val()
    		};
    	}
      this.getservices.getData(url,data)
      .subscribe( res => {
      	this.loader = false;
      	//console.log(res);
        	if(res['res'] == true){
        		this.numGeneral = res['totalalertas'];
    	    	if(this.numGeneral == 0){
    	    		this.snackBar.open('No hay datos...', null, { duration: 3000 });
    	    	}
    	    	this.ultimosMinData = [];
    	    	this.numUltimosMin = 0;
        		this.numRutas = 0;
    	    	this.alersRuta1 = [];
        		this.alersRuta2 = [];
        		this.alersGeneral1 = res['tabla1'];
        		this.alersGeneral2 = res['tabla2'];
        	}else{
        		this.numGeneral = 0;
        	}
        },err => {
          this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
          this.loader = false;
      });
    }
  }


  pordiaperiodosRuta(url,tipo){
    if(tipo == 'on'){
      if(url == 'alertasdiasRuta'){
        window.open('https://manager.airview.com.mx/api/V1/alertasdiasRuta/'+this.ruta, "_blank");
      }else{
        window.open('https://manager.airview.com.mx/api/V1/alertasperiodosRuta/'+this.ruta+'/'+$(".fechainicioAlertasReporteR").val()+'/'+$(".fechafinAlertasReporteR").val(), "_blank");
      }
    }else{
    	this.loader = true;
    	let data;
    	if(url == 'alertasdiasRuta'){
    		data = {
    			ruta: this.ruta
    		};
    	}else{
    		data = {
    			ruta: this.ruta,
    			inicio: $(".fechainicioAlertasReporteR").val(),
    			fin: $(".fechafinAlertasReporteR").val()
    		};
    	}
      this.getservices.getData(url,data)
      .subscribe( res => {
      	this.loader = false;
      	//console.log(res);
        	if(res['res'] == true){
        		this.numRutas = res['totalalertas'];
    	    	if(this.numRutas == 0){
    	    		this.snackBar.open('No hay datos...', null, { duration: 3000 });
    	    	}
    	    	this.ultimosMinData = [];
    	    	this.numUltimosMin = 0;
    	    	this.numGeneral = 0;
    	    	this.alersGeneral1 = [];
        		this.alersGeneral2 = [];
        		this.alersRuta1 = res['tabla1'];
        		this.alersRuta2 = res['tabla2'];    	
        	}else{
        		this.numRutas = 0;
        	}
        },err => {
          this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
          this.loader = false;
      });
    }
  }


  ubicar(item){
  	this.unidad = item.unidad+' || Ruta: '+item.ruta;
  	this.fechahora = item.fechahora;
    this.speed = item.speed;
    //// centro mapa
    this.map.setCenter({ lat: Number(item.lat), lng: Number(item.lng) });
    this.lat = item.lat;
    this.lng = item.lng;
    this.imei = item.imei;
    this.zoom = 19;

    $("#modal-alertaubicarReporte").modal("show");
  }

  adress(){
    this.http.get('https://maps.googleapis.com/maps/api/geocode/json?latlng='+this.lat+','+this.lng+'&key='+environment.ubicacion)
      .subscribe((data) => {
        if(data['status'] == "OK"){
          this.direccion = data['results'][2].formatted_address;
        }else{
          this.direccion = 'No hay dirección';
        }
      });
  }

  getStyle() {
    // snip snip -> fetch the url from somewhere
    let height = this.heightMap;
    let style = `height: ${height}px;`;
    
    // sanitize the style expression
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }


  fechaHoy(){
		let now = new Date();
		let year = "" + now.getFullYear();
		let month = "" + (now.getMonth() + 1); if (month.length == 1) { month = "0" + month; }
		let day = "" + now.getDate(); if (day.length == 1) { day = "0" + day; }
		return year + "-" + month + "-" + day;
	}

	fechaAnterior(dias){
		let hoy = this.fechaHoy();
		let ayer = this.editar_fecha(hoy, "-"+dias, "d");
		return ayer;
	}

	editar_fecha(fecha, intervalo, dma) {
	  var separador = separador || "-";
	  var arrayFecha = fecha.split(separador);
	  var dia = arrayFecha[2];
	  var mes = arrayFecha[1];
	  var anio = arrayFecha[0];
	  
	  var fechaInicial = new Date(anio, mes - 1, dia);
	  var fechaFinal = fechaInicial;
	  if(dma=="m" || dma=="M"){
	    fechaFinal.setMonth(fechaInicial.getMonth()+parseInt(intervalo));
	  }else if(dma=="y" || dma=="Y"){
	    fechaFinal.setFullYear(fechaInicial.getFullYear()+parseInt(intervalo));
	  }else if(dma=="d" || dma=="D"){
	    fechaFinal.setDate(fechaInicial.getDate()+parseInt(intervalo));
	  }else{
	    return fecha;
	  }
	  dia = fechaFinal.getDate();
	  mes = fechaFinal.getMonth() + 1;
	  anio = fechaFinal.getFullYear();
	 
	  dia = (dia.toString().length == 1) ? "0" + dia.toString() : dia;
	  mes = (mes.toString().length == 1) ? "0" + mes.toString() : mes;
	 
	  return anio + "-" + mes + "-" + dia;
	}

}
