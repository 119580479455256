import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import swal from 'sweetalert';

import {
  ScriptService,
  GetserviesService
} from '../../app.filesServices';

// Declaramos las variables para jQuery
declare var $:any;
declare var Codebase:any;
declare var moment:any;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html'
})
export class MenuComponent {

	loader:boolean = false;
	token = JSON.parse(localStorage.getItem("token"));
	nombre = JSON.parse(localStorage.getItem("token"));
	nivel:string;

	params:any = { buttons: false, timer: 2000 };

	fecha:any;
	timeFullInicio:any;
	timeFullFin:any;
	dateInicio:any;
	timeInicio:any;
	dateFin:any;
	timeFin:any;

	///minuto a minuto
	timeFullInicioMin:any;
	timeFullFinMin:any;
	dateInicioMin:any;
	timeInicioMin:any;
	dateFinMin:any;
	timeFinMin:any;

	dateInicioExcel:any;

	ruta:any;
  	rutaocliente:any;
  	velocidad:any = '60';
  	unidadesList:any;
  	unidad:any;

  	titulo:string;

  	panico:boolean = false;
  	excesos:boolean = false;
  	paros:boolean = false;

	constructor(
		private router:Router,
		public snackBar:MatSnackBar,
		public getservices:GetserviesService
             ) {
		this.nombre = this.nombre.nombre.split(" ");
		if(this.nombre[1]){
			this.nombre = this.nombre[0] + ' ' + this.nombre[1];
		}else{
			this.nombre = this.nombre[0];
		}
		this.nivel = this.token.nivel;
		///
		this.dateInicioExcel = this.fechaHoy();
		///
		this.fecha = this.fechaHoy();
		///
		this.timeFullInicio = moment(this.fechaHoy()+' '+this.hh_mm_ss(), 'YYYY-MM-DD HH:mm:ss').subtract(1, 'hours').format('HH:mm:ss');
		this.timeFullFin = this.hh_mm_ss();
		this.timeFullInicioMin = moment(this.fechaHoy()+' '+this.hh_mm_ss(), 'YYYY-MM-DD HH:mm:ss').subtract(1, 'hours').format('HH:mm:ss');
		this.timeFullFinMin = this.hh_mm_ss();
		///
		this.dateInicio = this.fechaAyer();
		this.timeInicio = moment(this.fechaHoy()+' '+this.hh_mm_ss(), 'YYYY-MM-DD HH:mm:ss').subtract(1, 'hours').format('HH:mm:ss');
		this.dateFin = this.fechaHoy();
		this.timeFin = this.hh_mm_ss();
		this.dateInicioMin = this.fechaAyer();
		this.timeInicioMin = moment(this.fechaHoy()+' '+this.hh_mm_ss(), 'YYYY-MM-DD HH:mm:ss').subtract(1, 'hours').format('HH:mm:ss');
		this.dateFinMin = this.fechaHoy();
		this.timeFinMin = this.hh_mm_ss();
		// trae datos de rutas para select
	    this.loader = true;
	    let data = {
	      pin: this.token.pin
	    };
	    this.getservices.getData('rutasoclientes',data)
	    .subscribe( res => {
	      this.rutaocliente = res;
	      this.ruta = this.rutaocliente[0].label;
	      if(this.rutaocliente.length == 1){
	      	$('.rutas').hide();
	      }
	      this.loader = false;
	      },err => {
	        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
	        this.loader = false;
	    });
		///
		$(function () {
	        Codebase.helpers(['datepicker','masked-inputs','select2']);
	    });
	}

	excelAlerts(){
		window.open('https://manager.airview.com.mx/api/V1/excelAlerts/'+$(".dateInicioExcel").val(), "_blank");
	}

	consultaDia(){
		this.timeFullInicio = $(".timeFullInicio").val();
		this.timeFullFin = $(".timeFullFin").val();
		this.router.navigate(['reloadalerts','consultadiaHoy',this.ruta,this.timeFullInicio,this.timeFullFin,'x','x','x','x']);
	}

	consultaAnterior(){
		this.dateInicio = $(".dateInicio").val();
		this.timeInicio = $(".timeInicio").val();
		this.dateFin = $(".dateFin").val();
		this.timeFin = $(".timeFin").val();
		this.router.navigate(['reloadalerts','consultadiaAyer',this.ruta,'x','x',this.dateInicio,this.timeInicio,this.dateFin,this.timeFin]);
	}

	consultaDiaExcesos(){
		if(this.velocidad.length >= 1 && this.velocidad != '0'){
			this.timeFullInicio = $(".timeFullInicio").val();
			this.timeFullFin = $(".timeFullFin").val();
			//this.router.navigate(['reloadvelocidades','consultadiaHoy',this.ruta,this.timeFullInicio,this.timeFullFin,'x','x','x','x',this.velocidad]);
			window.open('https://sibive.airview.com.mx/#/velocidades/consultadiaHoy/'+this.ruta+'/'+this.timeFullInicio+'/'+this.timeFullFin+'/x/x/x/x/'+this.velocidad+'/', "_blank");
		}else{
			this.snackBar.open('La velocidad no puede estar vacia o ser "0"', null, { duration: 3000 });
		}
	}

	consultaAnteriorExcesos(){
		if(this.velocidad.length >= 1 && this.velocidad != '0'){
			this.dateInicio = $(".dateInicio").val();
			this.timeInicio = $(".timeInicio").val();
			this.dateFin = $(".dateFin").val();
			this.timeFin = $(".timeFin").val();
			//this.router.navigate(['reloadvelocidades','consultadiaAyer',this.ruta,'x','x',this.dateInicio,this.timeInicio,this.dateFin,this.timeFin,this.velocidad]);
			window.open('https://sibive.airview.com.mx/#/velocidades/consultadiaAyer/'+this.ruta+'/x/x/'+this.dateInicio+'/'+this.timeInicio+'/'+this.dateFin+'/'+this.timeFin+'/'+this.velocidad+'/', "_blank");
		}else{
			this.snackBar.open('La velocidad no puede estar vacia o ser "0"', null, { duration: 3000 });
		}
	}

	consultaDiaParos(){
		this.timeFullInicio = $(".timeFullInicio").val();
		this.timeFullFin = $(".timeFullFin").val();
		this.router.navigate(['reloadparos','consultadiaHoy',this.ruta,this.timeFullInicio,this.timeFullFin,'x','x','x','x']);
	}
	consultaAnteriorParos(){
		this.dateInicio = $(".dateInicio").val();
		this.timeInicio = $(".timeInicio").val();
		this.dateFin = $(".dateFin").val();
		this.timeFin = $(".timeFin").val();
		this.router.navigate(['reloadparos','consultadiaAyer',this.ruta,'x','x',this.dateInicio,this.timeInicio,this.dateFin,this.timeFin]);
	}

	////// consulta de minuto a minuto

	onChange(rutaID:any) {
	    this.unidades(rutaID);
  	}

  	unidades(ruta:any){
  		this.loader = true;
	    let data = {
	      ruta: ruta,
	      pin: this.token.pin
	    };
	    this.getservices.getData('unidadesNombreRutaFiltro',data)
	    .subscribe( res => {
	      this.unidadesList = res;
	      this.unidad = this.unidadesList[0].imei;
	      this.loader = false;
	      },err => {
	        this.snackBar.open('Error de conexión en el servidor', null, { duration: 3000 });
	        this.loader = false;
	    });
  	}

	consultaDiaMinuto(){
		if(this.unidad){
		this.timeFullInicioMin = $(".timeFullInicioMin").val();
		this.timeFullFinMin = $(".timeFullFinMin").val();
		window.open('https://sibive.airview.com.mx/#/minutoaminuto/consultadiaHoy/'+this.ruta+'/'+this.timeFullInicioMin+'/'+this.timeFullFinMin+'/x/x/x/x/'+this.unidad+'/', "_blank");
		//this.router.navigate(['reloadminutoaminuto','consultadiaHoy',this.ruta,this.timeFullInicioMin,this.timeFullFinMin,'x','x','x','x',this.unidad]);
		}else{
			this.snackBar.open('La unidad debe estar llena', null, { duration: 3000 });
		}
	}

	consultaAnteriorMinuto(){
		if(this.unidad){
			this.dateInicioMin = $(".dateInicioMin").val();
			this.timeInicioMin = $(".timeInicioMin").val();
			this.dateFinMin = $(".dateFinMin").val();
			this.timeFinMin = $(".timeFinMin").val();
			window.open('https://sibive.airview.com.mx/#/minutoaminuto/consultadiaAyer/'+this.ruta+'/x/x/'+this.dateInicioMin+'/'+this.timeInicioMin+'/'+this.dateFinMin+'/'+this.timeFinMin+'/'+this.unidad+'/', "_blank");
			//this.router.navigate(['reloadminutoaminuto','consultadiaAyer',this.ruta,'x','x',this.dateInicioMin,this.timeInicioMin,this.dateFinMin,this.timeFinMin,this.unidad]);
		}else{
			this.snackBar.open('La unidad debe estar llena', null, { duration: 3000 });
		}
	}


	fechaHoy(){
		let now = new Date();
		let year = "" + now.getFullYear();
		let month = "" + (now.getMonth() + 1); if (month.length == 1) { month = "0" + month; }
		let day = "" + now.getDate(); if (day.length == 1) { day = "0" + day; }
		return year + "-" + month + "-" + day;
	}

	fechaAyer(){
		let hoy = this.fechaHoy();
		let ayer = this.editar_fecha(hoy, "-1", "d");
		return ayer;
	}

	editar_fecha(fecha, intervalo, dma) {
	  var separador = separador || "-";
	  var arrayFecha = fecha.split(separador);
	  var dia = arrayFecha[2];
	  var mes = arrayFecha[1];
	  var anio = arrayFecha[0];
	  
	  var fechaInicial = new Date(anio, mes - 1, dia);
	  var fechaFinal = fechaInicial;
	  if(dma=="m" || dma=="M"){
	    fechaFinal.setMonth(fechaInicial.getMonth()+parseInt(intervalo));
	  }else if(dma=="y" || dma=="Y"){
	    fechaFinal.setFullYear(fechaInicial.getFullYear()+parseInt(intervalo));
	  }else if(dma=="d" || dma=="D"){
	    fechaFinal.setDate(fechaInicial.getDate()+parseInt(intervalo));
	  }else{
	    return fecha;
	  }
	  dia = fechaFinal.getDate();
	  mes = fechaFinal.getMonth() + 1;
	  anio = fechaFinal.getFullYear();
	 
	  dia = (dia.toString().length == 1) ? "0" + dia.toString() : dia;
	  mes = (mes.toString().length == 1) ? "0" + mes.toString() : mes;
	 
	  return anio + "-" + mes + "-" + dia;
	}

	panicoModal(){
		this.panico = true;
		this.excesos = false;
		this.paros = false;
		this.titulo = 'Reporte de alertas';
		$(function () {
	        Codebase.helpers(['datepicker','masked-inputs','select2']);
	    });
	}

	excesosModal(){
		this.panico = false;
		this.excesos = true;
		this.paros = false;
		this.titulo = 'Reporte de excesos por velocidad';
	}

	parodemotor(){
		this.panico = false;
		this.excesos = false;
		this.paros = true;
		this.titulo = 'Reporte de paros de motor';
	}

	minutoModal(){
		this.panico = false;
		this.excesos = false;
		this.paros = false;
	    this.unidades(this.ruta);
	}

	hh_mm_ss() {
		let now = new Date();
		let hour = "" + now.getHours(); if (hour.length == 1) { hour = "0" + hour; }
		let minute = "" + now.getMinutes(); if (minute.length == 1) { minute = "0" + minute; }
		return hour + ":" + minute;
	}

}