import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer  } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import {
  ScriptService,
  GetserviesService
} from '../app.filesServices';

declare var $:any;

@Component({
  selector: 'app-sintransmision2diasyperiodo',
  templateUrl: './sintransmision2diasyperiodo.component.html',
  styleUrls: ['./sintransmision2diasyperiodo.component.css']
})
export class Sintransmision2diasyperiodoComponent implements OnInit {

	loader:boolean = false;
	token = JSON.parse(localStorage.getItem("token"));

	diastransmision:number = 2;
	unidadesListSinTransmision:any = [];
	numST:number = 0;
	operador:any = "=";
 
  constructor(
    public snackBar:MatSnackBar,
  	private router:Router,
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    public script:ScriptService,
    public getservices:GetserviesService
    ) {
  	this.script.loadScript();
  }

  ngOnInit() {
    //// request
    this.loader = true;
      let data = {
        parametrodias: this.diastransmision,
        operador: this.operador
      };
      this.getservices.getData('unidadesSinTranmitirParametro',data)
      .subscribe( res => {
        this.unidadesListSinTransmision = res;
        this.numST = this.unidadesListSinTransmision.length;
      },err => {
          this.snackBar.open('Error de conexión en el servidor. Hoy', null, { duration: 3000 });
      });
    this.loader = false;
  }

}