import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'reload',
  template: ''
})
export class ReloadComponent implements OnInit{
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe( params =>{
    	this.router.navigate([params['url']]);
    });
  }
}